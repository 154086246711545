import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/ActionButton';

import { useTableGroup } from './GroupContext';
import t from './translations';

export default function TableGroupToggle() {
  const { formatMessage } = useIntl();
  const { isOpen, setIsOpen } = useTableGroup();

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <th className="table-cell -is-group-toggle">
      <ActionButton
        icon={isOpen ? 'expand_less' : 'expand_more'}
        title={formatMessage(isOpen ? t.hide : t.show)}
        onClick={onClick}
      />
    </th>
  );
}
