import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { isIsoDate } from 'utils/date';

import useIsMounted from 'hooks/useIsMounted';

function parseSearchString<T = any>(search: string) {
  const parsed: any = qs.parse(search, {
    ignoreQueryPrefix: true,

    decoder: (value) => {
      if (value === 'true') return true;
      if (value === 'false') return false;
      if (value === 'null') return null;
      if (value === 'undefined') return undefined;

      if (/^(\d+|\d*\.\d+)$/.test(value)) return parseFloat(value);

      let decodedValue = value.replace(/\+/g, ' ');
      try {
        decodedValue = decodeURIComponent(decodedValue);
      } catch (error) {
        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.error('Failed to decodeURIComponent:', error);
        }
      }

      if (isIsoDate(decodedValue)) {
        return new Date(decodedValue);
      }

      return decodedValue;
    },
  });

  return parsed as T;
}

function stringifyQueryParams(queryParams: Record<string, any>): string {
  return qs.stringify(
    Object.keys(queryParams).reduce((acc, key) => {
      const value = queryParams[key];
      const serializedValue = [true, false, null, undefined].includes(value)
        ? String(value)
        : value;

      return {
        ...acc,
        [key]: serializedValue,
      };
    }, {}),
    {
      addQueryPrefix: true,
      indices: false,
      arrayFormat: 'brackets',
    }
  );
}

export default function useQueryParams<T extends Record<string, any>>(
  initialParams?: T,
  storageKey?: string
) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const [queryParams, setQueryParams] = useState<T>({
    ...initialParams,
    ...(storageKey ? retrieveFromSession(storageKey) : {}),
    ...parseSearchString(location.search),
  } as T);

  const initialParamsRef = useRef(initialParams);

  const effectRanRef = useRef(false);

  useEffect(() => {
    if (storageKey) {
      saveOnSession(storageKey, queryParams);
    }
  }, [storageKey, queryParams]);

  useEffect(() => {
    // skip mount as queryParams are already set in state
    if (effectRanRef.current) {
      // update queryParams when location has search,
      // otherwise use the intial queryParams as an empty search string
      // indicates that we should use the initial state
      location.search
        ? setQueryParams(parseSearchString<T>(location.search) as T)
        : setQueryParams(initialParamsRef.current as T);
    }

    effectRanRef.current = true;
  }, [location]);

  const queryParamsRef = useRef(queryParams);
  useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  const overwriteInitialParams = useCallback(
    (newInitialParams: Partial<T>) => {
      setQueryParams((prevParams) => ({ ...prevParams, ...newInitialParams }));
      initialParamsRef.current = { ...initialParams, ...newInitialParams } as T;
    },
    [initialParams]
  );

  const augmentedSetQueryParams: Dispatch<SetStateAction<T>> = useCallback(
    (newParams) => {
      const params =
        newParams instanceof Function
          ? newParams(queryParamsRef.current)
          : newParams;

      if (!isEqual(params, queryParamsRef.current) && isMounted()) {
        navigate({ search: stringifyQueryParams(params) });
      }
    },
    [isMounted, navigate]
  );

  return [
    queryParams,
    augmentedSetQueryParams,
    overwriteInitialParams,
  ] as const;
}

function retrieveFromSession(key: string) {
  const value = sessionStorage.getItem(key);
  return value ? parseSearchString(value) : {};
}

function saveOnSession(key: string, value: any) {
  sessionStorage.setItem(key, stringifyQueryParams(value));
}
