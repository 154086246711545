import { useCallback, useRef, useState } from 'react';

type Options = {
  delayDuration?: number;
  delayToggleTrue?: boolean;
  delayToggleFalse?: boolean;
};

export default function useToggleDelay({
  delayDuration = 500,
  delayToggleTrue = false,
  delayToggleFalse = true,
}: Options = {}) {
  const [value, setValue] = useState(false);

  const trueTimeoutId = useRef<any>();
  const falseTimeoutId = useRef<any>();

  const toggle = useCallback(
    (bool: boolean) => {
      if (trueTimeoutId.current) clearTimeout(trueTimeoutId.current);
      if (falseTimeoutId.current) clearTimeout(falseTimeoutId.current);

      const timeoutId = bool ? trueTimeoutId : falseTimeoutId;
      const needsDelay = bool ? delayToggleTrue : delayToggleFalse;

      timeoutId.current = needsDelay
        ? setTimeout(() => setValue(bool), delayDuration)
        : setValue(bool);
    },
    [setValue, delayDuration, delayToggleTrue, delayToggleFalse]
  );

  return [value, toggle] as const;
}
