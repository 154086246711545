import { useSelector } from 'react-redux';

import Flash from 'components/Flash';
import Section from 'components/Section';

type Props = {
  substituteMessage?: string;
  close?: boolean;
};

export default function ErrorFlash({ close = true, substituteMessage }: Props) {
  const activity = useSelector((state) => state.activity);
  const errors = useSelector((state) => state.errors);

  if (
    !activity.name &&
    errors.length > 0 &&
    errors.find((e) => e.handlerType === 'form')
  ) {
    return (
      <>
        {errors.map(({ detail }, index) => (
          <Section key={index} withoutPadding>
            <Flash
              close={close}
              type="danger"
              content={detail || substituteMessage || ''}
            />
          </Section>
        ))}
      </>
    );
  }

  return null;
}
