import { defineMessages, useIntl } from 'react-intl';

import DataList from 'components/DataList';
import Flash from 'components/Flash';
import Modal from 'components/Modal';
import Section from 'components/Section';

const t = defineMessages({
  title: {
    id: 'browser_support_modal_title',
    defaultMessage: 'Browser support',
  },
  description: {
    id: 'browser_support_modal_description',
    defaultMessage:
      'Dear customer, Your current browser does not provide the optimal user experience! We recommend the use of one of the following browsers in the list below.',
  },
  supportFor: {
    id: 'browser_support_modal_support_for',
    defaultMessage: 'Last {number} major version(s)',
  },
});

type Props = {
  show: boolean;
  cancel: () => void;
};

export default function UnsupportedBrowserModal({ show, cancel }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      large
      show={show}
      showCancel={false}
      showSubmit={false}
      title={formatMessage(t.title)}
      cancel={cancel}
      contentClassName="without-padding"
      isCentered={false}
    >
      <Flash type="info" close={false} content={formatMessage(t.description)} />

      <Section className="m-0">
        <DataList
          values={[
            {
              label: 'Chrome',
              value: formatMessage(t.supportFor, { number: 2 }),
            },
            {
              label: 'Firefox',
              value: formatMessage(t.supportFor, { number: 2 }),
            },
            {
              label: 'Safari',
              value: formatMessage(t.supportFor, { number: 2 }),
            },
            {
              label: 'Edge',
              value: formatMessage(t.supportFor, { number: 2 }),
            },
          ]}
        />
      </Section>
    </Modal>
  );
}
