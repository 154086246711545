import { Children, CSSProperties, ReactNode } from 'react';
import cx from 'classnames';

import { isSectionSubTitleElement } from './SectionSubTitle';

export interface SectionSubContentProps {
  children: ReactNode;
  withoutBorder?: boolean;
  style?: CSSProperties;
}

export default function SectionSubContent({
  children,
  withoutBorder = false,
  style,
}: SectionSubContentProps) {
  const childArray = Children.toArray(children);

  const content = childArray.filter(
    (child: any) => !isSectionSubTitleElement(child)
  );

  const subTitle = childArray.filter((child: any) =>
    isSectionSubTitleElement(child)
  );

  return (
    <>
      {subTitle}
      <div
        className={cx('section-sub-content', {
          '-without-border': withoutBorder,
        })}
        style={style}
      >
        {content}
      </div>
    </>
  );
}
