import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import createApiMiddleware from 'api/storeMiddleware';

import createLoggerMiddleware from './logger';
import rootReducer from './reducer';
import { stateSerializer } from './utils';

const isDev = import.meta.env.MODE !== 'production';
const isLoggingEnabled =
  parseInt(import.meta.env.REACT_APP_REDUX_LOGGER || '0', 10) === 1;

const apiMiddleware = createApiMiddleware();
const middlewares: Middleware[] = [thunkMiddleware, apiMiddleware];

if (isDev || isLoggingEnabled) {
  const loggerMiddleware = createLoggerMiddleware();
  middlewares.push(loggerMiddleware);
}

const devtoolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers =
  (isDev || isLoggingEnabled) && devtoolsCompose
    ? devtoolsCompose({ stateSanitizer: stateSerializer })
    : compose;

export default function configureStore() {
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(rootReducer, enhancer);
}
