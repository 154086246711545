import { lazy } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import {
  usePatientCardsEnabled,
  useTrackTreatmentsEnabled,
} from 'hooks/useConfig';

import FeatureFlag from 'components/FeatureFlag';
import Icon from 'components/Icon';

import Sidebar from 'views/components/Sidebar';

const Sessions = lazy(() => import('./sessions'));
const Cards = lazy(() => import('./cards'));
const Profile = lazy(() => import('./profile'));

export function TrackSidebar() {
  const { formatMessage } = useIntl();

  const showTreatments = useTrackTreatmentsEnabled();
  const showCards = usePatientCardsEnabled();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <Sidebar>
      <FeatureFlag booleanMatch={showTreatments}>
        <li role="presentation">
          <NavLink
            to="sessions/ongoing"
            title={formatMessage(t.ongoing)}
            className={cx}
          >
            <Icon>dashboard</Icon>
          </NavLink>
        </li>

        <li role="presentation">
          <NavLink
            to="sessions/historic"
            title={formatMessage(t.historic)}
            className={cx}
          >
            <Icon>app:session</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag booleanMatch={showCards}>
        <li role="presentation">
          <NavLink to="cards" title={formatMessage(t.cards)} className={cx}>
            <Icon>badge</Icon>
          </NavLink>
        </li>
      </FeatureFlag>
    </Sidebar>
  );
}

export function TrackRoutes() {
  const showTreatments = useTrackTreatmentsEnabled();
  const showCards = usePatientCardsEnabled();

  return (
    <Routes>
      {showTreatments && (
        <Route path="/" element={<Navigate to="sessions" />} />
      )}
      {showTreatments && <Route path="sessions/*" element={<Sessions />} />}

      {showCards && <Route path="/" element={<Navigate to="cards" />} />}
      {showCards && <Route path="cards" element={<Cards />} />}

      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

const t = defineMessages({
  ongoing: {
    id: 'navigation_track_ongoing_sessions',
    defaultMessage: 'Ongoing sessions',
  },
  historic: {
    id: 'navigation_track_historic_sessions',
    defaultMessage: 'Historic sessions',
  },
  cards: {
    id: 'navigation_track_patient_cards',
    defaultMessage: 'Patient cards',
  },
});
