interface Accessor {
  label?: string;
  value?: string;
}

export default function labelize(accessor?: Accessor) {
  return (item: Record<string, any>) => ({
    label: item[accessor?.label || 'name'],
    value: item[accessor?.value || 'id'],
  });
}
