import { RefObject, useEffect } from 'react';

import nodeIsOutsideParent from 'utils/nodeIsOutsideParent';

import useSyncedRef from 'hooks/useSyncedRef';

// Call the callback when the focused element leaves the `containerRef` because
// of pressing `Tab`, or when clicking outside `containerRef`.
export default function useBlurDetection(
  containerRef: RefObject<Node>,
  callback: () => void
) {
  const callbackRef = useSyncedRef(callback);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null;

      if (nodeIsOutsideParent(containerRef.current, target)) {
        callbackRef.current();
      }
    };

    const handleKey = (event: KeyboardEvent) => {
      if (
        event.key === 'Tab' &&
        (!containerRef.current ||
          nodeIsOutsideParent(containerRef.current, document.activeElement))
      ) {
        callbackRef.current();
      }
    };

    window.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKey);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKey);
    };
  }, [callbackRef, containerRef]);
}
