import { ReactNode, useRef } from 'react';
import { decamelize } from 'humps';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { cancelActivity } from 'store/modules/activity';

import ActionButton from 'components/ActionButton';

import t from './translations';

interface TableRemoveOverlayProps {
  activityName?: string;
  resourceType: string;
  resourceId: string;
  idAttribute?: string;

  confirmationQuestion?: ReactNode;
  confirm: () => void;
}

export default function TableRemoveOverlay({
  resourceType,
  resourceId,
  idAttribute = 'id',
  activityName,
  confirmationQuestion,
  confirm,
}: TableRemoveOverlayProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const nodeRef = useRef<HTMLDivElement>(null);
  const activity = useSelector((state) => state.activity);

  const name =
    activityName || `REMOVE_${decamelize(resourceType).toUpperCase()}`;

  const payloadId = activity?.payload?.[resourceType]?.[idAttribute];

  const isDeleting =
    activity && activity.name === name && payloadId && payloadId === resourceId;

  return (
    <CSSTransition
      in={isDeleting}
      timeout={1000}
      classNames="table-overlay"
      nodeRef={nodeRef}
    >
      {isDeleting ? (
        <div
          ref={nodeRef}
          className="table-overlay-remove"
          data-testid="table-overlay-remove"
        >
          <h5>
            {confirmationQuestion ||
              formatMessage(t.defaultConfirmationQuestion)}
          </h5>
          <ActionButton
            remove
            icon="check"
            title={formatMessage(t.confirmDelete)}
            onClick={(e) => {
              e.stopPropagation();
              confirm();
              dispatch(cancelActivity());
            }}
          />
          <ActionButton
            remove
            icon="close"
            title={formatMessage(t.cancelDelete)}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cancelActivity());
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </CSSTransition>
  );
}
