import { schema } from 'normalizr';
import { serialize as objectToFormData } from 'object-to-formdata';

import { parseDate, parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const treatmentSchema = new schema.Entity('treatments');

export const patientSchema = new schema.Entity(
  'patients',
  {
    treatment: treatmentSchema,
  },
  {
    processStrategy: (patient) => ({
      ...patient,
      ...schemaDeserializers({
        birthdate: parseDate,
        enrolledAt: parseDate,
        dialysisSince: parseDate,
        updatedAt: parseDateTime,
        createdAt: parseDateTime,
      })(patient),
      activePatientTreatments: patient.activePatientTreatments
        ? patient.activePatientTreatments.map(
            schemaDeserializers({
              updatedAt: parseDateTime,
              createdAt: parseDateTime,
            })
          )
        : [],
      identifiers: patient.identifiers
        ? patient.identifiers.map(
            schemaDeserializers({
              updatedAt: parseDateTime,
              createdAt: parseDateTime,
            })
          )
        : [],
    }),
  }
);

export const GET_PATIENT = networkActionTypes('GET_PATIENT');

export const getPatient = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT,
    url: `patients/${id}`,
    method: 'GET',
    normalizeSchema: patientSchema,
  },
});

export const GET_PATIENTS = networkActionTypes('GET_PATIENTS');

export const getPatients = (
  page: number,
  sortKeys = 'last_name asc'
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENTS,
    url: 'patients',
    method: 'GET',
    params: { page, pageSize: 30, sortKeys },
    normalizeSchema: { data: [patientSchema] },
  },
});

export const GET_PATIENTS_WITH_UNVALIDATED_MEDICATIONS = networkActionTypes(
  'GET_PATIENTS_WITH_UNVALIDATED_MEDICATIONS'
);

export const getPatientsWithUnvalidatedMedications = (
  page: number,
  collectionType: 'validation_prescription' | 'validation_administration'
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENTS_WITH_UNVALIDATED_MEDICATIONS,
    url: 'patient_selectors',
    method: 'GET',
    params: { collectionType, page, pageSize: 30 },
    normalizeSchema: { data: [patientSchema] },
  },
});

export const CREATE_PATIENT = networkActionTypes('CREATE_PATIENT');

export type CreatePatientArgsT = {
  id: string;
  firstName: string;
  lastName: string;
  sex: string;
  birthdate: string;
  caseId: string | null | undefined;
  admissionId: string | null | undefined;
  diagnosis: string;
  renalFailureType: 'acute' | 'chronic';
  dialysisInitiated: boolean;
  dialysisSince: Date;
  treatmentId: string;
  treatmentSince: Date;
  careType: string;
  disorder: 'renal' | 'other';
  centerIds?: string[];
  residenceType: string;
};

export const createPatient = ({
  firstName,
  lastName,
  sex,
  birthdate,
  caseId,
  admissionId,
  diagnosis,
  renalFailureType,
  dialysisInitiated,
  dialysisSince,
  treatmentId,
  treatmentSince,
  careType,
  disorder,
  centerIds,
  residenceType,
}: CreatePatientArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_PATIENT,
    url: 'patients',
    method: 'POST',
    payload: {
      patient: {
        firstName,
        lastName,
        sex,
        birthdate,
        caseId,
        admissionId,
        diagnosisId: diagnosis,
        disorder,
        renalFailureType: renalFailureType || null,
        dialysisInitiated:
          renalFailureType === 'chronic' ? dialysisInitiated : null,
        dialysisSince: dialysisSince || null,
        treatmentId,
        treatmentSince,
        careType: careType || null,
        centerIds,
        residenceType,
      },
    },
    normalizeSchema: patientSchema,
  },
});

export const UPDATE_PATIENT = networkActionTypes('UPDATE_PATIENT');

type UpdatePatientArgsT = {
  id: string;
  firstName: string;
  lastName: string;
  sex: string;
  birthdate: string;
  caseId: string | null | undefined;
  admissionId: string | null | undefined;
  residenceType: string;
};

export const updatePatient = ({
  id,
  firstName,
  lastName,
  sex,
  birthdate,
  caseId,
  admissionId,
  residenceType,
}: UpdatePatientArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT,
    url: `patients/${id}`,
    method: 'PUT',
    payload: {
      patient: {
        firstName,
        lastName,
        sex,
        birthdate,
        caseId,
        admissionId,
        residenceType,
      },
    },
    normalizeSchema: patientSchema,
  },
});

export const UPDATE_PATIENT_AVATAR = networkActionTypes(
  'UPDATE_PATIENT_AVATAR'
);

type UpdatePatientAvatarArgsT = {
  id: string;
  file: File | null;
};

export const updatePatientAvatar = ({
  id,
  file,
}: UpdatePatientAvatarArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_AVATAR,
    url: `patients/${id}/avatar`,
    method: file ? 'PUT' : 'DELETE',
    payload: objectToFormData({
      avatar: file,
    }),
    normalizeSchema: patientSchema,
  },
});

export interface UpdateCareTeamArgs {
  patientId: string;
  attendingDoctor: UserT | null;
  centerIds?: string[];
}

export const UPDATE_CARE_TEAM_SUCCESS = 'UPDATE_CARE_TEAM_SUCCESS';

export const updateCareTeamAction = (payload: UpdateCareTeamArgs) => ({
  type: UPDATE_CARE_TEAM_SUCCESS,
  payload,
});

export const UPDATE_PATIENT_CONTACT_INFO = networkActionTypes(
  'UPDATE_PATIENT_CONTACT_INFO'
);

type UpdatePatientContactInfoArgsT = {
  id: string;
  contactNotes: string;
};

export const updatePatientContactInfo = ({
  id,
  contactNotes,
}: UpdatePatientContactInfoArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_CONTACT_INFO,
    url: `patients/${id}/contact_info`,
    method: 'PUT',
    payload: { contactNotes },
    normalizeSchema: patientSchema,
  },
});

export const UPDATE_PATIENT_MEDICAL_INFO = networkActionTypes(
  'UPDATE_PATIENT_MEDICAL_INFO'
);

type UpdatePatientMedicalInfoArgsT = {
  id: string;
  bodyHeight?: number;
  bloodType?: string;
};

export const updatePatientMedicalInfo = ({
  id,
  bodyHeight,
  bloodType,
}: UpdatePatientMedicalInfoArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_MEDICAL_INFO,
    url: `patients/${id}/medical_info`,
    method: 'PUT',
    payload: {
      patient: {
        bodyHeight,
        bloodType: bloodType || null,
      },
    },
    normalizeSchema: patientSchema,
  },
});

export const UPDATE_PATIENT_TRANSPORT_INFO = networkActionTypes(
  'UPDATE_PATIENT_TRANSPORT_INFO'
);

type UpdatePatientTransportInfoArgsT = {
  patientId: string;
  forthTransportTypeId: string;
  forthTransportPhoneNumber: string;
  backTransportTypeId: string | null | undefined;
  backTransportPhoneNumber: string | null | undefined;
  transportNotes: string;
};

export const updatePatientTransportInfo = ({
  patientId,
  forthTransportTypeId,
  forthTransportPhoneNumber,
  backTransportTypeId,
  backTransportPhoneNumber,
  transportNotes,
}: UpdatePatientTransportInfoArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_TRANSPORT_INFO,
    url: `patients/${patientId}/transports`,
    method: 'PUT',
    payload: {
      patientId,
      forthTransportTypeId,
      forthTransportPhoneNumber,
      backTransportTypeId,
      backTransportPhoneNumber,
      transportNotes,
    },
  },
});

export const UPDATE_PATIENT_DISORDER = networkActionTypes(
  'UPDATE_PATIENT_DISORDER'
);

export type UpdatePatientDisorderArgsT = {
  id: string;
  disorder: 'other' | 'renal';
  renalFailureType: 'acute' | 'chronic' | null | undefined;
  dialysisInitiated: boolean;
  dialysisSince: Date | null | undefined;
};

export const updatePatientDisorder = ({
  id,
  disorder,
  renalFailureType,
  dialysisInitiated,
  dialysisSince,
}: UpdatePatientDisorderArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_DISORDER,
    url: `patients/${id}/disorder`,
    method: 'PUT',
    payload: {
      patientDisorder: {
        disorder,
        renalFailureType: renalFailureType || null,
        dialysisInitiated:
          renalFailureType === 'chronic' ? dialysisInitiated : null,
        dialysisSince: dialysisSince || null,
      },
    },
    actionPayload: { id },
    normalizeSchema: patientSchema,
  },
});

interface UpdatePatientResidenceTypeArgsT {
  id: string;
  residenceType: string;
}

export const UPDATE_PATIENT_RESIDENCE_TYPE = networkActionTypes(
  'UPDATE_PATIENT_RESIDENCE_TYPE'
);

export const updatePatientResidenceType = ({
  id,
  residenceType,
}: UpdatePatientResidenceTypeArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_RESIDENCE_TYPE,
    url: `patients/${id}/basic_info`,
    method: 'PUT',
    payload: {
      patient: {
        residenceType,
      },
    },
    normalizeSchema: patientSchema,
  },
});

export const DEACTIVATE_PATIENT = networkActionTypes('DEACTIVATE_PATIENT');

type DeactivatePatientArgsT = {
  id: string;
  reason: string;
  note: string;
};

export const deactivatePatient = ({
  id,
  reason,
  note,
}: DeactivatePatientArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DEACTIVATE_PATIENT,
    url: 'unenrollments',
    method: 'POST',
    payload: {
      unenrollment: {
        patientId: id,
        reason,
        note,
      },
    },
  },
});

export const ENROLL_EMERGENCY_PATIENT = networkActionTypes(
  'ENROLL_EMERGENCY_PATIENT'
);

type EnrollEmergencyPatientArgsT = {
  firstName: string;
  lastName: string;
  sex: string;
  birthdate: Date;
  diagnosis: string;
  renalFailureType: 'acute' | 'chronic';
  dialysisInitiated: boolean;
  dialysisSince: Date;
  treatmentId: string;
  modality: string | undefined;
  careType: string;
  disorder: 'other' | 'renal';
  centerIds?: string[];
  residenceType: string;
};

export const enrollEmergencyPatient = ({
  firstName,
  lastName,
  sex,
  birthdate,
  diagnosis,
  renalFailureType,
  dialysisInitiated,
  dialysisSince,
  disorder,
  centerIds,
  residenceType,
}: EnrollEmergencyPatientArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: ENROLL_EMERGENCY_PATIENT,
    url: 'emergency_enrollments',
    method: 'POST',
    payload: {
      emergencyPatient: {
        firstName,
        lastName,
        sex,
        birthdate,
        residenceType,
      },
      enrollment: {
        diagnosisId: diagnosis,
        disorder,
        renalFailureType: renalFailureType || null,
        dialysisInitiated:
          renalFailureType === 'chronic' ? dialysisInitiated : null,
        dialysisSince: dialysisSince || null,
        centerIds,
      },
    },
    normalizeSchema: { patient: patientSchema },
  },
});

export const ENROLL_PATIENT = networkActionTypes('ENROLL_PATIENT');

export type EnrollPatientArgsT = {
  patientId: string;
  diagnosis: string;
  renalFailureType: 'acute' | 'chronic';
  dialysisInitiated: boolean;
  dialysisSince: Date;
  disorder: 'other' | 'renal';
  centerIds?: string[];
  residenceType: string;
};

export const enrollPatient = ({
  patientId,
  diagnosis,
  renalFailureType,
  dialysisInitiated,
  dialysisSince,
  disorder,
  centerIds,
  residenceType,
}: EnrollPatientArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: ENROLL_PATIENT,
    url: 'enrollments',
    method: 'POST',
    payload: {
      enrollment: {
        patientId,
        diagnosisId: diagnosis,
        disorder,
        renalFailureType: renalFailureType || null,
        dialysisInitiated:
          renalFailureType === 'chronic' ? dialysisInitiated : false,
        dialysisSince: dialysisSince || null,
        centerIds,
      },
      patient: {
        residenceType,
      },
    },
  },
});

export const MERGE_PATIENTS = networkActionTypes('MERGE_PATIENTS');

export type MergePatientsArgsT = {
  emergencyPatientId: string;
  ehrPatientId: string;
};

export const mergePatients = ({
  emergencyPatientId,
  ehrPatientId,
}: MergePatientsArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: MERGE_PATIENTS,
    url: 'merge_emergency_patients',
    method: 'POST',
    payload: {
      patients: {
        emergencyPatientId,
        ehrPatientId,
      },
    },
    normalizeSchema: patientSchema,
  },
});

export const CLEAR_PATIENTS = 'CLEAR_PATIENTS';

export const clearPatients = () => ({
  type: CLEAR_PATIENTS,
});

export const UPDATE_PATIENT_ALLERGIES_KNOWN = networkActionTypes(
  'UPDATE_PATIENT_ALLERGIES_KNOWN'
);

export type updatePatientAllergiesKnownArgsT = {
  id: string;
  allergiesKnown: 'true' | 'false' | null;
};

export const updatePatientAllergiesKnown = ({
  id,
  allergiesKnown,
}: updatePatientAllergiesKnownArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_ALLERGIES_KNOWN,
    url: `patients/${id}/allergies_known`,
    method: 'PUT',
    payload: {
      patient: {
        allergiesKnown,
      },
    },
    actionPayload: { id },
    normalizeSchema: patientSchema,
  },
});

export const UPDATE_PATIENT_HOME_NURSING = networkActionTypes(
  'UPDATE_PATIENT_HOME_NURSING'
);

export type updatePatientHomeNursingArgsT = {
  id: string;
  receivesHomeNursing: boolean;
};

export const updatePatientHomeNursing = ({
  id,
  receivesHomeNursing,
}: updatePatientHomeNursingArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_HOME_NURSING,
    url: `patients/${id}/home_nursing`,
    method: 'PUT',
    payload: {
      patient: {
        receivesHomeNursing,
      },
    },
    actionPayload: { id },
    normalizeSchema: patientSchema,
  },
});

export const REPLACE_ACTIVE_PATIENT_TREATMENTS =
  'REPLACE_ACTIVE_PATIENT_TREATMENTS';

export const replaceActivePatientTreatments = (
  patientId: string,
  activePatientTreatments: Record<string, any>[]
) => ({
  type: REPLACE_ACTIVE_PATIENT_TREATMENTS,
  payload: {
    patientId,
    activePatientTreatments,
  },
});

export const GET_PATIENT_ALLERGY_INFO = networkActionTypes(
  'GET_PATIENT_ALLERGY_INFO'
);

export const getPatientAllergyInfo = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT_ALLERGY_INFO,
    url: `patients/${patientId}/allergies_info`,
    method: 'GET',
    actionPayload: { patientId },
  },
});
