import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

import {
  deserializeEncounter,
  deserializeIntradialyticProductOption,
  deserializeLabParameterOption,
  deserializePatient,
  deserializeUser,
} from './deserializers';
import {
  AllergyConditionTypeT,
  AllergyT,
  CenterT,
  ComplicationT,
  EncounterT,
  IdNameRecordT,
  IntradialyticProduct,
  MedicinalPosologyPeriodicityT,
  PaginationMeta,
  PersonTypeT,
  RoleT,
  RoomT,
  SessionHistoryT,
  SessionPeriodT,
  ShiftT,
  StockLocationT,
  TransportTypeT,
  UserPermissionT,
} from './types';

interface FetchCenterOptionsArgs {
  include?: string;
  sortKeys?: string;
}

export async function fetchCenterOptions({
  include,
  sortKeys = 'name asc',
}: FetchCenterOptionsArgs = {}) {
  const { data } = await http.get<{ centers: CenterT[] }>('/centers', {
    params: { include, sortKeys },
  });

  return data.centers.map(labelize()).sort(sortInline('label'));
}

interface FetchClinicalCategoryOptionsArgs {
  useForTherapyPlans?: boolean;
  useInLabViewer?: boolean;
}

export async function fetchClinicalCategoryOptions(
  params?: FetchClinicalCategoryOptionsArgs
) {
  const { data } = await http.get<{
    categories: IdNameRecordT[];
  }>('/clinical_categories/options', { params });

  return data.categories.map(labelize()).sort(sortInline('label'));
}

interface FetchPrescribableUnitOptionsArgs {
  productId?: string;
  active: boolean;
}

export async function fetchPrescribableUnitOptions({
  productId,
  active,
}: FetchPrescribableUnitOptionsArgs) {
  const { data } = await http.get<{
    prescribableUnits: (IdNameRecordT & { defaultPrescriptionUnit: boolean })[];
  }>(`medications/products/${productId}/prescribable_units`, {
    params: { active },
  });

  return data.prescribableUnits
    .map(({ id, name, defaultPrescriptionUnit }) => ({
      label: name,
      value: id,
      defaultPrescriptionUnit,
    }))
    .sort(sortInline('label'));
}

interface FetchAdministrationRouteOptionsArgs {
  productId: string;
}

export async function fetchAdministrationRouteOptions({
  productId,
}: FetchAdministrationRouteOptionsArgs) {
  const { data } = await http.get<{
    administrationRoutes: MedicinalAdministrationRouteT[];
  }>(`medications/products/${productId}/products_routes`);

  return data.administrationRoutes
    .map(({ id, name, defaultForProduct }) => ({
      label: name,
      value: id,
      defaultForProduct: defaultForProduct ?? false,
    }))
    .sort(sortInline('label'));
}

interface ObservationTypeT {
  id: string;
  name: string;
  creatable: boolean;
  custom: boolean;
}

export async function fetchObservationTypes() {
  const { data } = await http.get<{
    measurementTypes: Record<string, ObservationTypeT>;
  }>('measurement_types');

  const types = Object.values(data.measurementTypes);
  const options = types.map(labelize()).sort(sortInline('label'));

  return { types, options };
}

export async function fetchAccessSiteOptions() {
  const { data } = await http.get<any>('treatment_access_site_options');

  const serializedData = deserialize(data, {
    'accessSiteTechniqueOptions.catheter[].createdAt': parseDateTime,
    'accessSiteTechniqueOptions.catheter[].updatedAt': parseDateTime,
    'accessSiteTechniqueOptions.catheter[].deletedAt': parseDateTime,
    'accessSiteTechniqueOptions.fistula[].createdAt': parseDateTime,
    'accessSiteTechniqueOptions.fistula[].updatedAt': parseDateTime,
    'accessSiteTechniqueOptions.fistula[].deletedAt': parseDateTime,
    'accessSiteTechniqueOptions.graft[].createdAt': parseDateTime,
    'accessSiteTechniqueOptions.graft[].updatedAt': parseDateTime,
    'accessSiteTechniqueOptions.graft[].deletedAt': parseDateTime,
  });

  return {
    types: serializedData.accessSiteTypeOptions,
    treatmentTypes: serializedData.accessSiteTechniqueTreatmentTypeOptions,
    techniques: serializedData.accessSiteTechniqueOptions,
    techniqueTypeFlags: serializedData.accessSiteTechniqueTypeFlagOptions,
    sides: serializedData.accessSiteSideOptions,
    statuses: serializedData.accessSiteStatusOptions,
    catheterTypes: serializedData.accessSiteCatheterTypeOptions,
    catheterProducts: serializedData.accessSiteCatheterProductOptions,
  };
}

interface SearchPatientsArgs {
  query: string;
  searchScope?: 'not_enrolled' | 'supported_treatment_only';
  excludeAssignedPatients?: boolean;
}

export async function searchPatients(params: SearchPatientsArgs) {
  try {
    const { data } = await http.get<{ data: PatientT[] }>('patients/search', {
      params,
    });

    return data.data.map(deserializePatient);
  } catch {
    return [];
  }
}

export async function fetchLabParameterOptions() {
  const { data } = await http.get<{
    'laboratory/parameters': (IdNameRecordT & { unit: string | null })[];
  }>('/lab_parameters');

  return data['laboratory/parameters']
    .map(deserializeLabParameterOption)
    .sort(sortInline('label'));
}

export interface DialysisMachineT {
  id: string;
  systemDesign: string;
  brand: string;
  displayName: string;
  model: string;
}

export async function fetchEnabledDialysisMachines() {
  const data = await http.get<{
    models: DialysisMachineT[];
  }>('/devices/enabled_dialysis_models');

  return data.data.models;
}

export async function fetchCardTypeOptions() {
  const models = await fetchEnabledDialysisMachines();

  return models.map((model) => ({
    label: model.displayName,
    value: model.model,
  }));
}

interface SearchIntradialyticProductsArgs {
  name: string;
  virtual?: boolean;
  authorizedProducts?: boolean;
  filter?: (
    value: IntradialyticProduct,
    index: number,
    array: IntradialyticProduct[]
  ) => boolean;
}

export async function searchIntradialyticProducts({
  name,
  virtual,
  authorizedProducts,
  filter = () => true,
}: SearchIntradialyticProductsArgs) {
  try {
    const {
      data: { products },
    } = await http.get<{
      products: IntradialyticProduct[];
    }>('medications/intradialytic_products/search/local', {
      params: { name, virtual, authorizedProducts, limit: 100 },
    });

    return products
      .filter(filter)
      .map(deserializeIntradialyticProductOption)
      .sort(sortInline('label'));
  } catch {
    return [];
  }
}

export async function fetchDocumentCategoryOptions() {
  const { data } = await http.get<{ documentCategories: IdNameRecordT[] }>(
    'document_categories'
  );

  return data.documentCategories.map(labelize()).sort(sortInline('label'));
}

type ValueT = IdNameRecordT[];

export async function fetchComplicationCategories() {
  const { data } = await http.get<{
    technical: ValueT;
    other: ValueT;
    peritonealDialysis: ValueT;
    vascularAccess: ValueT;
    peritonealDialysisAccess: ValueT;
  }>('complications/categories');

  return {
    patientCategories: [
      ...data.other,
      ...data.technical,
      ...data.peritonealDialysis,
    ],
    accessCategories: data.vascularAccess,
    pdAccessCategories: data.peritonealDialysisAccess,
  };
}

interface FetchComplicationsArgs {
  id: string;
  type: 'treatment' | 'access_site';
  complicationTypes?: string[];
  page?: number;
  pageSize?: number;
}

export async function fetchComplications({
  id,
  type,
  complicationTypes,
  page = 0,
  pageSize = 30,
}: FetchComplicationsArgs) {
  const { data } = await http.get<{
    data: ComplicationT[];
    meta: PaginationMeta;
  }>(`patients/${id}/complications`, {
    params: {
      type,
      complicationTypes,
      page,
      pageSize,
    },
  });

  return deserialize(data, {
    'data[].createdAt': parseDateTime,
    'data[].updatedAt': parseDateTime,
    'data[].occurredAt': parseDateTime,
    'data[].meta.interruptionResumed': parseDateTime,
    'data[].reportedBy.createdAt': parseDateTime,
    'data[].reportedBy.updatedAt': parseDateTime,
    'data[].params[]': (param) =>
      param.id === 'interruption_resumed'
        ? deserialize(param, { value: parseDateTime })
        : param,
    'data[].site.createdAt': parseDateTime,
    'data[].site.updatedAt': parseDateTime,
    'data[].site.placedAt': parseDateTime,
    'data[].site.lastUsed': parseDateTime,
    'data[].site.technique.createdAt': parseDateTime,
    'data[].site.technique.updatedAt': parseDateTime,
    'data[].session.createdAt': parseDateTime,
    'data[].session.updatedAt': parseDateTime,
    'data[].session.startedAt': parseDateTime,
    'data[].session.endedAt': parseDateTime,
    'data[].session.center.createdAt': parseDateTime,
    'data[].session.center.updatedAt': parseDateTime,
    'data[].session.position.createdAt': parseDateTime,
    'data[].session.position.updatedAt': parseDateTime,
    'data[].session.backTransportType.createdAt': parseDateTime,
    'data[].session.backTransportType.updatedAt': parseDateTime,
    'data[].session.forthTransportType.createdAt': parseDateTime,
    'data[].session.forthTransportType.updatedAt': parseDateTime,
    'data[].session.room.createdAt': parseDateTime,
    'data[].session.room.updatedAt': parseDateTime,
    'data[].session.room.positions[].createdAt': parseDateTime,
    'data[].session.room.positions[].updatedAt': parseDateTime,
    'data[].session.room.stock.createdAt': parseDateTime,
    'data[].session.room.stock.updatedAt': parseDateTime,
  });
}

interface SessionsHistoryArgs {
  patientId: string | undefined;
  page: number;
  pageSize?: number;
}

export async function fetchSessionsHistory({
  patientId,
  page = 0,
  pageSize = 10,
}: SessionsHistoryArgs) {
  const { data } = await http.get<{
    data: SessionHistoryT[];
    meta: PaginationMeta;
    header: string[];
  }>(`patients/${patientId}/session_histories`, {
    params: { page, pageSize },
  });

  return data;
}

interface EncountersArgs {
  page?: number;
  pageSize?: number;
  patientId?: string;
  professions?: string[];
  types?: string[];
}

export async function fetchEncounters({
  page = 0,
  pageSize = 30,
  patientId,
  professions,
  types,
}: EncountersArgs) {
  const { data } = await http.get<{ data: EncounterT[]; meta: PaginationMeta }>(
    `patients/${patientId}/encounters`,
    { params: { page, pageSize, types, professions } }
  );

  return {
    data: data.data.map(deserializeEncounter),
    meta: data.meta,
  };
}

interface TransportTypeArgs {
  includeDeleted?: boolean;
}

export async function fetchTransportTypes(
  { includeDeleted }: TransportTypeArgs = { includeDeleted: false }
) {
  const { data } = await http.get<{
    transportTypes: TransportTypeT[];
  }>('transport_types');

  const transportTypes = includeDeleted
    ? data.transportTypes
    : data.transportTypes.filter((t) => !t.deletedAt);

  return transportTypes
    .map((transportType) =>
      deserialize(transportType, {
        createdAt: parseDateTime,
        deletedAt: parseDateTime,
        updatedAt: parseDateTime,
      })
    )
    .sort(sortInline('name'));
}

export async function fetchCenters(discardedOnly = false) {
  const { data } = await http.get<{ centers: CenterT[] }>('centers', {
    params: {
      include: 'shifts,rooms,rooms.positions,rooms.stock',
      sortKeys: 'name asc',
      scope: discardedOnly ? 'discarded' : undefined,
    },
  });

  const centers = data.centers;

  const rooms: Record<string, RoomT[]> = centers.reduce((acc, center) => {
    return {
      ...acc,
      [center.id]: center.rooms?.map((room) =>
        deserialize(room, {
          'createdAt': parseDateTime,
          'updatedAt': parseDateTime,
          'positions[].createdAt': parseDateTime,
          'positions[].updatedAt': parseDateTime,
          'stock.createdAt': parseDateTime,
          'stock.updatedAt': parseDateTime,
        })
      ),
    };
  }, {});

  const shifts: Record<string, ShiftT[]> = centers.reduce((acc, center) => {
    return {
      ...acc,
      [center.id]: center.shifts?.map((shift) =>
        deserialize(shift, {
          startsAt: parseDateTime,
          endsAt: parseDateTime,
        })
      ),
    };
  }, {});

  return {
    centers: centers.map((center) =>
      deserialize(center, {
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })
    ),
    rooms,
    shifts,
  };
}

export async function fetchGlobalCenterOptions() {
  const { data } = await http.get<{ centers: CenterT[] }>('global_centers');

  return data.centers.map(labelize()).sort(sortInline('label'));
}

export async function fetchStockLocationOptions() {
  const { data } = await http.get<{ stocks: StockLocationT[] }>('stocks');

  return data.stocks.map(({ id, name, externalIdentifier }) => ({
    label: `${name} ${externalIdentifier ? `(${externalIdentifier})` : ''}`,
    value: id,
  })) as OptionT[];
}

export async function fetchRoles() {
  const { data } = await http.get<{
    'users/roles': RoleT[];
  }>('roles');

  return data['users/roles'].map((role) =>
    deserialize(role, {
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
      deletedAt: parseDateTime,
    })
  );
}

export async function fetchPersonTypes() {
  const { data } = await http.get<{
    personTypes: PersonTypeT[];
  }>('person_types');

  return data.personTypes
    .map((type) =>
      deserialize(type, {
        'createdAt': parseDateTime,
        'updatedAt': parseDateTime,
        'identifierTypeAssignments[].createdAt': parseDateTime,
        'identifierTypeAssignments[].updatedAt': parseDateTime,
      })
    )
    .sort(sortInline(['isProfession', 'name']));
}

interface FetchUserArgs {
  page?: number;
  pageSize?: number;
  profession?: string | string[];
  scope?: string;
  right?: string;
  permission?: UserPermissionT;
}

export async function fetchUsers({
  page = 0,
  pageSize = 30,
  scope = 'active',
  profession,
  right,
  permission,
}: FetchUserArgs = {}) {
  const { data } = await http.get<{ data: UserT[]; meta: PaginationMeta }>(
    'users',
    {
      params: {
        page,
        pageSize,
        profession,
        scope,
        right,
        permission,
        sortKeys: 'last_name asc',
      },
    }
  );

  return {
    data: data.data.map(deserializeUser),
    meta: data.meta,
  };
}

export async function searchUsers(query: string) {
  const { data } = await http.get<{ users: UserT[] }>('users/search', {
    params: { query },
  });

  return data.users.map(deserializeUser);
}

export async function fetchUser(id: string) {
  const { data } = await http.get<{ user: UserT }>(`users/${id}`);

  return deserialize(data.user, {
    'createdAt': parseDateTime,
    'updatedAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}

interface SearchMedicinalProductArgs {
  name?: string;
  ambulatory?: boolean;
  ambulatoryPrescribable?: boolean;
  intradialytic?: boolean;
  intradialyticPrescribable?: boolean;
  virtual?: boolean;
}

export async function searchMedicinalProducts(
  params: SearchMedicinalProductArgs
) {
  const { data } = await http.get<{
    products: MedicinalProductItemT[];
  }>('medications/products/search', { params });

  return data.products.map((product) =>
    deserialize(product, {
      'updatedAt': parseDateTime,
      'startedAt': parseDateTime,
      'form.updatedAt': parseDateTime,
      'administrationRoutes[].updatedAt': parseDateTime,
      'administrationRoutes[].source.createdAt': parseDateTime,
      'administrationRoutes[].source.publishedAt': parseDateTime,
      'administrationRoutes[].source.updatedAt': parseDateTime,
      'prescribableUnits[].updatedAt': parseDateTime,
      'stocks[].createdAt': parseDateTime,
      'stocks[].updatedAt': parseDateTime,
      'stocks[].syncedAt': parseDateTime,
    })
  );
}

export async function fetchPosologyPeriodicities({
  enabled,
}: {
  enabled?: boolean;
} = {}) {
  const { data } = await http.get<{
    periodicities: MedicinalPosologyPeriodicityT[];
  }>('/medications/ambulatory_prescriptions/periodicities', {
    params: { enabled },
  });

  return data.periodicities;
}

export async function fetchSessionsPeriod({
  patientId,
  sessionId,
}: {
  patientId: string;
  sessionId: string;
}) {
  const { data: session } = await http.get<SessionPeriodT>(
    `/patients/${patientId}/sessions/${sessionId}/period`
  );

  return deserialize(session, {
    date: parseDateTime,
  });
}

export async function fetchSessionsPeriods({
  date,
  patientId,
}: {
  date?: Date | null;
  patientId: string;
}) {
  const { data } = await http.get<{ sessionPeriods: SessionPeriodT[] }>(
    `patients/${patientId}/sessions/periods`,
    { params: { startDate: date, endDate: date } }
  );

  return data.sessionPeriods.map((sessionDate) =>
    deserialize(sessionDate, {
      date: parseDateTime,
    })
  );
}

export async function fetchAddressTypes() {
  const { data } = await http.get<{ addressTypes: IdNameRecordT[] }>(
    'addresses/types'
  );

  return data.addressTypes;
}

export async function fetchPatient(patientId: string) {
  const { data } = await http.get<PatientT>(`patients/${patientId}`);

  return deserializePatient(data);
}

interface FetchAllergiesT {
  patientId: string;
  conditionType?: AllergyConditionTypeT;
}

export async function fetchAllergies({
  patientId,
  conditionType,
}: FetchAllergiesT) {
  const { data } = await http.get<{
    'patients/patientAllergies': AllergyT[];
  }>(`patients/${patientId}/allergies`);

  return data['patients/patientAllergies']
    .map((allergy) =>
      deserialize(allergy, {
        'onsetAt': parseDateTime,
        'createdAt': parseDateTime,
        'updatedAt': parseDateTime,
        'allergyReactions[].onsetAt': parseDateTime,
        'condition.discardedAt': parseDateTime,
        'condition.createdAt': parseDateTime,
        'condition.updatedAt': parseDateTime,
        'codes[].createdAt': parseDateTime,
        'codes[].updatedAt': parseDateTime,
      })
    )
    .filter((allergy) =>
      conditionType ? allergy.conditionType === conditionType : true
    );
}
