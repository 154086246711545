import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { entityCodeSchema } from 'store/modules/entities/actions/entityCodes';
import { networkActionTypes } from 'store/utils';

import { RTENode } from 'components/RTE/domain';

import { conditionSchema } from './index';

export const patientConditionSchema = new schema.Entity(
  'patientConditions',
  {
    codes: [entityCodeSchema],
    condition: conditionSchema,
  },
  {
    processStrategy: schemaDeserializers({
      onsetAt: parseDateTime,
      abatedAt: parseDateTime,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_PATIENT_CONDITIONS = networkActionTypes(
  'GET_PATIENT_CONDITIONS'
);

export const getPatientConditions = (
  patientId: string,
  conditionType?: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT_CONDITIONS,
    url: `patients/${patientId}/conditions`,
    method: 'GET',
    params: { conditionType },
    actionPayload: { patientId },
    normalizeSchema: { 'patients/patientConditions': [patientConditionSchema] },
  },
});

export const CREATE_PATIENT_CONDITION = networkActionTypes(
  'CREATE_PATIENT_CONDITION'
);

export const createPatientCondition = (
  patientId: string,
  condition: {
    conditionId: string | null | undefined;
    conditionType: string;
    note: RTENode[] | null | undefined;
    name: string;
    onsetAt: Date | null | undefined;
    abatedAt: Date | null | undefined;
    clinicalStatus: ConditionClinicalStatusT;
    verificationStatus: ConditionVerificationStatusT;
    severity: ConditionSeverityT;
    description: string;
    codes?: string[];
  },
  saveInLibrary = false
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_PATIENT_CONDITION,
    url: `patients/${patientId}/conditions`,
    method: 'POST',
    payload: {
      'patients/patientCondition': condition,
      'createCondition': saveInLibrary,
    },
    actionPayload: { patientId },
    normalizeSchema: { 'patients/patientCondition': patientConditionSchema },
  },
});

export const UPDATE_PATIENT_CONDITION = networkActionTypes(
  'UPDATE_PATIENT_CONDITION'
);

export const updatePatientCondition = (
  patientId: string,
  conditionId: string,
  condition: {
    name: string;
    description: string;
    note: ConditionNoteT;
    onsetAt?: Date | null;
    abatedAt?: Date | null;
    severity: ConditionSeverityT;
    clinicalStatus: ConditionClinicalStatusT;
    verificationStatus: ConditionVerificationStatusT;
    codes?: string[];
  }
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_CONDITION,
    url: `patient_conditions/${conditionId}`,
    method: 'PUT',
    payload: {
      'patients/patientCondition': {
        ...condition,
        onsetAt: condition.onsetAt || null,
        abatedAt: condition.abatedAt || null,
      },
    },
    actionPayload: { patientId },
    normalizeSchema: { 'patients/patientCondition': patientConditionSchema },
  },
});

export const DELETE_PATIENT_CONDITION = networkActionTypes(
  'DELETE_PATIENT_CONDITION'
);

export const deletePatientCondition = (
  patientId: string,
  conditionId: string
) => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_PATIENT_CONDITION,
    url: `patient_conditions/${conditionId}`,
    method: 'DELETE',
    actionPayload: { patientId, conditionId },
  },
});
