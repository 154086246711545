import { startActivity } from 'store/modules/activity';

import { DisposableItem } from './fetchers';

export const ADD_DISPOSABLE_ITEM = 'ADD_DISPOSABLE_ITEM';
export const EDIT_DISPOSABLE_ITEM = 'EDIT_DISPOSABLE_ITEM';

export const CREATE_DISPOSABLE_ITEM_FAILURE = 'CREATE_DISPOSABLE_ITEM_FAILURE';
export const UPDATE_DISPOSABLE_ITEM_FAILURE = 'UPDATE_DISPOSABLE_ITEM_FAILURE';

export const addDisposableItemActivity = () =>
  startActivity(ADD_DISPOSABLE_ITEM);

export const editDisposableItemActivity = (disposableItem: DisposableItem) =>
  startActivity(EDIT_DISPOSABLE_ITEM, { disposableItem });
