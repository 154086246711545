import { useEffect, useState } from 'react';

const narrowSidebarWidth = 60;
const wideSidebarWidth = 240;

const IS_WIDE_KEY = 'sidebar_wide';

export default function useSidebarSettings() {
  const [isWide, setIsWide] = useState(
    localStorage.getItem(IS_WIDE_KEY) === '1' ? true : false
  );

  const toggleIsWide = () => {
    setIsWide((value) => !value);
  };

  useEffect(() => {
    localStorage.setItem(IS_WIDE_KEY, isWide ? '1' : '0');

    document.documentElement.style.setProperty(
      '--size-sidebar-width',
      isWide ? `${wideSidebarWidth}px` : `${narrowSidebarWidth}px`
    );
  }, [isWide]);

  return {
    isWide,
    toggleIsWide,
  };
}
