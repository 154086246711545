import { useIntl } from 'react-intl';

import { sexTranslations as t } from 'translatedResources/sex';

import Icon from 'components/Icon';

interface Props {
  value: SexT;
  className?: string;
  iconOnly?: boolean;
}

export default function Sex({ value, className, iconOnly = true }: Props) {
  const { formatMessage } = useIntl();

  const displayValue = formatMessage(t[String(value)]);

  if (value === 'O' || value === 'U') {
    return <strong title="Sex">{displayValue}</strong>;
  }

  const icon = genderIcons(value);

  if (!icon) return null;

  return (
    <>
      <Icon className={className} title={displayValue}>
        {icon}
      </Icon>

      {!iconOnly && <div style={{ paddingLeft: 3 }}>{displayValue}</div>}
    </>
  );
}

function genderIcons(sex: SexT): string | null {
  switch (sex) {
    case 'M':
      return 'male';
    case 'F':
      return 'female';
    default:
      return null;
  }
}
