import {
  deserialize,
  parseDate,
  parseDateTime,
  parseForm,
} from 'api/deserialize';

import extractConfigValues from 'components/FormV3/dynamic/extractConfigValues';

import {
  EncounterT,
  EncounterTypeT,
  IdNameRecordT,
  IntradialyticProduct,
} from './types';

export function deserializePatient(patient: PatientT): PatientT {
  return deserialize(patient, {
    'birthdate': parseDate,
    'enrolledAt': parseDate,
    'dialysisSince': parseDate,
    'lastUnenrollmentAt': parseDate,
    'updatedAt': parseDateTime,
    'createdAt': parseDateTime,
    'attendingDoctor.createdAt': parseDateTime,
    'attendingDoctor.updatedAt': parseDateTime,
    'identifiers[].createdAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
    'activePatientTreatments[].createdAt': parseDateTime,
    'activePatientTreatments[].updatedAt': parseDateTime,
    'activePatientTreatments[].startDate': parseDateTime,
    'activePatientTreatments[].endDate': parseDateTime,
  });
}

export function deserializeUser(user: UserT) {
  return deserialize(user, {
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
    deletedAt: parseDateTime,
  });
}

export function deserializeLabParameterOption(
  parameter: IdNameRecordT & { unit: string | null }
): LabParameterOptionT {
  return {
    label: parameter.name,
    value: parameter.id,
    unit: parameter.unit,
  };
}

export interface IntradialyticProductOption extends ProductOptionT {
  comboId: string;
  productId: string | undefined;
  consumptionSetId: string | undefined;
}

export function deserializeIntradialyticProductOption(
  product: IntradialyticProduct
): IntradialyticProductOption {
  return {
    label: product.name,
    value: product.id,
    form: product.form,
    highRiskMedication: product.highRiskMedication,
    multidose: product.multidose,
    productId: product.id,
    consumptionSetId: product.consumptionSetId,
    comboId: createIntradialyticProductComboId(
      product.id,
      product.consumptionSetId
    ),
  };
}

export function deserializeEncounter(encounter: EncounterT) {
  const serialized = deserialize(encounter, {
    'date': parseDateTime,
    'encounterType': deserializeEncounterType,
    'createdAt': parseDateTime,
    'updatedAt': parseDateTime,
    'documents[].createdAt': parseDateTime,
  });

  const encounterTypeTemplate = serialized.encounterFormDefinition
    ? parseForm(serialized.encounterFormDefinition, serialized.values)
    : null;

  const values = encounterTypeTemplate
    ? extractConfigValues(encounterTypeTemplate)
    : {};

  return {
    ...serialized,
    encounterTypeTemplate,
    values,
  };
}

export function deserializeEncounterType(encounterType: EncounterTypeT) {
  return deserialize(encounterType, {
    encounterFormDefinition: parseForm,
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
    deletedAt: parseDateTime,
  });
}

export function createIntradialyticProductComboId(
  productId: string | null | undefined,
  consumptionSetId: string | null | undefined
) {
  return consumptionSetId
    ? `product_id_${productId}_consumption_set_id_${consumptionSetId}`
    : `product_id_${productId}`;
}
