import { useQuery } from 'react-query';

import { http } from 'api/client';
import { UserPermissionT } from 'api/fetchers/types';

type AuthStrategy = 'db' | 'ldap' | 'krb' | 'oidc';
type AccessType = Exclude<UserPermissionT, 'integration'>;
type PosologyType = 'structured' | 'free_text';

type ConfigurationType =
  | AuthStrategy
  | AccessType
  | string
  | number
  | boolean
  | string[];

export interface Configuration {
  id: string;
  type: 'string' | 'integer' | 'boolean' | 'list';
  value?: ConfigurationType | null;
  default?: ConfigurationType | null;
  key: string;
  title: string;
  description: string;
  allowedValues: OptionT[];
}

const days: Day[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

// Fetch configuration hooks
export const CONFIGURATIONS_QUERY_KEY = 'configurations';

export function useConfigurations() {
  const { data, isLoading } = useQuery(CONFIGURATIONS_QUERY_KEY, () =>
    fetchConfigurations()
  );

  return { configurations: data, isLoading };
}

function useConfiguration<T extends ConfigurationType>(
  key: string,
  defaultValue: T
) {
  const { data = [] } = useQuery(
    CONFIGURATIONS_QUERY_KEY,
    () => fetchConfigurations(),
    {
      staleTime: Infinity,
    }
  );

  const configuration = data.find((config) => config.key === key);

  return (configuration?.value as T) ?? defaultValue;
}

async function fetchConfigurations() {
  const { data } = await http.get<{ configurations: Configuration[] }>(
    'configurations'
  );

  return data.configurations;
}

// System configuration hooks

export function useJournalEHRPublishingEnabled() {
  return useConfiguration<boolean>('telegraph.journal_item', false);
}

export function useStrongPasswordPolicy() {
  return useConfiguration<boolean>('auth.enforce_password_policy', false);
}

export function useWorkbenchEnabled() {
  return useConfiguration<boolean>('workbench', false);
}

export function useRecordEnabled() {
  return useConfiguration<boolean>('record', false);
}

export function useTrackEnabled() {
  return useConfiguration<boolean>('track', false);
}

export function useTrackTreatmentsEnabled() {
  return useConfiguration<boolean>('track.treatment', false);
}

export function usePatientCardsEnabled() {
  return useConfiguration<boolean>('patient.cards', false);
}

export function useRightScopes() {
  const workbenchEnabled = useWorkbenchEnabled();
  const recordEnabled = useRecordEnabled();
  const patientCardsEnabled = usePatientCardsEnabled();

  const scopes: RightScopesT[] = ['admin'];

  if (patientCardsEnabled) scopes.push('patient_cards');
  if (workbenchEnabled) scopes.push('workbench');
  if (recordEnabled) scopes.push('record');

  return scopes;
}

export function useManualSessionEHRPublishingEnabled() {
  const isSessionSummaryEnabled = useConfiguration<boolean>(
    'telegraph.session_summary',
    false
  );
  const isManual =
    useConfiguration<string>(
      'telegraph.session_summary.frequency',
      'always'
    ) === 'adhoc';

  return isSessionSummaryEnabled && isManual;
}

export function useAmbulatoryMedicationEnabled() {
  return useConfiguration<boolean>('record.home_medication', false);
}

export function useBillingByTreatmentModality() {
  return useConfiguration<boolean>(
    'record.billing.by_treatment_modality',
    false
  );
}

export function useAmbulatoryPrescriptionsEnabled() {
  return useConfiguration<boolean>(
    'record.integration.belgium.ehealth.recip_e',
    false
  );
}

export function useManualPatientCreationEnabled() {
  return !useConfiguration<boolean>('record.integration.adt', true);
}

export function useAuthenticationStrategy() {
  return useConfiguration<AuthStrategy>('auth.authenticator', 'db');
}

export function usePasswordEnforcingAuthentication() {
  const authStrategyOIDC = useAuthenticationStrategy() === 'oidc';
  const authStrategyLDAP = useAuthenticationStrategy() === 'ldap';

  return !(authStrategyOIDC || authStrategyLDAP);
}

export function useAccountLockable() {
  return useConfiguration<boolean>('auth.lockable', false);
}

export function useStagingRibbon() {
  return useConfiguration<boolean>('display_staging_ribbon', false);
}

export function useFirstDayOfWeek(): WeekStartDay {
  const firstDayOfWeek = useConfiguration<Day>('first_day_of_week', 'monday');

  return days.indexOf(firstDayOfWeek) as WeekStartDay;
}

export function useWeekendDays(): WeekDay[] {
  const weekendDays = useConfiguration<Day[]>('weekend_days', [
    'saturday',
    'sunday',
  ]);

  return weekendDays.map((day) => days.indexOf(day) as WeekDay);
}

export function useUserAccessType() {
  return useConfiguration<AccessType>('user.access_type', 'global');
}

export function useMulticenterAccessRights() {
  return useConfiguration<boolean>('multicenter_access_rights', false);
}

export function useAgendaExternalRoomIdentifier() {
  return useConfiguration<boolean>('telegraph.agenda', false);
}

export function useAmbulatoryIsStructuredPosologyDefault() {
  const posology = useConfiguration<PosologyType>(
    'record.ambulatory_medication.posology.default',
    'free_text'
  );

  return posology === 'structured';
}
