import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import Icon from 'components/Icon';

import t from './translations';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

interface Props {
  column: string;
  sortOrder: SortOrder | undefined;
  onClick: (column: string) => void;
  children: ReactNode;
}

export default function TableSortableHeader(props: Props) {
  const { formatMessage } = useIntl();
  const { column, sortOrder, onClick, children } = props;

  return (
    <th onClick={() => onClick(column)}>
      <button
        type="button"
        className="table-sort"
        title={formatMessage(t.sort)}
      >
        {children}

        {sortOrder !== undefined ? (
          <Icon>
            {sortOrder === SortOrder.Asc ? 'arrow_drop_down' : 'arrow_drop_up'}
          </Icon>
        ) : null}
      </button>
    </th>
  );
}
