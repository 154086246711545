import { fromJS, Set } from 'immutable';

import {
  CREATE_INTRADIALYTIC_MEDICATION,
  GET_INTRADIALYTIC_MEDICATION,
  GET_INTRADIALYTIC_MEDICATION_HISTORY,
  GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS,
  GET_INTRADIALYTIC_MEDICATIONS,
  RESTART_INTRADIALYTIC_MEDICATION,
  STOP_INTRADIALYTIC_MEDICATION,
  UPDATE_INTRADIALYTIC_MEDICATION,
  VALIDATE_INTRADIALYTIC_MEDICATION,
} from 'store/modules/entities/actions/medication';

import {
  isSuggestion,
  MEDICATION_VALIDATION_TYPE,
} from 'views/record/patients/patient/medication/MedicationValidationType';

import { sortInlineMedications } from './index';

export default function intradialyticReducer(state: any, action: any) {
  switch (action.type) {
    case GET_INTRADIALYTIC_MEDICATIONS.SUCCESS: {
      return setMedicationCollection(state, action);
    }

    case GET_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return setMedicationCollection(state, action);
    }

    case GET_INTRADIALYTIC_MEDICATION_HISTORY.SUCCESS: {
      return setMedicationCollection(state, action);
    }

    case GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS.SUCCESS: {
      return setMedicationCollection(state, action, 'suggestion');
    }

    case STOP_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return removeMedication(state, action);
    }

    case RESTART_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return removeMedication(state, action);
    }

    case CREATE_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return createMedication(state, action);
    }

    case UPDATE_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return updateMedication(state, action);
    }

    case VALIDATE_INTRADIALYTIC_MEDICATION.SUCCESS: {
      return updateMedicationValidation(state, action);
    }

    default: {
      return state;
    }
  }
}

const MEDICATION_VALIDATION_SUGGESTION = 'suggestion';
const MEDICATION_VALIDATION_APPROVED = 'approved';
const MEDICATION_VALIDATION_REJECTED = 'rejected';

const BY_ID = ['intradialyticMedication', 'byId'];
const MEDICATION_VALIDATION_PATH = ['medicationValidations'];

const SINGLE_RESULT = 'medications/intradialyticMedicationPrescription';
const PLURAL_RESULT = 'medications/intradialyticMedicationPrescriptions';

const byXPath = (patientId: string, path = 'byPatientId') => [
  'intradialyticMedication',
  path,
  patientId,
];

const shouldShowInMedicationsTable = (
  medication: MedicationT,
  validation: MedicationValidationT,
  action: { payload: { status: string } }
) => {
  const { status } = action.payload;
  const now = new Date();

  switch (status) {
    case 'active':
      if (
        medication.startDate < now &&
        (!medication.endDate || medication.endDate > now)
      ) {
        return true;
      }
      if (
        medication.startDate &&
        medication.endDate &&
        Number(medication.startDate) === Number(medication.endDate)
      ) {
        return true;
      }

      return false;
    case 'future':
      return medication.startDate > now;
    case 'stopped':
      return !!medication.endDate && medication.endDate < now;
    case 'rejected':
      return validation?.status === MEDICATION_VALIDATION_REJECTED;
    default:
      return true;
  }
};

const setMedicationCollection = (
  state: any,
  action: any,
  validationType: 'suggestion' | 'other' = 'other'
) => {
  const {
    response: {
      entities: { intradialyticMedication: medications, medicationValidations },
      result,
    },
    patientId,
  } = action.payload;

  const resultIds = result[PLURAL_RESULT];
  const resultId = result[SINGLE_RESULT];
  const ids = Set(resultIds || (resultId ? [resultId] : []));

  const medicationValidationsFromStore =
    state.getIn(MEDICATION_VALIDATION_PATH)?.toJS() || {};

  const suggestedMedicationIds = Object.values(medicationValidationsFromStore)
    ?.filter(
      (suggestedMedication: any) =>
        suggestedMedication.status === MEDICATION_VALIDATION_SUGGESTION
    )
    ?.map((suggestedMedication: any) => suggestedMedication.id);

  const intradialyticMedicationsFromStore = state.getIn(BY_ID)?.toJS() || {};

  const intradialyticMedicationValues =
    Object.values(intradialyticMedicationsFromStore)?.filter(
      (unvalidatedMedication: any) =>
        suggestedMedicationIds.includes(
          unvalidatedMedication.medicationValidation
        )
    ) || [];

  const combinedMedications = [
    ...intradialyticMedicationValues,
    ...Object.values(medications || {}),
  ];

  const combinedMedicationsObject = Object.fromEntries(
    Object.values(combinedMedications).map((value: any) => [value.id, value]) ||
      []
  );

  const medicationMergeMethod =
    validationType === MEDICATION_VALIDATION_SUGGESTION ? 'mergeIn' : 'setIn';

  return state[medicationMergeMethod](
    BY_ID,
    fromJS(combinedMedicationsObject || {})
  )
    .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations || {}))
    .updateIn(
      byXPath(
        patientId,
        validationType === MEDICATION_VALIDATION_SUGGESTION
          ? 'byPatientIdSuggestions'
          : 'byPatientId'
      ),
      (set = Set()) => set.concat(ids)
    );
};

const createMedication = (state: any, action: any) => {
  const {
    response: {
      entities: { intradialyticMedication: medications, medicationValidations },
      result,
    },
    patientId,
  } = action.payload;

  const medicationId = result[SINGLE_RESULT];
  const medication = medications[medicationId];
  const medicationValidation =
    medicationValidations?.[medication.medicationValidation];

  const isSuggestion =
    medicationValidation?.status === MEDICATION_VALIDATION_SUGGESTION;

  return state
    .mergeDeepIn(BY_ID, fromJS(medications))
    .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations || {}))
    .updateIn(byXPath(patientId, 'byPatientId'), (set = Set()) =>
      !isSuggestion &&
      shouldShowInMedicationsTable(medication, medicationValidation, action)
        ? set.add(medicationId)
        : set
    )
    .updateIn(byXPath(patientId, 'byPatientIdSuggestions'), (set = Set()) =>
      set.add(medicationId)
    );
};

const updateMedication = (state: any, action: any) => {
  const {
    response: {
      entities: { intradialyticMedication: medications, medicationValidations },
      result,
    },
    patientId,
  } = action.payload;

  const medicationId = result[SINGLE_RESULT];
  const medication = medications[medicationId];
  const medicationValidation =
    medicationValidations?.[medication.medicationValidation];

  const isApproved =
    medicationValidation?.status === MEDICATION_VALIDATION_APPROVED;

  return state
    .mergeIn(BY_ID, fromJS(medications))
    .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations || {}))
    .updateIn(byXPath(patientId, 'byPatientId'), (set = Set()) =>
      isApproved &&
      shouldShowInMedicationsTable(medication, medicationValidation, action)
        ? set.add(medicationId)
        : set
    )
    .updateIn(byXPath(patientId, 'byPatientIdSuggestions'), (set = Set()) =>
      isApproved ? set.filter((id) => id !== medicationId) : set
    );
};

const updateMedicationValidation = (state: any, action: any) => {
  const {
    response: {
      entities: { medicationValidations },
      result: { medicationValidation: validationId },
    },
    medicationId,
    patientId,
  } = action.payload;

  if (!medicationValidations) return state;

  const medicationValidation = medicationValidations[validationId];
  const medication = state.getIn([...BY_ID, medicationId]).toJS();

  return state
    .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations))
    .updateIn(byXPath(patientId, 'byPatientId'), (set = Set()) =>
      shouldShowInMedicationsTable(medication, medicationValidation, action) &&
      medicationValidation.status !== MEDICATION_VALIDATION_REJECTED
        ? set.add(medicationId)
        : set.remove(medicationId)
    )
    .updateIn(byXPath(patientId, 'byPatientIdSuggestions'), (set = Set()) =>
      set.filter((id) => id !== medicationId)
    );
};

const removeMedication = (state: any, action: any) => {
  const { id: medicationId, patientId } = action.payload;

  return state
    .deleteIn([...BY_ID, medicationId])
    .updateIn(byXPath(patientId, 'byPatientId'), (set = Set()) =>
      set.filter((id) => id !== medicationId)
    )
    .updateIn(byXPath(patientId, 'byPatientIdSuggestions'), (set = Set()) =>
      set.filter((id) => id !== medicationId)
    );
};

const buildMedications = (state: any, medications: any[]) => {
  const { medicationValidations } = state.entities.toJS();

  return medications.map((m) => ({
    ...m,
    medicationValidation: medicationValidations[m.medicationValidation],
  }));
};

export const intradialyticMedicationsSelector = (
  state: any,
  {
    patientId = 'undefined',
    fetchType = 'normal',
    keepSuggestions = false,
  }: {
    patientId?: string;
    fetchType?: 'normal' | 'history';
    keepSuggestions?: boolean;
  } = {}
): IntradialyticMedicationT[] | undefined => {
  const isFetching =
    fetchType === 'history'
      ? !!state.network.GET_INTRADIALYTIC_MEDICATION_HISTORY
      : !!(
          state.network.GET_INTRADIALYTIC_MEDICATIONS ||
          state.network.GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS
        );

  if (isFetching) return;

  const { intradialyticMedication } = state.entities.toJS();

  const intradialyticMedicationByPatientId =
    intradialyticMedication?.byPatientId?.[patientId];

  const intradialyticMedicationById = intradialyticMedicationByPatientId
    ?.map((medId: string) => intradialyticMedication.byId[medId])
    .filter(Boolean);

  const medications = intradialyticMedicationById
    ? buildMedications(state, intradialyticMedicationById)
    : [];

  const filteredMedication = !keepSuggestions
    ? medications?.filter((medication) => !isSuggestion(medication))
    : medications;

  return sortInlineMedications(filteredMedication).sort((a, b) => {
    const aStatus = a.medicationValidation?.status;
    const bStatus = b.medicationValidation?.status;

    if (
      aStatus === MEDICATION_VALIDATION_TYPE.validationRequired &&
      bStatus === MEDICATION_VALIDATION_TYPE.approved
    ) {
      return 1;
    }

    if (
      aStatus === MEDICATION_VALIDATION_TYPE.approved &&
      bStatus === MEDICATION_VALIDATION_TYPE.validationRequired
    ) {
      return -1;
    }

    return 0;
  });
};

export const unvalidatedIntradialyticMedicationsSelector = (
  state: any,
  patientId = '',
  type:
    | typeof MEDICATION_VALIDATION_TYPE.suggestion
    | typeof MEDICATION_VALIDATION_TYPE.validationRequired
): IntradialyticMedicationT[] | undefined => {
  const isFetching = !!state.network.GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS;

  if (isFetching) return;

  const { intradialyticMedication } = state.entities.toJS();

  const intradialyticMedicationByPatientId =
    intradialyticMedication?.['byPatientIdSuggestions']?.[patientId];

  const intradialyticMedicationById = intradialyticMedicationByPatientId
    ?.map((medId: string) => intradialyticMedication.byId[medId])
    .filter(Boolean);

  const medications = intradialyticMedicationById
    ? buildMedications(state, intradialyticMedicationById)
    : [];

  const medicationSuggestions = medications.filter((medication) => {
    const status = medication?.medicationValidation?.status;

    return type === 'suggestion'
      ? status === MEDICATION_VALIDATION_TYPE.suggestion
      : status === MEDICATION_VALIDATION_TYPE.validationRequired;
  });

  return sortInlineMedications(medicationSuggestions);
};
