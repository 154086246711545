import { Suspense, useEffect } from 'react';
import qs from 'qs';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  useRecordEnabled,
  useTrackEnabled,
  useWorkbenchEnabled,
} from 'hooks/useConfig';

import Loading from 'components/Loading';
import Main from 'components/Main';

import {
  adminRights,
  AdminRoutes,
  AdminSidebar,
  useAdminEnabled,
} from 'views/admin';
import { RecordRoutes, RecordSidebar } from 'views/record';
import { TrackRoutes, TrackSidebar } from 'views/track';
import { WorkbenchRoutes, WorkbenchSidebar } from 'views/workbench';

import ErrorPage from '../error';
import ForbiddenPage from '../forbidden';
import NotFoundPage from '../notFound';
import ErrorModal from './ErrorModal';
import Header from './Header';

const setIntegrationFlag = (integration) =>
  localStorage.setItem('integration', integration);

const getIntegrationFlag = () =>
  !!parseInt(localStorage.getItem('integration') || '', 10);

const SupsenseFallback = () => (
  <Main>
    <Loading />
  </Main>
);

export default function AuthenticatedApp() {
  const { search } = useLocation();

  useEffect(() => {
    const values = qs.parse(search, { ignoreQueryPrefix: true });

    if (values.integration === '1') setIntegrationFlag(values.integration);
  }, [search]);

  const integrationActive = getIntegrationFlag();

  useEffect(() => {
    if (document.body) {
      if (integrationActive) {
        document.body.classList.add('integration');
      } else {
        document.body.classList.remove('integration');
      }
    }
  }, [integrationActive]);

  const recordEnabled = useRecordEnabled();
  const workbenchEnabled = useWorkbenchEnabled();
  const trackEnabled = useTrackEnabled();
  const adminEnabled = useAdminEnabled(adminRights);

  const redirectPath = recordEnabled
    ? '/record'
    : trackEnabled
    ? '/track'
    : workbenchEnabled
    ? '/workbench'
    : adminEnabled
    ? '/admin'
    : '/not_found';

  return (
    <div className="wrapper">
      {!integrationActive ? (
        <>
          <Routes>
            {recordEnabled ? (
              <Route path="record/*" element={<Header enableSearch />} />
            ) : null}
            {workbenchEnabled ? (
              <Route path="workbench/*" element={<Header />} />
            ) : null}
            {trackEnabled ? (
              <Route path="track/*" element={<Header />} />
            ) : null}
            {adminEnabled ? (
              <Route path="admin/*" element={<Header />} />
            ) : null}
          </Routes>

          <Routes>
            {recordEnabled ? (
              <Route path="record/*" element={<RecordSidebar />} />
            ) : null}
            {workbenchEnabled ? (
              <Route path="workbench/*" element={<WorkbenchSidebar />} />
            ) : null}
            {trackEnabled ? (
              <Route path="track/*" element={<TrackSidebar />} />
            ) : null}
            {adminEnabled ? (
              <Route path="admin/*" element={<AdminSidebar />} />
            ) : null}
          </Routes>
        </>
      ) : null}

      <Suspense fallback={<SupsenseFallback />}>
        <Routes>
          <Route path="/" element={<Navigate to={redirectPath} />} />

          {recordEnabled ? (
            <Route path="record/*" element={<RecordRoutes />} />
          ) : null}
          {workbenchEnabled ? (
            <Route path="workbench/*" element={<WorkbenchRoutes />} />
          ) : null}
          {trackEnabled ? (
            <Route path="track/*" element={<TrackRoutes />} />
          ) : null}
          {adminEnabled ? (
            <Route path="admin/*" element={<AdminRoutes />} />
          ) : null}

          <Route path="error" element={<ErrorPage />} />
          <Route path="forbidden" element={<ForbiddenPage />} />
          <Route path="not_found" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/not_found" />} />
        </Routes>
      </Suspense>

      <ErrorModal />
    </div>
  );
}
