import { Link, useLocation } from 'react-router-dom';

import Logo from 'sharedComponents/Logo';

import HeaderCurrentUser from './CurrentUser';
import HeaderSearch from './Search';
import HeaderSignOut from './SignOut';

interface Props {
  enableSearch?: boolean;
}

export default function Header({ enableSearch = false }: Props) {
  const { pathname } = useLocation();
  const path = `/${pathname.split('/', 2)[1]}`;

  return (
    <nav className="app-header">
      <div className="app-header-left">
        <Link to={path} className="app-header-logo" title="NephroFlow">
          <Logo isWhite />
        </Link>

        {enableSearch ? <HeaderSearch /> : null}
      </div>

      <div className="app-header-right">
        <HeaderCurrentUser />
        <HeaderSignOut />
      </div>
    </nav>
  );
}
