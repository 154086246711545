type SizeCustomProperty =
  | '--size-max-width'
  | '--size-spacing'
  | '--size-form-label-width'
  | '--size-border-radius'
  | '--size-sidebar-width'
  | '--size-app-header-height'
  | '--size-min-content-width'
  | '--size-screen-small'
  | '--size-screen-medium'
  | '--size-screen-large';

type ColorCustomProperty = '--color-font' | '--color-border';

type FontCustomProperty =
  | '--font-sans'
  | '--font-serif'
  | '--font-regular'
  | '--font-bold'
  | '--font-size'
  | '--font-line-height';

type AnimCustomProperty = '--anim-transition-speed';

type LayerCustomProperty = '--layer-full-screen-rte-z-index';

type BrandCustomProperty =
  | '--success'
  | '--success-dark'
  | '--danger'
  | '--info'
  | '--warning'
  | '--info-alternative'
  | '--info-highlighted'
  | '--black'
  | '--gray-darkest'
  | '--gray-darker'
  | '--gray-dark'
  | '--gray'
  | '--gray-light'
  | '--gray-lighter'
  | '--gray-lightest'
  | '--white';

type CustomProperty =
  | SizeCustomProperty
  | ColorCustomProperty
  | FontCustomProperty
  | AnimCustomProperty
  | LayerCustomProperty
  | BrandCustomProperty;

export function getString(
  property: CustomProperty,
  element: Element = document.documentElement
) {
  return getComputedStyle(element).getPropertyValue(property).trim();
}

export function getInt(
  property: CustomProperty,
  element: Element = document.documentElement
) {
  return parseInt(getComputedStyle(element).getPropertyValue(property), 10);
}
