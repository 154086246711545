import { DATE_FORMAT_MANUAL_INPUT as dateFormat } from 'locale';

const dateRegex = (() => {
  const separator = '[^\\d]+';
  const regexParts = {
    Y: '(\\d{4})',
    M: '(\\d{1,2})',
    D: '(\\d{1,2})',
  };

  return new RegExp(dateFormat.map((p) => regexParts[p]).join(separator));
})();

function stringToDate(value: string) {
  const match = value.match(dateRegex);

  if (!match) return null;

  const year = parseInt(match[dateFormat.indexOf('Y') + 1], 10);
  const month = parseInt(match[dateFormat.indexOf('M') + 1], 10);
  const day = parseInt(match[dateFormat.indexOf('D') + 1], 10);

  if (month < 1 || month > 12) return null;
  if (day < 1 || day > 31) return null;

  return new Date(year, month - 1, day, 12, 0, 0, 0);
}

export default stringToDate;
