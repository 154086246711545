import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { fetchPatient, searchPatients } from 'api/fetchers';
import { selectPatientOptions } from 'api/fetchers/utils';

import useIsMounted from 'hooks/useIsMounted';

import { ListInput, ListInputProps } from 'components/Inputs';

import PatientOption from 'sharedComponents/reactSelect/PatientOption';
import PatientSingleValue from 'sharedComponents/reactSelect/PatientSingleValue';

export interface PatientInputProps<M extends boolean = boolean>
  extends CustomOmit<
    ListInputProps<M extends true ? string[] : string, PatientOptionT, M>,
    'options' | 'fetchOptions'
  > {
  scope: 'enrolled' | 'not_enrolled';
  onFetchedOptions?: (options: PatientOptionT[]) => void;
}

export default function PatientInput<M extends boolean = boolean>({
  scope,
  onFetchedOptions,
  placeholder,
  components,
  value,
  disabled,
  ...listInputProps
}: PatientInputProps<M>) {
  const { formatMessage } = useIntl();

  const [initialOptions, setInitialOptions] = useState<
    PatientOptionT[] | undefined
  >();

  const isMounted = useIsMounted();

  const fetchOptions = async (query: string) => {
    const patients = await searchPatients({
      query,
      ...(scope !== 'enrolled' ? { searchScope: scope } : null),
    });
    const options = selectPatientOptions(patients);
    onFetchedOptions?.(options);

    return options;
  };

  const ranEffect = useRef(false);
  useEffect(() => {
    if (!ranEffect.current) {
      if (value) {
        (async () => {
          const patientIds: string[] = !Array.isArray(value) ? [value] : value;
          const promises = patientIds.map((id) => fetchPatient(id));
          const patients = await Promise.all(promises).catch(() => {});

          if (patients && isMounted()) {
            const options = selectPatientOptions(patients);
            setInitialOptions(options);
          }
        })();
      } else {
        setInitialOptions([]);
      }
    }

    return () => {
      ranEffect.current = true;
    };
  }, [isMounted, value]);

  const loading = typeof initialOptions === 'undefined' ? true : undefined;

  return (
    <ListInput
      {...listInputProps}
      value={value}
      options={initialOptions || []}
      loading={loading}
      disabled={disabled || loading}
      placeholder={
        placeholder ||
        formatMessage(
          listInputProps.multiple ? t.placeholderMultiple : t.placeholder
        )
      }
      components={{
        SingleValue: PatientSingleValue,
        Option: PatientOption,
        ...components,
      }}
      fetchOptions={fetchOptions}
    />
  );
}

const t = defineMessages({
  placeholder: {
    id: 'patient_input_placeholder',
    defaultMessage: 'Type to search for a patient...',
  },
  placeholderMultiple: {
    id: 'patient_input_placeholder_multiple',
    defaultMessage: 'Type to search for patients...',
  },
});
