import { fromJS, Map } from 'immutable';
import { Action } from 'redux';

import { combineReducers } from 'store/utils';

import acls from './acls';
import codeSystems from './codeSystems';
import conditions from './conditions';
import patientConditions from './conditions/patient';
import entityCodes from './entityCodes';
import exportCenter from './exportCenter';
import journalEntries from './journalEntries';
import logbookEntries from './logbookEntries';
import medicationAdministrations from './medication/administrations';
import ambulatoryMedication from './medication/ambulatory';
import ambulatoryPrescriptions from './medication/ambulatoryPrescriptions';
import medicationInterruptions from './medication/interruptions';
import intradialyticMedication from './medication/intradialytic';
import invalidPrescriptionsOverview from './medication/invalidPrescriptionsOverview';
import ambulatoryMedicationSchedule from './medication/schedule';
import medicinalTherapyPlans from './medication/therapyPlans';
import medicinalTherapyPlanTypes from './medication/therapyPlanTypes';
import medicationImport from './medicationImport';
import medicationManagement from './medicationManagement';
import patients from './patients';
import tags from './tags';

export type EntitiesState = Map<any, any>;

interface EntitiesAction extends Action<string> {
  payload?: any;
}

export const initialState: EntitiesState = fromJS({
  ambulatoryMedicationSchedule: {},
  ambulatoryPrescriptions: { byPatientId: {}, byId: {} },
  ambulatoryPrescriptionItems: { byPrescriptionId: {}, byId: {} },
  ambulatoryPrescriptionDraftItems: {},
  intradialyticPrescribableMedicinalProductItems: { byId: {} },
  codes: { byId: {}, byCodeSystem: {} },
  conditions: { byId: {}, byResult: [] },
  entityCodes: { byId: {}, byEntity: {} },
  codeSystems: {},
  deactivations: { byId: {}, byPatientId: {} },
  exportCenter: {
    exportables: { byId: {}, byResult: [] },
    billingItems: {},
    patients: {},
  },
  invalidPrescriptionsOverview: { byId: {}, ambulatory: [], intradialytic: [] },
  journalEntries: { byId: {}, byPatientId: {} },
  journalEntryComments: {},
  labResults: {
    results: { byId: {}, byTherapyPlanPatientId: {}, byTherapyPlanId: {} },
    observationTypes: { byId: {}, byTherapyPlanPatientId: {} },
  },
  logbookEntries: { byId: {}, bySessionId: {} },
  logbookCategores: {},
  medicinalAdministrationRoutes: { byId: {}, byProductId: {} },
  medicationInterruptions: { byId: {}, byPatientId: {} },
  medicationInterruptionsHistory: { byId: {}, byInterruptionId: {} },
  medicationAdministrations: { byId: {}, byPatientId: {}, bySessionId: {} },
  medicationValidations: {},
  medicinalTherapyPlans: { byId: {}, byPatientId: {} },
  medicinalTherapyPlanCalendars: {},
  medicinalTherapyPlanCalendarDates: {},
  medicinalTherapyPlanCalendarDateEntries: {},
  medicinalTherapyPlanTypes: {},
  medicinalTherapyPlanCategories: {},
  medicinalTherapyPlanAdministrationDefaults: {},
  medicinalIngredients: {},
  medicinalConsumptionSets: { byId: {}, byProductId: {} },
  medicinalConsumptionSetItems: { byId: {} },
  medicinalProductParentConsumptionSetItems: { byId: {} },
  medicinalImportItems: { importExecution: {}, searchExecution: {}, items: {} },
  medicinalNotGivenReasons: {},
  medicinalProductIngredients: {},
  medicinalPackages: {},
  medicinalPrices: {},
  medicinalProductItems: { byId: {}, byResult: [] },
  medicinalProductProviders: {},
  patients: {},
  patientIdentifiers: {},
  patientInsurances: { byId: {}, byPatientId: {} },
  patientConditions: { byId: {}, byPatientId: {} },
  prescribableUnits: { byId: {}, byProductId: {} },
  tags: {},
  unitAliases: {},
  userPermissions: { byId: {}, byResult: [] },
});

export default function reducer(
  state: EntitiesState = initialState,
  action: EntitiesAction
): EntitiesState {
  const entitiesReducer = combineReducers(
    acls,
    ambulatoryMedication,
    ambulatoryMedicationSchedule,
    ambulatoryPrescriptions,
    codeSystems,
    conditions,
    entityCodes,
    exportCenter,
    intradialyticMedication,
    invalidPrescriptionsOverview,
    journalEntries,
    logbookEntries,
    medicationAdministrations,
    medicationImport,
    medicationInterruptions,
    medicationManagement,
    medicinalTherapyPlanTypes,
    medicinalTherapyPlans,
    patientConditions,
    patients,
    tags
  );

  return entitiesReducer(state, action);
}
