import { camelize, camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { Action } from 'redux';

import getType from 'utils/getType';

// Actions
//==============================================================================

export const FORBIDDEN = 'FORBIDDEN';
export const METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED';
export const SERVER_ERROR = 'SERVER_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE';

// Action Creators
//==============================================================================

export function forbidden({ failureType }: { failureType: string }) {
  return {
    type: FORBIDDEN,
    payload: { failedAction: failureType },
    error: true,
  };
}

export function methodNotAllowed({ failureType }: { failureType: string }) {
  return {
    type: METHOD_NOT_ALLOWED,
    payload: { failedAction: failureType },
    error: true,
  };
}

export function serverError({ failureType }: { failureType: string }) {
  return {
    type: SERVER_ERROR,
    payload: { failedAction: failureType },
    error: true,
  };
}

export function notFound({ failureType }: { failureType: string }) {
  return {
    type: NOT_FOUND,
    payload: { failedAction: failureType },
    error: true,
  };
}

export function payloadTooLarge({ failureType }: { failureType: string }) {
  return {
    type: PAYLOAD_TOO_LARGE,
    payload: { failedAction: failureType },
    error: true,
  };
}

export function networkError(
  type: string,
  rd: ApiRequestDescription | undefined,
  json: any
): ApiErrorResponseT {
  if (Array.isArray(json?.errors)) {
    const errors = camelizeKeys(json.errors).map((error: any) => ({
      source: error.source ? camelize(error.source) : undefined,
      title: error.title ? error.title : undefined,
      detail: error.detail ? error.detail : undefined,
      meta: {
        request: rd?.params || rd?.payload,
      },
    }));

    return { type, payload: errors, error: true };
  }

  if (json?.error) {
    return { type, payload: json.error, error: true };
  }

  return { type, payload: json?.errors, error: true };
}

export function networkResponse(
  type: string,
  rd: ApiRequestDescription,
  json: any
): ApiSuccessResponseT {
  const response = rd.normalizeSchema
    ? normalize(json, rd.normalizeSchema)
    : json;

  const actionPayload = rd.actionPayload || {};

  return {
    type,
    payload: {
      ...actionPayload,
      request: rd.params || rd.payload,
      response,
    },
    error: false,
  };
}

// Reducer
//==============================================================================

interface NetworkState {
  [networkType: string]: boolean;
}

type NetworkAction = Action<string>;

export const initialState = {};

export default function networkReducer(
  state: NetworkState = initialState,
  action: NetworkAction
): NetworkState {
  const { type } = action;

  switch (true) {
    case type.includes('REQUEST'): {
      return { ...state, [getType(type)]: true };
    }

    case type.includes('SUCCESS'):
    case type.includes('FAILURE'): {
      return { ...state, [getType(type)]: false };
    }

    default: {
      return state;
    }
  }
}
