import { useRef, useState } from 'react';
import { acknowledgements } from 'acknowledgements.json';
import chunk from 'lodash/chunk';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { http } from 'api/client';

import { useRecordEnabled } from 'hooks/useConfig';

import Grid from 'components/Grid';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import { Pages } from 'components/Paginator';
import Section from 'components/Section';
import Table from 'components/Table';
import TableActiveCell from 'components/TableActiveCell';
import Tabs from 'components/Tabs';

import { ReactComponent as CeIndicator } from 'assets/images/ce.svg';
import { ReactComponent as DateOfManufactureIndicator } from 'assets/images/date-of-manufacture.svg';
import { ReactComponent as EifuIndicator } from 'assets/images/eifu.svg';
import { ReactComponent as ManufacturerIndicator } from 'assets/images/manufacturer.svg';

import { company, version } from '../../../../package.json';

const TABS = {
  USR: 'userDocumentation',
  MOD: 'moduleInformation',
  ACK: 'acknowledgements',
};

const MODULES = {
  MAN: 'manager',
  SER: 'server',
  KTV: 'ktv',
  UFV: 'ufv',
};

interface VersionInfo {
  version: string;
  ifu: string;
  dateOfManufacture: string;
}

interface InfoModalProps {
  show: boolean;
  cancel: () => void;
}

interface PackageT {
  name: string;
  version: string;
  repository?: string;
  license: string | string[];
}

export default function InfoModal({ show, cancel }: InfoModalProps) {
  return (
    <Modal
      show={show}
      large
      showCancel={false}
      showSubmit={false}
      title={`NephroFlow Manager ${version}`}
      contentClassName="without-padding"
      cancel={cancel}
    >
      <ModalContent />
    </Modal>
  );
}

function ModalContent() {
  const [tab, setTab] = useState(TABS.USR);
  const { isLoading } = useVersionInfo(true);

  const modalContentRef = useRef<HTMLDivElement>(null);

  if (isLoading) {
    return <Loading type="static" />;
  }

  const onLoadAcknowledgmentsPage = () => {
    modalContentRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  };

  return (
    <div className="info-modal" ref={modalContentRef}>
      <Tabs.List>
        <Tabs.Tab
          id={TABS.USR}
          active={tab === TABS.USR}
          onClick={() => setTab(TABS.USR)}
        >
          <FormattedMessage {...t.userDocumentation} />
        </Tabs.Tab>

        <Tabs.Tab
          id={TABS.MOD}
          active={tab === TABS.MOD}
          onClick={() => setTab(TABS.MOD)}
        >
          <FormattedMessage {...t.moduleInformation} />
        </Tabs.Tab>

        <Tabs.Tab
          id={TABS.ACK}
          active={tab === TABS.ACK}
          onClick={() => setTab(TABS.ACK)}
        >
          <FormattedMessage {...t.acknowledgements} />
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panels>
        <Tabs.Panel id={TABS.USR} active={tab === TABS.USR}>
          <UserDocumentation />
        </Tabs.Panel>

        <Tabs.Panel id={TABS.MOD} active={tab === TABS.MOD}>
          <ModuleInformation />
        </Tabs.Panel>

        <Tabs.Panel id={TABS.ACK} active={tab === TABS.ACK}>
          <Acknowledgments
            packages={acknowledgements}
            onLoadPage={onLoadAcknowledgmentsPage}
          />
        </Tabs.Panel>
      </Tabs.Panels>
    </div>
  );
}

function UserDocumentation() {
  const { data } = useVersionInfo(false);

  const items = [
    {
      icon: EifuIndicator,
      detail: () => (
        <>
          <FormattedMessage {...t.instructionsForUseDescription} />
          <br />
          <a href={data?.ifu} target="_blank" rel="noopener noreferrer">
            <FormattedMessage {...t.userManual} />
          </a>
        </>
      ),
    },
  ];

  return (
    <Table
      headers={['', t.detail]}
      items={items}
      renderItem={({ icon: Icon, detail }, index) => (
        <tr key={index} style={{ height: 75 }}>
          <td>
            <Icon style={{ width: 40 }} />
          </td>
          <td>{detail()}</td>
        </tr>
      )}
    />
  );
}

function Acknowledgments({
  packages,
  onLoadPage,
}: {
  packages?: PackageT[];
  onLoadPage: () => void;
}) {
  const chunkedPackages = chunk(packages, 100);
  const [page, setPage] = useState(0);

  return (
    <>
      <Table
        isSticky
        headers={[t.packageLabel, t.versionLabel, t.licenseLabel]}
        items={chunkedPackages[page] || []}
        fixedColumnWidths={['2fr', '1fr', '1fr']}
        renderItem={({ name, repository, version, license }) => (
          <tr key={`${name}_${version}`}>
            <td>
              <a href={repository} rel="noopener noreferrer" target="_blank">
                {name}
              </a>
            </td>
            <td>{version}</td>
            <td>{Array.isArray(license) ? license.join(', ') : license}</td>
          </tr>
        )}
      />

      <Pages
        currentPage={page}
        pageSize={100}
        totalCount={packages?.length || 0}
        totalPages={chunkedPackages.length}
        loadPage={(page) => {
          setPage(page);
          onLoadPage();
        }}
      />
    </>
  );
}

function ModuleInformation() {
  const { formatMessage } = useIntl();
  const [module, setModule] = useState(MODULES.MAN);

  const items = {
    [MODULES.MAN]: { label: formatMessage(t.manager), value: MODULES.MAN },
    [MODULES.SER]: { label: formatMessage(t.server), value: MODULES.SER },
    [MODULES.KTV]: { label: formatMessage(t.ktvModule), value: MODULES.KTV },
    [MODULES.UFV]: { label: formatMessage(t.ufvModule), value: MODULES.UFV },
  };

  const isActive = (value: string) => value === module;

  return (
    <Grid columns="1fr 2fr" withoutMargin>
      <Grid.Item style={{ borderRight: '1px solid var(--color-border)' }}>
        <Section withoutPadding>
          <Table
            isSticky
            headers={[t.module]}
            items={Object.values(items)}
            renderItem={({ label, value }) => (
              <tr
                key={value}
                onClick={() => setModule(value)}
                className="is-clickable"
              >
                <TableActiveCell isActive={isActive(value)}>
                  {label}
                </TableActiveCell>
              </tr>
            )}
          />
        </Section>
      </Grid.Item>

      <Grid.Item>
        <Section>
          <Modules module={module} items={items} />
        </Section>
      </Grid.Item>
    </Grid>
  );
}

function Modules({
  module,
  items,
}: {
  module: string;
  items: Record<string, OptionT>;
}) {
  const { data: versionInfo } = useVersionInfo(false);

  switch (module) {
    case MODULES.MAN:
      return (
        <ModuleDetail
          title={items[MODULES.MAN]}
          date={versionInfo?.dateOfManufacture}
          version={version}
        />
      );

    case MODULES.SER:
      return (
        <ModuleDetail
          title={items[MODULES.SER]}
          date={versionInfo?.dateOfManufacture}
          version={versionInfo?.version}
        />
      );

    case MODULES.KTV:
      return (
        <ModuleDetail
          title={items[MODULES.KTV]}
          date="2021-05-12"
          version="1.0.0"
        />
      );

    case MODULES.UFV:
      return (
        <ModuleDetail
          title={items[MODULES.UFV]}
          date="2021-05-12"
          version="1.0.0"
        />
      );

    default:
      return null;
  }
}

function ModuleDetail({
  title,
  date,
  version,
}: {
  title: OptionT;
  date: string | undefined;
  version: string | undefined;
}) {
  const { formatMessage } = useIntl();
  const recordEnabled = useRecordEnabled();

  const showCEMark =
    recordEnabled &&
    (title.value === MODULES.KTV || title.value === MODULES.UFV);

  return (
    <>
      <div className="flex flex-row">
        <strong>{title.label}</strong>
      </div>

      <div className="flex flex-row">
        <FormattedMessage
          {...t.version}
          values={{ version: version || 'x.x.x' }}
        />
      </div>

      <div className="flex flex-row justify-start items-start pt-4">
        <div className="flex" style={{ width: '50px' }}>
          <ManufacturerIndicator style={{ width: 20 }} />
        </div>

        <div style={{ textAlign: 'left' }}>
          {company.name}
          <br />
          {company.address}
          <br />
          {company.postalCode} {formatMessage(t.bruges)},{' '}
          {formatMessage(t.belgium)}
          <br />
          {formatMessage(t.rpr)} {company.rpr}
        </div>
      </div>

      <div className="flex flex-row justify-start items-center pt-4">
        <div className="flex" style={{ width: '50px' }}>
          <DateOfManufactureIndicator style={{ width: 20 }} />
        </div>

        <div>{date || '-'}</div>
      </div>

      {showCEMark ? (
        <div className="flex flex-row justify-start items-center pt-4">
          <div className="flex" style={{ width: '50px' }}>
            <CeIndicator style={{ width: 20 }} />
          </div>

          <div>
            <FormattedMessage {...t.ceMark} />
          </div>
        </div>
      ) : null}
    </>
  );
}

function useVersionInfo(enabled = false) {
  return useQuery(
    'versionInfo',
    async () => {
      const { data } = await http.get<VersionInfo>('version_info');

      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      retry: 1,
    }
  );
}

const t = defineMessages({
  bruges: {
    id: 'app_info_modal_bruges',
    defaultMessage: 'Bruges',
  },
  belgium: {
    id: 'app_info_modal_belgium',
    defaultMessage: 'Belgium',
  },
  userManual: {
    id: 'app_info_modal_user_manual',
    defaultMessage: 'User manual',
  },
  userDocumentation: {
    id: 'app_info_modal_user_documentation',
    defaultMessage: 'User documentation',
  },
  acknowledgements: {
    id: 'app_info_modal_acknowledgements',
    defaultMessage: 'Acknowledgements',
  },
  moduleInformation: {
    id: 'app_info_modal_module_information',
    defaultMessage: 'Module information',
  },
  detail: {
    id: 'app_info_modal_detail',
    defaultMessage: 'Detail',
  },
  ceMark: {
    id: 'app_info_modal_ce_mark',
    defaultMessage: 'European conformity marking',
  },
  instructionsForUseDescription: {
    id: 'app_info_modal_instructions_for_use_description',
    defaultMessage: 'Instructions for use are supplied electronically',
  },
  rpr: {
    id: 'app_info_modal_rpr',
    defaultMessage: 'RPR',
  },
  component: {
    id: 'app_info_modal_component',
    defaultMessage: 'Component',
  },
  module: {
    id: 'app_info_modal_module',
    defaultMessage: 'Module',
  },
  version: {
    id: 'app_info_modal_version',
    defaultMessage: 'Version {version}',
  },
  versionLabel: {
    id: 'app_info_modal_version_label',
    defaultMessage: 'Version',
  },
  packageLabel: {
    id: 'app_info_modal_npm_package',
    defaultMessage: 'Package',
  },
  licenseLabel: {
    id: 'app_info_modal_license',
    defaultMessage: 'License',
  },
  manager: {
    id: 'app_info_modal_manager',
    defaultMessage: 'NephroFlow Manager',
  },
  server: {
    id: 'app_info_modal_server',
    defaultMessage: 'NephroFlow API Server',
  },
  ktvModule: {
    id: 'app_info_modal_ktv_module',
    defaultMessage: 'NephroFlow Kt/V Module',
  },
  ufvModule: {
    id: 'app_info_modal_ufv_module',
    defaultMessage: 'NephroFlow UFV Module',
  },
});
