import { defineMessages, IntlShape } from 'react-intl';

export const long = defineMessages({
  sunday: { id: 'date_weekday_long_sunday', defaultMessage: 'Sunday' },
  monday: { id: 'date_weekday_long_monday', defaultMessage: 'Monday' },
  tuesday: { id: 'date_weekday_long_tuesday', defaultMessage: 'Tuesday' },
  wednesday: { id: 'date_weekday_long_wednesday', defaultMessage: 'Wednesday' },
  thursday: { id: 'date_weekday_long_thursday', defaultMessage: 'Thursday' },
  friday: { id: 'date_weekday_long_friday', defaultMessage: 'Friday' },
  saturday: { id: 'date_weekday_long_saturday', defaultMessage: 'Saturday' },
});

export const short = defineMessages({
  sunday: { id: 'date_weekday_short_sun', defaultMessage: 'Sun' },
  monday: { id: 'date_weekday_short_mon', defaultMessage: 'Mon' },
  tuesday: { id: 'date_weekday_short_tue', defaultMessage: 'Tue' },
  wednesday: { id: 'date_weekday_short_wed', defaultMessage: 'Wed' },
  thursday: { id: 'date_weekday_short_thu', defaultMessage: 'Thu' },
  friday: { id: 'date_weekday_short_fri', defaultMessage: 'Fri' },
  saturday: { id: 'date_weekday_short_sat', defaultMessage: 'Sat' },
});

function shiftOrderByStartOfWeek(list: any[], firstDayOfWeek: WeekStartDay) {
  const beginingOfWeek = list.splice(firstDayOfWeek);
  const endOfWeek = list.slice(0, firstDayOfWeek);

  return [...beginingOfWeek, ...endOfWeek];
}

function translate(intl: IntlShape, translations) {
  return Object.keys(translations).map<string>((weekDay) =>
    intl.formatMessage(translations[weekDay])
  );
}

function weekDaysOptions(
  intl: IntlShape,
  translations,
  firstDayOfWeek: WeekStartDay
) {
  const days = Object.keys(translations);
  const sortedDays = shiftOrderByStartOfWeek(days, firstDayOfWeek);

  return sortedDays.map<OptionT>((day) => ({
    label: intl.formatMessage(translations[day]),
    value: day,
  }));
}

export const weekDaysLong = (intl: IntlShape) => translate(intl, long);

export const weekDaysShort = (intl: IntlShape) => translate(intl, short);

export const sortedWeekDaysLong = (
  intl: IntlShape,
  firstDayOfWeek: WeekStartDay
) => shiftOrderByStartOfWeek(weekDaysLong(intl), firstDayOfWeek);

export const sortedWeekDaysShort = (
  intl: IntlShape,
  firstDayOfWeek: WeekStartDay
) => shiftOrderByStartOfWeek(weekDaysShort(intl), firstDayOfWeek);

export const weekDaysLongOptions = (
  intl: IntlShape,
  firstDayOfWeek: WeekStartDay
) => weekDaysOptions(intl, long, firstDayOfWeek);

export const weekDaysShortOptions = (
  intl: IntlShape,
  firstDayOfWeek: WeekStartDay
) => weekDaysOptions(intl, short, firstDayOfWeek);
