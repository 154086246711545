import { ReactNode, useRef } from 'react';
import cx from 'classnames';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import useDelayedAnimation from 'hooks/useDelayedAnimation';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';

import Portal from 'sharedComponents/Portal';

const t = defineMessages({
  closeTitle: { id: 'modal_close_title', defaultMessage: 'Close' },
  submitTitle: { id: 'modal_submit_title', defaultMessage: 'Submit' },
  cancel: { id: 'modal_cancel_button', defaultMessage: 'Cancel' },
  submit: { id: 'modal_submit_button', defaultMessage: 'Delete' },
});

const ANIMATION_DURATION = 1000;

type Props = {
  show: boolean;
  showClose?: boolean;
  showCancel?: boolean;
  showSubmit?: boolean;
  small?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  auto?: boolean;
  isCentered?: boolean;
  contentClassName?: string;
  submitButtons?: ReactNode;
  cancel: () => void;
  submit?: () => void;
  submitTitle?: string;
  title?: string | ReactNode;
  children?: ReactNode | null | undefined;
};

export default function Modal({
  show,
  showClose = true,
  showCancel = true,
  showSubmit = true,
  small = false,
  large = false,
  extraLarge = false,
  auto = false,
  isCentered = true,
  contentClassName,
  submitButtons: customSubmitButtons,
  cancel,
  submit,
  submitTitle,
  title,
  children,
}: Props) {
  const { formatMessage } = useIntl();

  const nodeRef = useRef<HTMLDivElement>(null);

  const shouldRender = useDelayedAnimation(show, ANIMATION_DURATION);

  const onCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    cancel();
  };

  const onSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    submit?.();
  };

  return (
    <Portal>
      <CSSTransition
        in={show}
        timeout={ANIMATION_DURATION}
        classNames="modal"
        nodeRef={nodeRef}
      >
        {show || shouldRender ? (
          <div ref={nodeRef}>
            <div
              className="modal-backdrop"
              role="presentation"
              onClick={onCancel}
            />
            <div
              className={cx('modal', {
                'is-small': small,
                'is-large': large,
                'is-extra-large': extraLarge,
                'is-auto': auto,
              })}
              data-testid="modal"
            >
              <div className="modal-container">
                {showClose || title ? (
                  <div className="modal-header">
                    <h6>{title}</h6>
                    <div className="modal-header-right">
                      {showClose && (
                        <ActionButton
                          icon="close"
                          title={formatMessage(t.closeTitle)}
                          onClick={onCancel}
                        />
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="modal-content-container">
                  <div
                    className={cx('modal-content', contentClassName, {
                      'is-centered': isCentered,
                    })}
                  >
                    {children}
                  </div>
                </div>
                <div className="modal-footer">
                  {showCancel && (
                    <Button onClick={onCancel}>
                      <FormattedMessage {...t.cancel} />
                    </Button>
                  )}
                  {showSubmit &&
                    (customSubmitButtons || (
                      <Button color="danger" onClick={onSubmit}>
                        {submitTitle || <FormattedMessage {...t.submit} />}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </Portal>
  );
}
