import { CSSProperties, ReactNode } from 'react';
import cx from 'classnames';

type Props = {
  isActive: boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
};

export default function TableActiveCell({
  children,
  isActive,
  onClick,
  style,
  className,
}: Props) {
  return (
    <td
      role="gridcell"
      onClick={onClick}
      className={cx(className, 'table-cell', { '-is-active': isActive })}
      style={style}
    >
      {children}
    </td>
  );
}
