/* eslint-disable local/sort-imports */

import 'core-js/stable';
import 'react-toastify/dist/ReactToastify.css';
import './styles/application.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import Root from 'views';

import { version } from '../package.json';
import loadFonts from './assets/fonts/fontLoader';

loadFonts();

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    release: version,
    environment: 'production',
  });
}

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root')
);
