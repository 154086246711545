import { ReactNode } from 'react';
import cx from 'classnames';

import injectType from 'utils/injectType';

export interface TabListProps {
  children: ReactNode;
  isSectionTitle?: boolean;
}

function TabList({ children, isSectionTitle = false }: TabListProps) {
  return (
    <ul
      className={cx('navbar tablist', { '-is-section-title': isSectionTitle })}
    >
      {children}
    </ul>
  );
}

const [TypedTabList, isTabListElement] = injectType(TabList, 'tabs.tablist');

export default TypedTabList;
export { isTabListElement };
