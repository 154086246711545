import { ReactNode } from 'react';

import injectType from 'utils/injectType';

export interface SectionSubTitleProps {
  children: ReactNode;
  actionButtons?: ReactNode;
}

function SectionSubTitle({ children, actionButtons }: SectionSubTitleProps) {
  return (
    <h5 className="section-sub-title">
      <div>{children}</div>
      {actionButtons && <div>{actionButtons}</div>}
    </h5>
  );
}

const [TypedSectionSubTitle, isSectionSubTitleElement] = injectType(
  SectionSubTitle,
  'section.sectionsubtitle'
);

export default TypedSectionSubTitle;
export { isSectionSubTitleElement };
