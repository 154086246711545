import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetPassword } from 'store/modules/entities/actions/users';

import { useStrongPasswordPolicy } from 'hooks/useConfig';
import useQueryParams from 'hooks/useQueryParams';

import Flash from 'components/Flash';
import Section from 'components/Section';

import ErrorFlash from 'sharedComponents/ErrorFlash';

import SigninContent from '../components/SigninContent';
import useActionReset from '../components/useActionReset';
import t from '../translations';
import ResetPasswordForm from './components/Form';

export default function ResetPassword() {
  const dispatch = useDispatch();
  const actionReset = useActionReset();
  const { formatMessage } = useIntl();

  const [{ token }] = useQueryParams<{ token: string }>();

  const success = useSelector((state) => state.success);

  const strongPasswordPolicy = useStrongPasswordPolicy();

  useEffect(() => {
    return actionReset;
  }, [actionReset]);

  return (
    <div className="login">
      <SigninContent title={formatMessage(t.resetPassword)}>
        <ErrorFlash
          close={false}
          substituteMessage={formatMessage(t.errorMessageFallback)}
        />

        {success.type === 'RESET_PASSWORD' ? (
          <p>{formatMessage(t.resetSuccessMessage)}</p>
        ) : (
          <>
            {strongPasswordPolicy && (
              <Section withoutPadding>
                <Flash
                  type="info"
                  content={formatMessage(t.passwordInfo)}
                  close={false}
                />
              </Section>
            )}
            <ResetPasswordForm
              onSubmit={(values) =>
                dispatch(resetPassword({ ...values, token }))
              }
            />
          </>
        )}

        <Link style={{ marginTop: '10px' }} to="/login">
          {formatMessage(t.returnLogin)}
        </Link>
      </SigninContent>
    </div>
  );
}
