interface NamedPerson {
  lastName?: null | string;
  firstName?: null | string;
}

export default function fullName<P extends NamedPerson>(
  person?: P,
  joinSeparator = ' '
): string {
  if (!person) return '';

  const { lastName, firstName } = person;

  return [lastName, firstName]
    .filter((str) => str && str.length > 0)
    .join(joinSeparator);
}
