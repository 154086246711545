import { startActivity } from 'store/modules/activity';

export const EDIT_IN_CENTER_CARE_TEAM = 'EDIT_IN_CENTER_CARE_TEAM';
export const EDIT_GENERAL_PRACTITIONER_CARE_TEAM =
  'EDIT_GENERAL_PRACTITIONER_CARE_TEAM';

export const UPDATE_CARE_TEAM_FAILURE = 'UPDATE_CARE_TEAM_FAILURE';

export const editInCenterCareTeamActivity = () =>
  startActivity(EDIT_IN_CENTER_CARE_TEAM);

export const editGeneralPractitionerCareTeamActivity = () =>
  startActivity(EDIT_GENERAL_PRACTITIONER_CARE_TEAM);
