import { Navigate, Route, Routes } from 'react-router-dom';

import { useAccountLockable } from 'hooks/useConfig';

import Login from './components/Login';
import RecoverAccount from './recoverAccount';
import ResetPassword from './resetPassword';
import AccountUnlock from './unlockAccount';

export default function LoginRoot() {
  const isAccountLockable = useAccountLockable();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="recover_account" element={<RecoverAccount />} />
      <Route path="reset_password" element={<ResetPassword />} />

      {isAccountLockable && (
        <Route path="unlock_account" element={<AccountUnlock />} />
      )}

      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}
