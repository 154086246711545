import { fromJS, Set } from 'immutable';

import {
  CREATE_AMBULATORY_MEDICATION,
  GET_AMBULATORY_MEDICATION,
  GET_AMBULATORY_MEDICATION_HISTORY,
  RESTART_AMBULATORY_MEDICATION,
  STOP_AMBULATORY_MEDICATION,
  UPDATE_AMBULATORY_MEDICATION,
} from 'store/modules/entities/actions/medication';

import { isSameDay } from 'utils/date';

import { sortInlineMedications } from './index';

export default function ambulatoryReducer(state: any, action: any) {
  switch (action.type) {
    case GET_AMBULATORY_MEDICATION.SUCCESS: {
      return setMedicationCollection(state, action);
    }

    case GET_AMBULATORY_MEDICATION_HISTORY.SUCCESS: {
      return setMedicationCollection(state, action);
    }

    case STOP_AMBULATORY_MEDICATION.SUCCESS: {
      return removeMedication(state, action);
    }

    case RESTART_AMBULATORY_MEDICATION.SUCCESS: {
      return removeMedication(state, action);
    }

    case CREATE_AMBULATORY_MEDICATION.SUCCESS: {
      return createMedication(state, action);
    }

    case UPDATE_AMBULATORY_MEDICATION.SUCCESS: {
      return updateMedication(state, action);
    }

    default: {
      return state;
    }
  }
}

const BY_ID = ['ambulatoryMedication', 'byId'];
const BY_PATIENT_ID = ['ambulatoryMedication', 'byPatientId'];

const SINGLE_RESULT = 'medications/homeMedicationPrescription';
const PLURAL_RESULT = 'medications/homeMedicationPrescriptions';

function setMedicationCollection(state: any, action: any): any {
  const {
    response: {
      entities: { ambulatoryMedication },
      result,
    },
    patientId,
  } = action.payload;

  const resultIds = result[PLURAL_RESULT];
  const resultId = result[SINGLE_RESULT];
  const ids = Set(resultIds || (resultId ? [resultId] : []));

  return state
    .setIn(BY_ID, fromJS(ambulatoryMedication || {}))
    .setIn([...BY_PATIENT_ID, patientId], ids);
}

function shouldShowInMedicationsTable(
  medication: MedicationT,
  action: { payload: { status: string } }
) {
  const { status } = action.payload;
  const now = new Date();

  switch (status) {
    case 'active':
      if (
        medication.startDate < now &&
        (!medication.endDate || medication.endDate > now)
      ) {
        return true;
      }

      if (
        medication.startDate < now &&
        medication.endDate &&
        isSameDay(medication.endDate, now)
      ) {
        return true;
      }

      if (
        medication.startDate &&
        medication.endDate &&
        Number(medication.startDate) === Number(medication.endDate)
      ) {
        return true;
      }

      return false;
    case 'future':
      return medication.startDate > now;
    case 'stopped':
      return !!medication.endDate && medication.endDate < now;
    default:
      return true;
  }
}

function createMedication(state: any, action: any): any {
  const {
    response: {
      entities: { ambulatoryMedication = {} },
      result,
    },
    patientId,
  } = action.payload;

  const medicationId = result[SINGLE_RESULT];
  const medication = ambulatoryMedication[medicationId];

  return state
    .mergeDeepIn(BY_ID, fromJS(ambulatoryMedication))
    .updateIn([...BY_PATIENT_ID, patientId], (set = Set()) =>
      shouldShowInMedicationsTable(medication, action)
        ? set.add(medicationId)
        : set
    );
}

function updateMedication(state: any, action: any): any {
  const {
    response: {
      entities: { ambulatoryMedication = {} },
      result,
    },
    patientId,
  } = action.payload;

  const medicationId = result[SINGLE_RESULT];
  const medication = ambulatoryMedication[medicationId];

  return state
    .mergeDeepIn(BY_ID, fromJS(ambulatoryMedication))
    .setIn([...BY_ID, medicationId, 'posology'], medication.posology)
    .updateIn([...BY_PATIENT_ID, patientId], (set = Set()) =>
      shouldShowInMedicationsTable(medication, action)
        ? set.add(medicationId)
        : set
    );
}

function removeMedication(state: any, action: any): any {
  const { id: medicationId, patientId } = action.payload;

  return state
    .deleteIn([...BY_ID, medicationId])
    .updateIn([...BY_PATIENT_ID, patientId], (set = Set()) =>
      set.filter((id) => id !== medicationId)
    );
}

export const ambulatoryMedicationsSelector = (
  state: any,
  patientId = 'undefined',
  fetchType: 'normal' | 'history' = 'normal'
): AmbulatoryMedicationT[] | undefined => {
  let isFetching: boolean;

  if (fetchType === 'history') {
    isFetching = !!state.network.GET_AMBULATORY_MEDICATION_HISTORY;
  } else {
    isFetching = !!state.network.GET_AMBULATORY_MEDICATION;
  }

  if (isFetching) return;

  const { ambulatoryMedication } = state.entities.toJS();

  const ambulatoryMedicationByPatientId =
    ambulatoryMedication?.byPatientId?.[patientId];

  const ambulatoryMedicationById = ambulatoryMedicationByPatientId
    ?.map((medId: string) => ambulatoryMedication.byId[medId])
    .filter(Boolean);

  const medications = ambulatoryMedicationById || [];

  return sortInlineMedications(medications);
};

export const ambulatoryMedicationPackageOptionsSelector = (
  state: any,
  medicationId: string | null | undefined
): OptionT[] => {
  if (!medicationId) return [];

  const ambulatoryMedication: AmbulatoryMedicationT | null | undefined =
    state.entities
      .getIn(['ambulatoryMedication', 'byId', medicationId])
      ?.toJS();

  if (!ambulatoryMedication?.availablePackages) return [];

  return ambulatoryMedication.availablePackages.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
};
