import { defineMessages } from 'react-intl';

export default defineMessages({
  enterFullScreen: {
    id: 'rte_enter_fullscreen',
    defaultMessage: 'Enter full screen',
  },
  exitFullScreen: {
    id: 'rte_exit_fullscreen',
    defaultMessage: 'Exit full screen',
  },
  bold: {
    id: 'rte_format_bold',
    defaultMessage: 'Bold',
  },
  italic: {
    id: 'rte_format_italic',
    defaultMessage: 'Italic',
  },
  underline: {
    id: 'rte_format_underline',
    defaultMessage: 'Underline',
  },
  bulletedList: {
    id: 'rte_format_bulleted_list',
    defaultMessage: 'Bulleted list',
  },
  numberedList: {
    id: 'rte_format_numbered_list',
    defaultMessage: 'Numbered list',
  },
  headings: {
    id: 'rte_format_headings',
    defaultMessage: 'Headings',
  },
  removeHeading: {
    id: 'rte_remove_heading',
    defaultMessage: 'Remove heading',
  },
  colors: {
    id: 'rte_format_colors',
    defaultMessage: 'Colors',
  },
  removeColor: {
    id: 'rte_remove_color',
    defaultMessage: 'Remove color',
  },
  clearFormatting: {
    id: 'rte_clear_formatting',
    defaultMessage: 'Clear formatting',
  },
  undo: {
    id: 'rte_undo',
    defaultMessage: 'Undo',
  },
  redo: {
    id: 'rte_redo',
    defaultMessage: 'Redo',
  },
});
