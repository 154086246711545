import { startActivity } from 'store/modules/activity';

import { PatientIdentifierT } from './fetchers';

export const EDIT_PATIENT_IDENTIFIER = 'EDIT_PATIENT_IDENTIFIER';

export const UPDATE_PATIENT_IDENTIFIER_FAILURE =
  'UPDATE_PATIENT_IDENTIFIER_FAILURE';

export const editPatientIdentifierActivity = (identifier: PatientIdentifierT) =>
  startActivity(EDIT_PATIENT_IDENTIFIER, { identifier });
