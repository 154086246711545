import { startActivity } from 'store/modules/activity';

import { AddressT } from './fetchers';

export const ADD_PATIENT_ADDRESS = 'ADD_PATIENT_ADDRESS';
export const EDIT_PATIENT_ADDRESS = 'EDIT_PATIENT_ADDRESS';
export const REMOVE_PATIENT_ADDRESS = 'REMOVE_PATIENT_ADDRESS';

export const CREATE_PATIENT_ADDRESS_FAILURE = 'CREATE_PATIENT_ADDRESS_FAILURE';
export const UPDATE_PATIENT_ADDRESS_FAILURE = 'UPDATE_PATIENT_ADDRESS_FAILURE';

export const addAddressActivity = () => startActivity(ADD_PATIENT_ADDRESS);

export const editAddressActivity = (address: AddressT) =>
  startActivity(EDIT_PATIENT_ADDRESS, { address });

export const removePatientAddressActivity = (patientAddress: AddressT) =>
  startActivity(REMOVE_PATIENT_ADDRESS, { patientAddress });
