import { createContext, useContext } from 'react';

export const SelectMenuPortalTargetContext = createContext<HTMLElement | null>(
  null
);

export default SelectMenuPortalTargetContext.Provider;

export function useSelectMenuPortalTargetContext() {
  return useContext(SelectMenuPortalTargetContext);
}
