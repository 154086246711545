import { CSSProperties, useLayoutEffect, useRef } from 'react';

const ANIMATION_DURATION = 200;

const styleNode =
  (node: HTMLElement) => (property: string, value: string | number) => {
    let cssValue = String(value);
    if (property === 'max-height')
      cssValue = typeof value === 'number' ? `${value}px` : value;

    node.style.setProperty(property, cssValue);
  };

export default function useFold<T extends HTMLElement>(
  show: boolean,
  maxHeight?: number
) {
  const ref = useRef<T | null>(null);
  const targetHeight = useRef<string | number>(0);
  const timeout = useRef<any>();
  const effectRan = useRef<boolean>(false);
  const style: CSSProperties = {
    transition: `max-height ${ANIMATION_DURATION}ms ease-out`,
  };

  useLayoutEffect(() => {
    const node = ref.current;
    if (!node) return;

    const css = styleNode(node);

    if (!effectRan.current) {
      show ? openInitial() : closeInitial();
      effectRan.current = true;
    } else {
      clearTimeout(timeout.current);
      show ? open(node) : close(node);
    }

    function openInitial() {
      css('overflow', 'visible');
      css('max-height', maxHeight || 'none');
    }

    function closeInitial() {
      css('max-height', 0);
      css('overflow', 'hidden');
    }

    function open(node: T) {
      requestAnimationFrame(() => {
        css('visibility', 'hidden');
        css('max-height', 'none');
        const { height } = node.getBoundingClientRect();
        targetHeight.current = Math.min(height, maxHeight || Infinity);
        css('max-height', 0);
        css('visibility', 'visible');

        requestAnimationFrame(() => {
          css('max-height', targetHeight.current);
          css('overflow', 'hidden');
        });

        timeout.current = setTimeout(() => {
          css('max-height', maxHeight || 'none');
          css('overflow', 'visible');
        }, ANIMATION_DURATION);
      });
    }

    function close(node: T) {
      css('overflow', 'hidden');

      requestAnimationFrame(() => {
        const { height } = node.getBoundingClientRect();
        targetHeight.current = Math.min(height, maxHeight || Infinity);
        css('max-height', targetHeight.current);

        requestAnimationFrame(() => {
          css('max-height', 0);
        });
      });
    }
  }, [show, maxHeight]);

  return [ref, style] as const;
}
