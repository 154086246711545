import { ReactNode, useEffect, useState } from 'react';

import Icon from 'components/Icon';

import useToggleDelay from '../hooks/useToggleDelay';
import Button from './Button';

interface Props {
  isActive: boolean;
  width: number;
  buttonIcon: string;
  buttonTitle: string;
  buttonIsDisabled?: boolean;
  children: ReactNode;
}

export default function Popout({
  isActive,
  width,
  buttonIcon,
  buttonTitle,
  buttonIsDisabled = false,
  children,
}: Props) {
  const [isMouseOver, toggleIsMouseOver] = useToggleDelay();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isMouseOver) setIsOpen(false);
  }, [isMouseOver]);

  return (
    <div
      className="rte-popout"
      onMouseEnter={() => toggleIsMouseOver(true)}
      onMouseLeave={() => toggleIsMouseOver(false)}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        isActive={isActive || isOpen}
        title={buttonTitle}
        isDisabled={buttonIsDisabled}
      >
        <Icon>{buttonIcon}</Icon>
      </Button>

      <div
        className="rte-popout-content-wrapper"
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        <div
          className="rte-popout-content"
          style={{
            width,
            opacity: isOpen ? 1 : 0,
            transform: `translateX(${isOpen ? '0' : '-4px'})`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
