import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  forbidden,
  methodNotAllowed,
  networkError,
  notFound,
  payloadTooLarge,
  serverError,
} from 'store/modules/network';

export default function storeErrorHandler(actionDispatcher: AppDispatch) {
  return ({ error, requestDescription, failureType }: ErrorHandlerArgT) => {
    if (import.meta.env.MODE === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const { response } = error;

    // The server encountered an unexpected condition that
    // prevented it from fulfilling the request.
    // https://httpstatuses.com/500
    if (response?.status >= 500) {
      actionDispatcher({ type: failureType, payload: null, error: true });
      return actionDispatcher(serverError({ failureType }));
    }

    // The server is refusing to process a request because,
    // the request payload is larger than the server is willing or able to process.
    // https://httpstatuses.com/413
    if (response?.status === 413) {
      actionDispatcher({ type: failureType, payload: null, error: true });
      return actionDispatcher(payloadTooLarge({ failureType }));
    }

    // The method received in the request-line is known by the origin server,
    // but not supported by the target resource.
    // https://httpstatuses.com/405
    if (response?.status === 405) {
      actionDispatcher({ type: failureType, payload: null, error: true });
      return actionDispatcher(methodNotAllowed({ failureType }));
    }

    // The server understood the request but did not find the resource.
    // https://httpstatuses.com/404
    if (response?.status === 404) {
      actionDispatcher({ type: failureType, payload: null, error: true });
      return actionDispatcher(notFound({ failureType }));
    }

    // The server understood the request but refuses to authorize it.
    // https://httpstatuses.com/403
    if (response?.status === 403) {
      actionDispatcher({ type: failureType, payload: null, error: true });
      return actionDispatcher(forbidden({ failureType }));
    }

    return actionDispatcher(
      networkError(failureType, requestDescription, response?.data)
    );
  };
}

export function useStoreErrorHandler() {
  const dispatch = useDispatch();
  const [handler] = useState(() => storeErrorHandler(dispatch));

  return handler;
}
