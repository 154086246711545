import { startActivity } from 'store/modules/activity';

import { InsuranceT } from './fetchers';

export const ADD_PATIENT_INSURANCE = 'ADD_PATIENT_INSURANCE';
export const EDIT_PATIENT_INSURANCE = 'EDIT_PATIENT_INSURANCE';
export const REMOVE_PATIENT_INSURANCE = 'REMOVE_PATIENT_INSURANCE';

export const CREATE_PATIENT_INSURANCE_FAILURE =
  'CREATE_PATIENT_INSURANCE_FAILURE';
export const UPDATE_PATIENT_INSURANCE_FAILURE =
  'UPDATE_PATIENT_INSURANCE_FAILURE';

export const addPatientInsuranceActivity = () =>
  startActivity(ADD_PATIENT_INSURANCE);

export const editPatientInsuranceActivity = (patientInsurance: InsuranceT) =>
  startActivity(EDIT_PATIENT_INSURANCE, { patientInsurance });

export const removePatientInsuranceActivity = (patientInsurance: InsuranceT) =>
  startActivity(REMOVE_PATIENT_INSURANCE, { patientInsurance });
