import { lazy } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import Icon from 'components/Icon';

import Sidebar from 'views/components/Sidebar';

const Dashboard = lazy(() => import('./components/Dashboard'));
const Device = lazy(() => import('./devices/[deviceId]'));
const Profile = lazy(() => import('./profile'));

export function WorkbenchSidebar() {
  const { formatMessage } = useIntl();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <Sidebar>
      <li role="presentation">
        <NavLink
          to="/workbench"
          title={formatMessage(t.dashboard)}
          className={cx}
        >
          <Icon>dashboard</Icon>
        </NavLink>
      </li>
    </Sidebar>
  );
}

export function WorkbenchRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="devices/:deviceId/*" element={<Device />} />
      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

const t = defineMessages({
  dashboard: {
    id: 'navigation_perspective_workbench_dashboard_title',
    defaultMessage: 'Dashboard',
  },
});
