const timeRegex =
  /^(0[0-9]|[0-9]|1[0-9]|2[0-3])[^\d]+(0[0-9]|[0-9]|[1-5][0-9]|100)$/;

export default function stringToTime(value: string) {
  const match = value.match(timeRegex);

  if (!match) return null;

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);

  return new Date(1970, 0, 1, hours, minutes, 0, 0);
}
