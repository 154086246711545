import Avatar from 'components/Avatar';

type User = {
  firstName?: string;
  lastName?: string;
  avatarThumbUrl: string;
  title?: string;
  fullName?: string;
};

const displayName = (user: User) =>
  [user.title, user.lastName, user.firstName].filter(Boolean).join(' ');

const UserInfo = ({
  user,
  showAvatar = true,
}: {
  user: User;
  showAvatar?: boolean;
}) => {
  const name = user.fullName || displayName(user);

  return (
    <>
      {showAvatar ? (
        <Avatar
          url={user.avatarThumbUrl}
          className="is-small with-margin-small"
        />
      ) : null}
      {name}
    </>
  );
};

export default UserInfo;
