import get from 'lodash/get';
import set from 'lodash/set';

import { isIsoDate } from 'utils/date';

import walkObject from './walkObject';

type Parser = (value: any) => any;
type ParserMap = Record<string, Parser>;

export default function deserialize<T = any>(data: T, parserMap: ParserMap): T {
  const paths = Object.keys(parserMap);
  const deserialized = paths.reduce(
    (result, path) => execute(result, path, parserMap[path]),
    data
  );

  if (import.meta.env.MODE === 'development') {
    walkObject(deserialized, (path, value) => {
      if (typeof value === 'string' && isIsoDate(value)) {
        // eslint-disable-next-line no-console
        console.error(`Encountered a date string in deserialized object.`, {
          deserialized,
          path,
        });
      }
    });
  }

  return deserialized;
}

function execute(data: any, path: string, serializer: Parser) {
  const arrayIndicatorIndex = path.indexOf('[]');

  if (arrayIndicatorIndex > -1) {
    const basePath = path.slice(0, arrayIndicatorIndex);
    const restPath = path.slice(arrayIndicatorIndex + 2);
    const array: any[] | undefined = get(data, basePath);

    if (array !== undefined) {
      if (restPath === '') {
        return set(
          data,
          basePath,
          array.map((item) => serializer(item))
        );
      }

      return set(
        data,
        basePath,
        array.map((item) => execute(item, restPath.slice(1), serializer))
      );
    }

    return data;
  }

  const value = get(data, path);
  if (value !== undefined) {
    return set(data, path, serializer(value));
  }

  return data;
}
