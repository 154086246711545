import { CSSProperties, ReactNode } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { resetError } from 'store/modules/errors';
import { resetSuccess } from 'store/modules/success';

import Icon from 'components/Icon';

interface FlashProps {
  content?: string | string[];
  children?: ReactNode;
  type?: AppColor;
  icon?: string;
  style?: CSSProperties;
  close?: boolean;
  onClose?: () => void;
}

export default function Flash(props: FlashProps) {
  const dispatch = useDispatch();

  const {
    icon,
    children,
    type = 'danger',
    content,
    style,
    close = true,
    onClose: externalOnClose,
  } = props;

  const onClose = () => {
    if (externalOnClose) {
      externalOnClose();
    } else {
      dispatch(resetError());
      dispatch(resetSuccess());
    }
  };

  if (!children && (!content || (Array.isArray(content) && content.length < 1)))
    return null;

  return (
    <div className={cx('alert', `-type-${type}`)} style={style} role="alert">
      <Icon>{icon || iconMap[type]}</Icon>
      <div className="alert__content">
        {children}
        {content ? (
          <ul>
            {(Array.isArray(content) ? content : [content]).map(
              (string, index) => (
                <li key={index}>{string}</li>
              )
            )}
          </ul>
        ) : null}
      </div>
      {close ? (
        <button type="button" className="alert__close-button" onClick={onClose}>
          <Icon>clear</Icon>
        </button>
      ) : null}
    </div>
  );
}

const iconMap: Record<AppColor, string> = {
  info: 'info',
  success: 'check_circle',
  warning: 'warning',
  danger: 'error',
};
