import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { AuthCredentials } from 'auth/types';

import {
  useAccountLockable,
  useAuthenticationStrategy,
  useStagingRibbon,
} from 'hooks/useConfig';

import { useAuth } from 'providers';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

import t from '../translations';
import LoginForm from './LoginForm';
import SigninContent from './SigninContent';

export default function Login() {
  const { formatMessage } = useIntl();
  const { login } = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const onLogin = useCallback(
    (credentials: AuthCredentials) => {
      setIsLoading(true);
      login(credentials)
        .then(() => navigate(from, { replace: true }))
        .catch((error) => {
          setError(error?.message || 'Error');
          setIsLoading(false);
        });
    },
    [login, from, navigate]
  );

  const authenticationStrategy = useAuthenticationStrategy();
  const isAccountLockable = useAccountLockable();
  const isStaging = useStagingRibbon();

  useEffect(() => {
    if (authenticationStrategy === 'krb') {
      onLogin(null);
    }
  }, [authenticationStrategy, onLogin]);

  if (authenticationStrategy === 'krb' && error) {
    return <Navigate to="/forbidden" />;
  }

  if (authenticationStrategy === 'krb' || isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const microsoftStrategy = (
    <div className="flex flex-column items-center justify-center">
      <Button color="info" onClick={() => onLogin(null)}>
        {formatMessage(t.loginMicrosoft)}
      </Button>
    </div>
  );

  const credentialsStrategy = (
    <>
      <LoginForm onSubmit={onLogin} />

      <Link to="/login/recover_account">
        {formatMessage(isAccountLockable ? t.recoverAccount : t.forgotPassword)}
      </Link>
    </>
  );

  const strategy =
    authenticationStrategy === 'oidc' ? microsoftStrategy : credentialsStrategy;

  return (
    <div className="login">
      {isStaging && (
        <div className="login__staging-banner" role="alert">
          {formatMessage(t.acceptationEnvironmentDisclaimer)}
        </div>
      )}

      <SigninContent title={formatMessage(t.signIn)}>
        {error && (
          <div className="alert -type-danger" role="alert">
            <Icon>error</Icon>
            {error === 'Unauthorized' ? (
              <FormattedMessage {...t.errorMessageLoginFallback} />
            ) : (
              error
            )}
          </div>
        )}

        {strategy}
      </SigninContent>
    </div>
  );
}
