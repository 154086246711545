import { ForwardedRef, forwardRef } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

type CustomProps = {
  icon: string;
  title: string;
  disabled?: boolean;
  color?: AppColor;
  size?: 'mini';
  remove?: boolean;
  spinning?: boolean;
  forwardedRef: ForwardedRef<HTMLButtonElement>;
};

type HtmlProps = Omit<JSX.IntrinsicElements['button'], keyof CustomProps>;

type Props = CustomProps & HtmlProps;

function ActionButtonImplementation({
  forwardedRef,

  icon,
  title,
  disabled = false,
  color,
  size,
  remove,
  spinning,

  className,
  ...buttonProps
}: Props) {
  return (
    <button
      ref={forwardedRef}
      {...buttonProps}
      title={title}
      type="button"
      data-testid="action-button"
      className={cx(
        'action',
        `-color-${color || 'none'}`,
        `-size-${size || 'normal'}`,
        {
          '-is-remove': remove,
          '-is-spinning': spinning,
        },
        className
      )}
      disabled={disabled}
    >
      <Icon>{icon}</Icon>
    </button>
  );
}

const ActionButton = forwardRef<HTMLButtonElement, Omit<Props, 'forwardedRef'>>(
  (props, ref) => <ActionButtonImplementation {...props} forwardedRef={ref} />
);
ActionButton.displayName = 'ActionButton';

export default ActionButton;
