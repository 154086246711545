import { useIntl } from 'react-intl';

import Button from 'components/Button';

import t from '../translations';

interface LoginFormProps {
  onSubmit: (arg0: { username: string; password: string }) => void;
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  const { formatMessage } = useIntl();

  function handleSubmit(event) {
    event.preventDefault();

    const { username, password } = event.target.elements;

    onSubmit({ username: username.value, password: password.value });
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="username" className="sr-only">
        {formatMessage(t.username)}
      </label>

      <input
        autoFocus
        type="text"
        id="username"
        name="username"
        placeholder={formatMessage(t.username)}
      />

      <label htmlFor="password" className="sr-only">
        {formatMessage(t.password)}
      </label>

      <input
        type="password"
        id="password"
        name="password"
        placeholder={formatMessage(t.password)}
      />

      <Button color="info" block type="submit">
        {formatMessage(t.loginSubmit)}
      </Button>
    </form>
  );
}
