export default function ClearIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg {...props} width="10" height="10" viewBox="0 0 10 10">
      <line
        x1="1"
        y1="1"
        x2="9"
        y2="9"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        x1="9"
        y1="1"
        x2="1"
        y2="9"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
