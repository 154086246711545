import { AxiosRequestConfig } from 'axios';
import { DefaultRootState } from 'react-redux';
import { Middleware } from 'redux';

import { networkResponse } from 'store/modules/network';

import { http } from './client';
import storeErrorHandler from './storeErrorHandler';

export function getConfig(
  requestDescription: ApiRequestDescription
): AxiosRequestConfig {
  const { url, method, params, payload: data } = requestDescription;

  return { url, method, params, data };
}

/**
 * Middleware that handles NetworkActionT actions.
 *
 * Calling `next` will pass the action to the next middleware.
 * In our case, the API middleware is the last one so it will be passed to redux.
 * That means the action NEEDS to be a plain object. It cannot be a thunk or anything else.
 * The `next` call will also return the action that it's passed so we can return a `next` call
 * and it will be the action that is passed to `next`.
 *
 * Calling `dispatch` will make sure that the actions passes through ALL middlwwares.
 * This means that we can dispatch thunks and `NetworkActionT` objects.
 *
 * Note that we return an ASYNC function.
 * This implicitely returns a promise.
 * Everything we return will be the resolved value.
 *
 * That means that we can await the `dispatch` call if we dispatch a `NetworkActionT`.
 * The `dispatch` call will resolve after the API call is resolved and will receive
 * either `ApiSuccessResponseT` or `ApiErrorResponseT`.
 */
export default function createApiMiddleware(): Middleware<
  Record<string, any>,
  DefaultRootState,
  AppDispatch
> {
  return () => (next) => (action) => {
    if (action.type !== 'CALL_API') {
      return next(action);
    }

    return (async () => {
      const requestDescription = action.payload as ApiRequestDescription;
      const {
        types: {
          REQUEST: requestType,
          SUCCESS: successType,
          FAILURE: failureType,
        },
      } = requestDescription;

      const requestConfig = getConfig(requestDescription);

      next({ type: requestType });

      try {
        const response = await http(requestConfig);

        // The server has successfully fulfilled the request and
        // there is no additional content to send in the response payload body.
        // https://httpstatuses.com/204
        const body = response.data || null;

        return next(networkResponse(successType, requestDescription, body));
      } catch (error: any) {
        const errorAction = storeErrorHandler(next)({
          error,
          requestDescription,
          failureType,
        });

        return errorAction;
      }
    })();
  };
}
