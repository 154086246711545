import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ErrorPage from '../error';
import ForbiddenPage from '../forbidden';
import Login from '../login';
import NotFoundPage from '../notFound';

export default function UnauthenticatedApp() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login/*" element={<Login />} />
      <Route path="error" element={<ErrorPage />} />
      <Route path="forbidden" element={<ForbiddenPage />} />
      <Route path="not_found" element={<NotFoundPage />} />
      <Route
        path="*"
        element={<Navigate to="/login" state={{ from: location }} />}
      />
    </Routes>
  );
}
