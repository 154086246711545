import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { unlockAccount } from 'store/modules/entities/actions/users';

import useQueryParams from 'hooks/useQueryParams';

import ErrorFlash from 'sharedComponents/ErrorFlash';

import SigninContent from '../components/SigninContent';
import useActionReset from '../components/useActionReset';
import t from '../translations';

export default function AccountUnlock() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [{ token }] = useQueryParams<{ token: string }>();

  const actionReset = useActionReset();

  useEffect(() => {
    dispatch(unlockAccount({ token }));

    return actionReset;
  }, [actionReset, dispatch, token]);

  const success = useSelector((state) => state.success);

  return (
    <div className="login">
      <SigninContent title={formatMessage(t.accountUnlock)}>
        <ErrorFlash
          close={false}
          substituteMessage={formatMessage(t.errorMessageFallback)}
        />

        {success.type === 'UNLOCK_ACCOUNT' && (
          <p>{formatMessage(t.resetSuccessMessage)}</p>
        )}

        <Link style={{ marginTop: '10px' }} to="/login">
          {formatMessage(t.returnLogin)}
        </Link>
      </SigninContent>
    </div>
  );
}
