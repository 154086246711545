import { MouseEvent } from 'react';
import cx from 'classnames';
import { useSlate } from 'slate-react';

import Button from './Button';

const SIZE = 14;

interface Props {
  color: string;
}

export default function ColorButton({ color }: Props) {
  const editor = useSlate();

  return (
    <Button
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        editor.toggleColor(color);
      }}
    >
      <svg
        className={cx('rte-color', {
          'is-active': editor.isColorActive(color),
        })}
        width={SIZE}
        height={SIZE}
      >
        <rect
          className="rte-color-back"
          width={SIZE}
          height={SIZE}
          rx={3}
          fill={color}
        />
        <rect
          className="rte-color-center"
          width={5}
          height={5}
          x={4.5}
          y={4.5}
          rx={1}
          fill="white"
        />
      </svg>
    </Button>
  );
}
