import {
  Dispatch,
  MutableRefObject,
  ChangeEvent as ReactChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import range from 'lodash/range';
import { DateUtils, DayModifiers, Modifiers } from 'react-day-picker';
import { defineMessages, useIntl } from 'react-intl';
import {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  MenuProps,
  SelectComponentsConfig,
  Props as SelectProps,
  SingleValueProps,
} from 'react-select';
import Creatable from 'react-select/creatable';

import sortInline from 'utils/sort';
import stringToDate from 'utils/stringToDate';
import stringToTime from 'utils/stringToTime';

import useSyncedRef from 'hooks/useSyncedRef';

import ActionButton from 'components/ActionButton';
import DatePicker, {
  datePickerClassNames,
  getClassName,
  useLocaleUtils,
} from 'components/DatePicker';
import Icon from 'components/Icon';
import { useSelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import { useBlurDetection } from './shared';
import { ClearIndicator } from './shared/reactSelect';
import { BaseInputProps } from './types';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 120, 0, 1, 0, 0);
const toMonth = new Date(currentYear + 100, 11, 31, 23, 59);

const selectComponents: SelectComponentsConfig<
  Option,
  boolean,
  GroupBase<Option>
> = {
  SingleValue,
  Menu,
  MenuList,
  ClearIndicator,
  DropdownIndicator,
};

const dateUis: UI[] = ['date', 'datetime', 'multidate'];
const timeUis: UI[] = ['time', 'datetime'];

export type UI = 'date' | 'time' | 'datetime' | 'multidate';
type Value<T extends UI> = T extends 'multidate' ? Date[] : Date;
type Option = { value: Date; __isNew__?: true };

export interface DateTimeInputProps<T extends UI = 'date'>
  extends BaseInputProps<Value<T>> {
  ui: T;
  clearable?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableToday?: boolean;
  disableDate?: (day: Date) => boolean;
  onMonthChange?: (date: Date) => void;
}

interface CustomSelectProps {
  onMenuChildFocus: () => void;
  menuRef: MutableRefObject<HTMLDivElement | null>;
  ui: UI;
  selected: Option[];
  clearable: boolean | undefined;
  modifiers: Partial<Modifiers>;
}

export default function DateTimeInput<T extends UI = 'date'>({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  name,
  ui,
  clearable = true,
  disabled = false,
  autoFocus = false,
  disablePast = false,
  disableFuture = false,
  disableToday = false,
  disableDate,
  onMonthChange,
}: DateTimeInputProps<T>) {
  const isControlled = !!externalOnChange;

  const { formatMessage } = useIntl();
  const menuPortalTarget = useSelectMenuPortalTargetContext();

  const { selected, handleChange } = useManagedSelect<T>({
    value: isControlled ? value : defaultValue,
    onChange: externalOnChange,
    isControlled,
  });

  const modifiers = useModifiers(
    disablePast,
    disableFuture,
    disableToday,
    disableDate
  );

  const [isFocused, setIsFocused] = useState<true | undefined>(undefined);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useBlurDetection(menuRef, () => {
    setIsFocused(undefined);
  });

  const onChange = (value: Option | readonly Option[] | null) => {
    setIsFocused(undefined);
    handleChange(value);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const onMenuChildFocus = () => {
    setIsFocused(true);
  };

  const onCreateOption = (value: string) => {
    const date = ui === 'time' ? stringToTime(value) : stringToDate(value);

    if (!date) {
      return;
    }

    if (isDateDisabled(modifiers, date)) {
      return;
    }

    const option: Option = { value: date };

    if (ui === 'multidate') {
      const dateIndex = selected.findIndex(({ value }) =>
        DateUtils.isSameDay(value, date)
      );

      if (dateIndex > -1) {
        handleChange([
          ...selected.slice(0, dateIndex),
          ...selected.slice(dateIndex + 1),
        ]);
      } else {
        handleChange([...selected, option]);
      }
    } else {
      handleChange(option);
    }
  };

  const placeholder = (() => {
    if (ui === 'date') return t.selectDate;
    if (ui === 'time') return t.selectTime;
    if (ui === 'datetime') return t.selectDateTime;
    if (ui === 'multidate') return t.selectMultipleDates;
  })();

  const input = (
    <Creatable<Option>
      inputId={name}
      name={name}
      value={selected}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={disabled}
      autoFocus={autoFocus}
      components={selectComponents}
      className="react-select select"
      classNamePrefix="react-select select"
      menuPortalTarget={menuPortalTarget}
      isFocused={isFocused}
      menuIsOpen={isFocused}
      menuPlacement="auto"
      openMenuOnFocus
      minMenuHeight={ui === 'datetime' ? 280 : 180}
      maxMenuHeight={280}
      isClearable={clearable ?? ui === 'multidate'}
      onCreateOption={onCreateOption}
      placeholder={placeholder ? formatMessage(placeholder) : undefined}
      // custom
      onMenuChildFocus={onMenuChildFocus}
      menuRef={menuRef}
      ui={ui}
      selected={selected}
      clearable={clearable}
      modifiers={modifiers}
      onMonthChange={onMonthChange}
    />
  );

  return <div className="base-input -type-datetime">{input}</div>;
}

function Menu(props: MenuProps<Option>) {
  const { ui } = props.selectProps as SelectProps<Option> & CustomSelectProps;

  return (
    <components.Menu
      {...props}
      className={cx('select__menu--is-datetime', props.className, {
        'select__menu--is-datetime-only-time':
          timeUis.includes(ui) && !dateUis.includes(ui),
        'select__menu--is-datetime-only-date':
          dateUis.includes(ui) && !timeUis.includes(ui),
      })}
    >
      {props.children}
    </components.Menu>
  );
}

function MenuList(props: MenuListProps<Option>) {
  const { ui, menuRef } = props.selectProps as SelectProps<Option> &
    CustomSelectProps;

  return (
    <components.MenuList {...props} innerRef={(el) => (menuRef.current = el)}>
      {dateUis.includes(ui) ? <DateUI {...props} /> : null}
      {timeUis.includes(ui) ? <TimeUI {...props} /> : null}
    </components.MenuList>
  );
}

function DateUI(props: MenuListProps<Option>) {
  const { setValue, clearValue } = props;
  const {
    onMenuChildFocus,
    selected,
    ui,
    clearable,
    modifiers,
    onMonthChange,
  } = props.selectProps as SelectProps<Option> & CustomSelectProps;

  const selectedDate = selected[0]?.value as Date | undefined;

  const [shownMonth, setShownMonth] = useState(
    () => selectedDate || new Date()
  );

  const onMonthChangeRef = useSyncedRef(onMonthChange);

  useEffect(() => {
    onMonthChangeRef.current?.(shownMonth);
  }, [shownMonth, onMonthChangeRef]);

  const localeUtils = useLocaleUtils();

  const monthOptions = localeUtils.getMonths();
  const yearOptions = range(fromMonth.getFullYear(), toMonth.getFullYear());

  const onDayClick = (day: Date, dayModifiers: DayModifiers) => {
    if (isDateDisabled(modifiers, day)) {
      return;
    }

    if (ui === 'multidate') {
      const isSelected = datePickerClassNames.selected in dayModifiers;

      if (isSelected) {
        const newSelection = selected
          .filter(({ value }) => !DateUtils.isSameDay(value, day))
          .map((option) => ({ ...option, value: option.value }));

        if (newSelection.length > 0) {
          setValue(newSelection, 'select-option', undefined as any);
        } else {
          clearValue();
        }
      } else {
        const newSelection = [...selected, { value: day }];
        setValue(newSelection, 'select-option', undefined as any);
      }

      onMenuChildFocus();
    } else {
      if (clearable && selectedDate && DateUtils.isSameDay(selectedDate, day)) {
        clearValue();
      } else {
        const newDate = new Date(day);
        if (selectedDate) {
          newDate.setHours(
            selectedDate.getHours(),
            selectedDate.getMinutes(),
            0,
            0
          );
        }

        setValue({ value: newDate }, 'select-option', undefined as any);

        if (ui === 'datetime') {
          onMenuChildFocus();
        }
      }
    }
  };

  return (
    <DatePicker
      onDayClick={onDayClick}
      month={shownMonth}
      selectedDays={selected.map(({ value }) => value)}
      modifiers={modifiers}
      captionElement={
        <DayPickerCaption
          shownMonth={shownMonth}
          setShownMonth={setShownMonth}
          monthOptions={monthOptions}
          yearOptions={yearOptions}
          onMenuChildFocus={onMenuChildFocus}
        />
      }
    />
  );
}

function TimeUI(props: MenuListProps<Option>) {
  const { formatMessage } = useIntl();

  const { setValue } = props;
  const { selected, onMenuChildFocus } =
    props.selectProps as CustomSelectProps & SelectProps<Option, false>;

  const selectedTime = selected[0]?.value as Date | undefined;

  const hourOptions = Array.from({ length: 24 }).map((_, i) => i);
  const minuteOptions = Array.from({ length: 12 }).map((_, i) => i * 5);

  const hourRef = useRef<HTMLUListElement>(null);
  const minuteRef = useRef<HTMLUListElement>(null);

  const selectedHour = selectedTime?.getHours();
  const selectedMinute = selectedTime?.getMinutes();

  const shouldAnimateScroll = useRef(false);

  const scrollTo = (ref: RefObject<HTMLUListElement>, nthItem: number) => {
    ref.current?.scrollTo({
      top: 26 * nthItem,
      behavior: shouldAnimateScroll.current ? 'smooth' : 'auto',
    });
  };

  useEffect(() => {
    if (selectedHour !== undefined) scrollTo(hourRef, selectedHour);
  }, [selectedHour]);

  useEffect(() => {
    if (selectedMinute !== undefined)
      scrollTo(minuteRef, (Math.floor(selectedMinute / 5) * 5) / 5);
  }, [selectedMinute]);

  const onHourChange = (hours: number) => {
    const newDate = selectedTime ? new Date(selectedTime) : new Date();
    if (!selectedTime) newDate.setHours(0, 0, 0, 0);

    newDate.setHours(hours);

    shouldAnimateScroll.current = true;
    setValue({ value: newDate }, 'select-option', undefined as any);
    onMenuChildFocus();
  };

  const onMinuteChange = (minutes: number) => {
    const newDate = selectedTime ? new Date(selectedTime) : new Date();
    if (!selectedTime) newDate.setHours(0, 0, 0, 0);

    newDate.setMinutes(minutes);

    shouldAnimateScroll.current = true;

    setValue({ value: newDate }, 'select-option', undefined as any);
    onMenuChildFocus();
  };

  const onCurrentClick = () => {
    const now = new Date();
    const newDate = selectedTime ? new Date(selectedTime) : new Date();

    newDate.setHours(now.getHours(), now.getMinutes(), 0, 0);

    setValue({ value: newDate }, 'select-option', undefined as any);
    onMenuChildFocus();
  };

  return (
    <div className="timepicker">
      <div className="timepicker__caption">
        <ActionButton
          onClick={onCurrentClick}
          icon="timer"
          title={formatMessage(t.currentTime)}
        />
      </div>

      <div className="timepicker__header">
        <div className="timepicker__header__label">
          <abbr title={formatMessage(t.hours)}>{formatMessage(t.hh)}</abbr>
        </div>
        <div className="timepicker__header__label">
          <abbr title={formatMessage(t.minutes)}>{formatMessage(t.mm)}</abbr>
        </div>
      </div>

      <div className="timepicker__columns">
        <ul className="timepicker__list" ref={hourRef}>
          {hourOptions.map((hour) => (
            <li
              key={hour}
              value={hour}
              className={cx('timepicker__list-item', {
                '-is-selected': selectedTime?.getHours() === hour,
              })}
              onClick={() => onHourChange(hour)}
              data-value={hour}
            >
              {String(hour).padStart(2, '0')}
            </li>
          ))}
          <li className="timepicker__list-item" />
        </ul>

        <ul className="timepicker__list" ref={minuteRef}>
          {minuteOptions.map((minute) => (
            <li
              key={minute}
              value={minute}
              className={cx('timepicker__list-item', {
                '-is-selected': selectedTime?.getMinutes() === minute,
              })}
              onClick={() => onMinuteChange(minute)}
              data-value={minute}
            >
              {String(minute).padStart(2, '0')}
            </li>
          ))}
          <li className="timepicker__list-item" />
        </ul>
      </div>
    </div>
  );
}

function SingleValue(props: SingleValueProps<Option>) {
  const { formatDate, formatTime } = useIntl();

  const { ui, selected } = props.selectProps as SelectProps<Option> &
    CustomSelectProps;

  const displayValue = selected
    ? selected
        .map(({ value }) => {
          const result: string[] = [];

          if (dateUis.includes(ui)) result.push(formatDate(value));
          if (timeUis.includes(ui)) result.push(formatTime(value));

          return result.join(' ');
        })
        .join(', ')
    : '';

  return (
    <components.SingleValue {...props}>{displayValue}</components.SingleValue>
  );
}

function DropdownIndicator(props: DropdownIndicatorProps<any>) {
  const { ui } = props.selectProps as SelectProps<Option> & CustomSelectProps;

  return (
    <components.DropdownIndicator
      {...props}
      className={cx('select__dropdown-indicator--is-datetime', props.className)}
    >
      <Icon>{dateUis.includes(ui) ? 'event' : 'schedule'}</Icon>
    </components.DropdownIndicator>
  );
}

interface DayPickerCaptionProps {
  shownMonth: Date;
  setShownMonth: Dispatch<SetStateAction<Date>>;
  monthOptions: string[];
  yearOptions: number[];
  onMenuChildFocus: () => void;
}

function DayPickerCaption({
  shownMonth,
  setShownMonth,
  monthOptions,
  yearOptions,
  onMenuChildFocus,
}: DayPickerCaptionProps) {
  const { formatMessage } = useIntl();

  const preventMenuFromClosing = (
    event: ReactMouseEvent | ReactKeyboardEvent
  ) => {
    event.stopPropagation();
    (event.target as HTMLSelectElement).focus();
  };

  const onMonthChange = (event: ReactChangeEvent<HTMLSelectElement>) => {
    const month = parseInt(event.target.value, 10);
    const newMonth = new Date(shownMonth);
    newMonth.setMonth(month);
    setShownMonth(newMonth);
  };

  const onYearChange = (event: ReactChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(event.target.value, 10);
    const newMonth = new Date(shownMonth);
    newMonth.setFullYear(year);
    setShownMonth(newMonth);
  };

  const onClickPrev = () => {
    const newMonth = new Date(shownMonth);
    newMonth.setMonth(newMonth.getMonth() - 1);
    setShownMonth(newMonth);
  };

  const onCurrentClick = () => {
    setShownMonth(new Date());
  };

  const onClickNext = () => {
    const newMonth = new Date(shownMonth);
    newMonth.setMonth(newMonth.getMonth() + 1);
    setShownMonth(newMonth);
  };

  return (
    <div className={getClassName('caption')}>
      <div className={getClassName('caption__inner')}>
        <select
          className={getClassName('caption__select')}
          onFocus={onMenuChildFocus}
          onMouseDown={preventMenuFromClosing}
          onKeyDown={preventMenuFromClosing}
          onChange={onMonthChange}
          value={shownMonth.getMonth()}
        >
          {monthOptions.map((month, index) => (
            <option key={month} value={index}>
              {month}
            </option>
          ))}
        </select>

        <select
          className={getClassName('caption__select')}
          onFocus={onMenuChildFocus}
          onMouseDown={preventMenuFromClosing}
          onKeyDown={preventMenuFromClosing}
          onChange={onYearChange}
          value={shownMonth.getFullYear()}
        >
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        <div className={getClassName('caption__spacer')} />

        <div className={getClassName('caption__actions')}>
          <ActionButton
            icon="chevron_left"
            title={formatMessage(t.previousMonth)}
            onClick={onClickPrev}
          />
          <ActionButton
            icon="event"
            title={formatMessage(t.currentMonth)}
            onClick={onCurrentClick}
          />
          <ActionButton
            icon="chevron_right"
            title={formatMessage(t.nextMonth)}
            onClick={onClickNext}
          />
        </div>
      </div>
    </div>
  );
}

function useManagedSelect<T extends UI = 'date'>({
  value,
  onChange,
  isControlled,
}: Pick<DateTimeInputProps<T>, 'value' | 'onChange'> & {
  isControlled: boolean;
}) {
  const getSelected = useCallback((val: typeof value): Option[] => {
    if (Array.isArray(val)) {
      return val.map((val) => ({ value: val }));
    }

    if (val) {
      return [{ value: val }];
    }

    return [];
  }, []);

  const [selected, setSelected] = useState<Option[]>(getSelected(value));
  const onChangeRef = useSyncedRef(onChange);

  const handleChange = useCallback(
    (selection: Option | readonly Option[] | null) => {
      let sel: Option[] = [];
      let val: Value<T> | null = null;

      if (Array.isArray(selection)) {
        sel = selection.sort(sortInline('value'));
        val = sel.map(({ value }) => value) as Value<T>;
      } else if (selection && 'value' in selection) {
        sel = [{ ...selection, value: selection.value }];
        val = selection.value as Value<T>;
      }

      setSelected(sel);

      onChangeRef.current?.(val);
    },
    [onChangeRef]
  );

  useEffect(() => {
    if (isControlled) setSelected(getSelected(value));
  }, [value, isControlled, getSelected]);

  return { selected, handleChange };
}

function useModifiers(
  disablePast: boolean,
  disableFuture: boolean,
  disableToday: boolean,
  disableDate: ((day: Date) => boolean) | undefined
): Partial<Modifiers> {
  const disabled: ((day: Date) => boolean)[] = [];

  if (disablePast) {
    disabled.push((day) => DateUtils.isPastDay(day));
  }

  if (disableFuture) {
    disabled.push(
      (day) =>
        !DateUtils.isSameDay(new Date(), day) && !DateUtils.isPastDay(day)
    );
  }

  if (disableToday) {
    disabled.push((day) => DateUtils.isSameDay(new Date(), day));
  }

  if (disableDate) {
    disabled.push(disableDate);
  }

  return {
    [datePickerClassNames.disabled]: (day: Date) =>
      disabled.some((fn) => fn(day)),
  };
}

function isDateDisabled(modifiers: Partial<Modifiers>, day: Date) {
  const disabledModifier = modifiers[datePickerClassNames.disabled];

  if (disabledModifier && typeof disabledModifier === 'function') {
    return disabledModifier(day);
  }

  return false;
}

const t = defineMessages({
  selectDate: {
    id: 'datetime_input_select_date',
    defaultMessage: 'Select date...',
  },
  selectTime: {
    id: 'datetime_input_select_time',
    defaultMessage: 'Select time...',
  },
  selectDateTime: {
    id: 'datetime_input_select_date_time',
    defaultMessage: 'Select date and time...',
  },
  selectMultipleDates: {
    id: 'datetime_input_select_multiple_dates',
    defaultMessage: 'Select date(s)...',
  },
  previousMonth: {
    id: 'datetime_input_previous_month',
    defaultMessage: 'Previous month',
  },
  currentMonth: {
    id: 'datetime_input_current_month',
    defaultMessage: 'Current month',
  },
  nextMonth: {
    id: 'datetime_input_next_month',
    defaultMessage: 'Next month',
  },
  hh: {
    id: 'datetime_input_hh',
    defaultMessage: 'HH',
  },
  mm: {
    id: 'datetime_input_mm',
    defaultMessage: 'MM',
  },
  hours: {
    id: 'datetime_input_hours',
    defaultMessage: 'Hours',
  },
  minutes: {
    id: 'datetime_input_minutes',
    defaultMessage: 'Minutes',
  },
  currentTime: {
    id: 'datetime_input_current_time',
    defaultMessage: 'Current time',
  },
});
