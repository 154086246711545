import { IntlShape } from 'react-intl';

import fullName from 'utils/fullname';
import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

import { RoomT, SessionPeriodT, ShiftT, UserOptionT } from './types';

export function selectRooms(
  allRooms?: Record<string, RoomT[]>,
  centerId?: string | null
) {
  if (!allRooms || !centerId || !allRooms[centerId]) return [];

  const rooms = allRooms[centerId];

  return rooms.sort(sortInline('name', 'asc'));
}

export function selectRoomOptions(
  allRooms?: Record<string, RoomT[]>,
  centerId?: string | null
) {
  return selectRooms(allRooms, centerId).map(labelize());
}

export function selectPositionOptions(
  allRooms?: RoomT[],
  roomId?: string | null
) {
  if (!allRooms || !roomId) return [];

  const selectedRoom = allRooms.find(({ id }) => id === roomId);

  if (!selectedRoom) return [];

  const positions = selectedRoom.positions || [];

  return positions
    .filter((pos) => pos.used === true)
    .map(({ id, number }) => ({
      label: String(number),
      value: id,
    })) as OptionT[];
}

export function selectShifts(
  allShifts?: Record<string, ShiftT[]>,
  centerId?: string | null
) {
  if (!allShifts || !centerId || !allShifts[centerId]) return [];

  const shifts = allShifts[centerId];

  const shiftTypes = { morning: 0, afternoon: 1, evening: 2, night: 3 };

  return shifts.sort((a, b) => shiftTypes[a.type] - shiftTypes[b.type]);
}

export function selectShiftOptions(
  allShifts?: Record<string, ShiftT[]>,
  centerId?: string | null
) {
  return selectShifts(allShifts, centerId).map(labelize());
}

export function selectUserOptions(
  users?: UserT[],
  filter?: Record<string, string>
): UserOptionT[] {
  if (!users) return [];

  const filteredUsers = filter
    ? Object.keys(filter).reduce((acc, key) => {
        return acc.filter((u) => u[key] === filter[key]);
      }, users)
    : users;

  return filteredUsers
    .map((u) => ({
      value: u.id,
      label: fullName({ ...u }),
      centerIds: u.centerIds,
      avatarThumbUrl: u.avatarThumbUrl,
      title: u.title,
      profession: u.profession,
    }))
    .sort(sortInline('label'));
}

export function selectSessionDateOptions(
  sessionPeriods: SessionPeriodT[],
  formatDate: IntlShape['formatDate']
) {
  return sessionPeriods.map(({ date, id, period }) => {
    const formattedDate = formatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    return {
      label: period ? `${formattedDate} (${period})` : formattedDate,
      value: id,
    };
  });
}

export function selectPatientOptions(patients: PatientT[]) {
  return patients.map<PatientOptionT>((patient) => ({
    label: fullName(patient),
    value: patient.id,
    avatarUrl: patient.avatarUrl,
    avatarThumbUrl: patient.avatarThumbUrl,
    birthdate: patient.birthdate,
    sex: patient.sex,
  }));
}
