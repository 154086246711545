import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

import networkResponseHandler from 'utils/networkResponseHandler';

const ambulatoryPrescriptionItemSchema = new schema.Entity(
  'ambulatoryPrescriptionItems'
);

const ambulatoryPrescriptionSchema = new schema.Entity(
  'ambulatoryPrescriptions',
  {
    homeMedicationOrderItems: [ambulatoryPrescriptionItemSchema],
  },
  {
    processStrategy: (prescription) => ({
      ...prescription,
      ...schemaDeserializers({
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })(prescription),
      prescriber: prescription.prescriber
        ? {
            ...prescription.prescriber,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(prescription.prescriber),
          }
        : null,
    }),
  }
);

export const GET_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'GET_AMBULATORY_PRESCRIPTION'
);

export const getAmbulatoryPrescription = (
  patientId: string,
  prescriptionId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders/${prescriptionId}`,
    method: 'GET',
    normalizeSchema: {
      'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
    },
    actionPayload: { patientId },
  },
});

export const GET_AMBULATORY_PRESCRIPTIONS = networkActionTypes(
  'GET_AMBULATORY_PRESCRIPTIONS'
);

export const getAmbulatoryPrescriptions = (
  patientId: string,
  page: number
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_PRESCRIPTIONS,
    url: `patients/${patientId}/home_medication_orders`,
    method: 'GET',
    params: { page, pageSize: 10 },
    actionPayload: { patientId },
    normalizeSchema: {
      data: [ambulatoryPrescriptionSchema],
    },
  },
});

export const CREATE_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'CREATE_AMBULATORY_PRESCRIPTION'
);

export const createAmbulatoryPrescription = (
  patientId: string,
  items: AmbulatoryPrescriptionItemBaseT[],
  register = false
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders`,
    method: 'POST',
    payload: {
      'medications/homeMedicationOrder': {
        items: items,
        register,
      },
    },
    normalizeSchema: {
      'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
    },
    actionPayload: { patientId },
  },
});

export const UPDATE_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'UPDATE_AMBULATORY_PRESCRIPTION'
);

export const updateAmbulatoryPrescription = (
  patientId: string,
  prescriptionId: string,
  items: any[]
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders/${prescriptionId}`,
    method: 'PUT',
    payload: {
      'medications/homeMedicationOrder': {
        items: items,
      },
    },
    normalizeSchema: {
      'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
    },
    actionPayload: { patientId },
  },
});

export const CANCEL_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'CANCEL_AMBULATORY_PRESCRIPTION'
);

export const cancelAmbulatoryPrescription = ({
  patientId,
  prescriptionId,
  reason,
}: {
  patientId: string;
  prescriptionId: string;
  reason: string | null;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CANCEL_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders/${prescriptionId}/revoke`,
    method: 'POST',
    payload: {
      'medications/homeMedicationOrder': { reason },
    },
    normalizeSchema: {
      'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
    },
    actionPayload: { patientId },
  },
});

export const DELETE_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'DELETE_AMBULATORY_PRESCRIPTION'
);

export type DeleteAmbulatoryPrescriptionArgsT = {
  patientId: string;
  prescriptionId: string;
};

export const deleteAmbulatoryPrescription = ({
  patientId,
  prescriptionId,
}: DeleteAmbulatoryPrescriptionArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders/${prescriptionId}`,
    method: 'DELETE',
    payload: {
      'medications/homeMedicationOrder': { reason: null },
    },
    actionPayload: { patientId, prescriptionId },
  },
});

export const COPY_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'COPY_AMBULATORY_PRESCRIPTION'
);

export const copyAmbulatoryPrescription = (
  patientId: string,
  prescriptionId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: COPY_AMBULATORY_PRESCRIPTION,
    url: `patients/${patientId}/home_medication_orders/${prescriptionId}/copy`,
    method: 'POST',
    normalizeSchema: {
      'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
    },
    actionPayload: { patientId },
  },
});

export const REGISTER_AMBULATORY_PRESCRIPTION = networkActionTypes(
  'REGISTER_AMBULATORY_PRESCRIPTION'
);

export const registerAmbulatoryPrescription =
  (
    patientId: string,
    prescriptionId: string,
    items: any[]
  ): ThunkNetworkActionT =>
  (dispatch) =>
    networkResponseHandler(
      dispatch(updateAmbulatoryPrescription(patientId, prescriptionId, items))
    )
      .then(() =>
        dispatch({
          type: 'CALL_API',
          payload: {
            types: REGISTER_AMBULATORY_PRESCRIPTION,
            url: `patients/${patientId}/home_medication_orders/${prescriptionId}/register`,
            method: 'POST',
            normalizeSchema: {
              'medications/homeMedicationOrder': ambulatoryPrescriptionSchema,
            },
            actionPayload: { patientId },
          },
        })
      )
      .catch((action) => action);

export const START_CHECK_PRESCRIPTION_STATUSES = networkActionTypes(
  'START_CHECK_PRESCRIPTION_STATUSES'
);

export const startCheckPrescriptionStatuses = (
  patientId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: START_CHECK_PRESCRIPTION_STATUSES,
    url: `patients/${patientId}/check_delivered_status`,
    method: 'POST',
  },
});

export const GET_PRESCRIPTION_STATUSES_CHECKED = networkActionTypes(
  'GET_PRESCRIPTION_STATUSES_CHECKED'
);

export const getPrescriptionStatusesChecked = (
  patientId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PRESCRIPTION_STATUSES_CHECKED,
    url: `patients/${patientId}/delivered_status_checked`,
    method: 'GET',
  },
});

export const CREATE_AMBULATORY_PRESCRIPTION_ITEM =
  'CREATE_AMBULATORY_PRESCRIPTION_ITEM';

export const createAmbulatoryPrescriptionItem = (
  prescriptionId: string | undefined,
  prescriptionItem: AmbulatoryPrescriptionFormItemT,
  patientId: string
) => ({
  type: CREATE_AMBULATORY_PRESCRIPTION_ITEM,
  payload: {
    prescriptionId,
    prescriptionItem,
    patientId,
  },
});

export const UPDATE_AMBULATORY_PRESCRIPTION_ITEM =
  'UPDATE_AMBULATORY_PRESCRIPTION_ITEM';

export const updateAmbulatoryPrescriptionItem = (
  prescriptionId: string | undefined,
  partialPrescriptionItem: Partial<AmbulatoryPrescriptionFormItemT> & {
    id: string;
  },
  patientId: string
) => ({
  type: UPDATE_AMBULATORY_PRESCRIPTION_ITEM,
  payload: {
    prescriptionId,
    partialPrescriptionItem,
    patientId,
  },
});

export const DELETE_AMBULATORY_PRESCRIPTION_ITEM =
  'DELETE_AMBULATORY_PRESCRIPTION_ITEM';

export const deleteAmbulatoryPrescriptionItem = (
  prescriptionId: string | undefined,
  prescriptionItemId: string,
  patientId: string
) => ({
  type: DELETE_AMBULATORY_PRESCRIPTION_ITEM,
  payload: {
    prescriptionId,
    prescriptionItemId,
    patientId,
  },
});

export const CLEAR_AMBULATORY_PRESCRIPTION_DRAFT_ITEMS =
  'CLEAR_AMBULATORY_PRESCRIPTION_DRAFT_ITEMS';

export const clearAmbulatoryPrescriptionDraftItems = (patientId: string) => ({
  type: CLEAR_AMBULATORY_PRESCRIPTION_DRAFT_ITEMS,
  payload: { patientId },
});
