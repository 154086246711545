import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { resetError } from 'store/modules/errors';
import { resetSuccess } from 'store/modules/success';

export default function useActionReset() {
  const dispatch = useDispatch();

  const actionReset = useCallback(() => {
    dispatch(resetSuccess());
    dispatch(resetError());
  }, [dispatch]);

  return actionReset;
}
