import { CSSProperties, ReactNode } from 'react';

import defaultAvatar from 'assets/images/avatar_default_original.png';

type Props = {
  url?: string | null;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
};

export default function Avatar({
  url = defaultAvatar,
  className,
  style: styleProp,
}: Props) {
  const style = {
    ...styleProp,
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  const classString = className ? `avatar ${className}` : 'avatar';

  return <div className={classString} style={style} />;
}
