import { startActivity } from 'store/modules/activity';

import { Wound } from './fetchers';

export const ADD_WOUND = 'ADD_WOUND';
export const EDIT_WOUND = 'EDIT_WOUND';
export const ARCHIVE_WOUND = 'ARCHIVE_WOUND';

export const CREATE_WOUND_FAILURE = 'CREATE_WOUND_FAILURE';
export const UPDATE_WOUND_FAILURE = 'UPDATE_WOUND_FAILURE';

export const EDIT_WOUND_PRESCRIPTION = 'EDIT_WOUND_PRESCRIPTION';

export const UPDATE_WOUND_PRESCRIPTION_FAILURE =
  'UPDATE_WOUND_PRESCRIPTION_FAILURE';

export const addWoundActivity = () => startActivity(ADD_WOUND);

export const editWoundActivity = (wound: Wound) =>
  startActivity(EDIT_WOUND, { wound });

export const archiveWoundActivity = (wound: Wound) =>
  startActivity(ARCHIVE_WOUND, { wound });

export const editPrescriptionActivity = () =>
  startActivity(EDIT_WOUND_PRESCRIPTION);
