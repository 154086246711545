import { ReactNode } from 'react';

interface TableActionsProps {
  children?: ReactNode;
  thead?: boolean;
}

export default function TableActions({
  children,
  thead = false,
}: TableActionsProps) {
  const Tag = thead ? 'th' : 'td';

  return (
    <Tag className="table-cell -is-actions">
      <div>{children}</div>
    </Tag>
  );
}
