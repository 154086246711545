import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useSlate } from 'slate-react';

import Icon from 'components/Icon';

import { getString } from 'styles/vars';

import { BUTTON_WIDTH } from '../domain';
import t from '../translations';
import Button from './Button';
import ColorButton from './ColorButton';
import Popout from './Popout';

const colors = [
  getString('--success'),
  getString('--danger'),
  getString('--info'),
  getString('--warning'),
];

export default function ColorRow() {
  const { formatMessage } = useIntl();
  const editor = useSlate();
  const isActive = editor.isColorActive();

  return (
    <Popout
      width={BUTTON_WIDTH * 5}
      isActive={isActive}
      buttonIcon="format_color_text"
      buttonTitle={formatMessage(t.colors)}
      buttonIsDisabled={!isActive && !editor.hasSelection()}
    >
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          editor.removeColor();
        }}
        title={formatMessage(t.removeColor)}
      >
        <Icon>block</Icon>
      </Button>

      {colors.map((color) => (
        <ColorButton key={color} color={color} />
      ))}
    </Popout>
  );
}
