import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  requestAccountUnlock,
  requestPasswordReset,
} from 'store/modules/entities/actions/users';

import { useAccountLockable } from 'hooks/useConfig';

import Button from 'components/Button';

import ErrorFlash from 'sharedComponents/ErrorFlash';

import SigninContent from '../components/SigninContent';
import useActionReset from '../components/useActionReset';
import t from '../translations';
import ResetRequestForm from './components/Form';

export default function RecoverAccount() {
  const dispatch = useDispatch();
  const actionReset = useActionReset();
  const { formatMessage } = useIntl();

  const isAccountLockable = useAccountLockable();

  const [optionChosen, setOptionChosen] = useState(!isAccountLockable);
  const [recoverType, setRecoverType] = useState(
    isAccountLockable ? 'none' : 'password'
  );

  const success = useSelector((state) => state.success);

  const isSuccess =
    success.type === 'REQUEST_PASSWORD_RESET' ||
    success.type === 'REQUEST_ACCOUNT_UNLOCK';

  useEffect(() => {
    return actionReset;
  }, [actionReset]);

  if (!optionChosen) {
    return (
      <div className="login">
        <SigninContent title={formatMessage(t.recoverAccount)}>
          <Button
            color="info"
            onClick={() => {
              setOptionChosen(true);
              setRecoverType('password');
            }}
          >
            <FormattedMessage {...t.forgotPassword} />
          </Button>

          <Button
            color="info"
            onClick={() => {
              setOptionChosen(true);
              setRecoverType('unlock');
            }}
          >
            <FormattedMessage {...t.requestAccountUnlock} />
          </Button>

          <Link style={{ marginTop: '10px' }} to="/login">
            {formatMessage(t.returnLogin)}
          </Link>
        </SigninContent>
      </div>
    );
  }

  return (
    <div className="login">
      <SigninContent
        title={formatMessage(
          recoverType === 'password' ? t.forgotPassword : t.requestAccountUnlock
        )}
      >
        <ErrorFlash
          close={false}
          substituteMessage={formatMessage(t.errorMessageFallback)}
        />

        {isSuccess ? (
          <p>{formatMessage(t.recoverSuccessMessage)}</p>
        ) : (
          <ResetRequestForm
            onSubmit={(values) =>
              recoverType === 'password'
                ? dispatch(requestPasswordReset(values))
                : dispatch(requestAccountUnlock(values))
            }
          />
        )}

        <Link style={{ marginTop: '10px' }} to="/login">
          {formatMessage(t.returnLogin)}
        </Link>
      </SigninContent>
    </div>
  );
}
