import { schema } from 'normalizr';

import {
  parseDate,
  parseDateTime,
  parseFloat,
  schemaDeserializers,
} from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const serializeDateArray = (dates: string[]) => dates.map(parseDate);

const medicationValidationSchema = new schema.Entity(
  'medicationValidations',
  {},
  {
    processStrategy: (validation) => ({
      ...validation,
      ...schemaDeserializers({
        performedAt: parseDateTime,
        suggestedAt: parseDateTime,
        updatedAt: parseDateTime,
      })(validation),
      performedBy: validation.performedBy
        ? {
            ...validation.performedBy,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(validation.performedBy),
          }
        : null,
      suggestedBy: validation.suggestedBy
        ? {
            ...validation.suggestedBy,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(validation.suggestedBy),
          }
        : null,
    }),
  }
);

// Intradialytic medication
// ==========================

const intradialyticMedicationSchema = new schema.Entity(
  'intradialyticMedication',
  {
    medicationValidation: medicationValidationSchema,
  },
  {
    processStrategy: (medication) => ({
      ...medication,
      ...schemaDeserializers({
        quantity: parseFloat,
        startDate: parseDateTime,
        endDate: parseDateTime,
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
        dates: serializeDateArray,
      })(medication),
      prescriber: medication.prescriber
        ? {
            ...medication.prescriber,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.prescriber),
          }
        : null,
      scheduleInterruption: medication.scheduleInterruption
        ? {
            ...medication.scheduleInterruption,
            ...schemaDeserializers({
              startDate: parseDateTime,
              endDate: parseDateTime,
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.scheduleInterruption),
          }
        : null,
    }),
  }
);

export const GET_INTRADIALYTIC_MEDICATIONS = networkActionTypes(
  'GET_INTRADIALYTIC_MEDICATIONS'
);

export type GetIntradialyticMedicationsArgsT = {
  patientId: string;
  status: MedicationFilterT;
  validationStatus?: ValidationMedicationStatusT[];
};

export const getIntradialyticMedications = ({
  patientId,
  status,
  validationStatus,
}: GetIntradialyticMedicationsArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INTRADIALYTIC_MEDICATIONS,
    url: `patients/${patientId}/intradialytic_medication_prescriptions`,
    method: 'GET',
    actionPayload: { patientId },
    params: { status, validationStatus },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescriptions': [
        intradialyticMedicationSchema,
      ],
    },
  },
});

export const GET_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'GET_INTRADIALYTIC_MEDICATION'
);

export type GetIntradialyticMedicationArgsT = {
  patientId: string;
  id: string;
};

export const getIntradialyticMedication = ({
  patientId,
  id,
}: GetIntradialyticMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${id}`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescription':
        intradialyticMedicationSchema,
    },
  },
});

export const GET_INTRADIALYTIC_MEDICATION_HISTORY = networkActionTypes(
  'GET_INTRADIALYTIC_MEDICATION_HISTORY'
);

export type GetIntradialyticMedicationHistoryArgsT = {
  patientId: string;
  id: string;
};

export const getIntradialyticMedicationHistory = ({
  patientId,
  id,
}: GetIntradialyticMedicationHistoryArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INTRADIALYTIC_MEDICATION_HISTORY,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${id}/history`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescriptions': [
        intradialyticMedicationSchema,
      ],
    },
  },
});

export const GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS = networkActionTypes(
  'GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS'
);

export const getIntradialyticMedicationSuggestions = ({
  patientId,
}: {
  patientId: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INTRADIALYTIC_MEDICATION_SUGGESTIONS,
    url: `patients/${patientId}/medication_validations`,
    method: 'GET',
    actionPayload: { patientId },
    params: { validationType: 'prescription' },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescriptions': [
        intradialyticMedicationSchema,
      ],
    },
  },
});

export const CREATE_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'CREATE_INTRADIALYTIC_MEDICATION'
);

export type CreateIntradialyticMedicationArgsT = {
  patientId: string;
  medicationType: string;
  productId?: string;
  formula?: string;
  form?: string;
  variableDose: boolean;
  quantity: number;
  prescribableUnitId?: string;
  unit?: string;
  frequency: string;
  days?: string[];
  dates?: Date[];
  whenNecessary: boolean;
  moment: string;
  beforeMomentMinutes?: number;
  startDate: Date;
  endDate?: Date;
  comment?: string;
  consumptionSetId?: string;
  routeId?: string;
  routeName?: string;
};

export const createIntradialyticMedication = (
  { patientId, ...values }: CreateIntradialyticMedicationArgsT,
  status: MedicationFilterT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions`,
    method: 'POST',
    payload: {
      prescription: {
        ...values,
        dates: values.frequency === 'specific_dates' ? values.dates : null,
        days: values.days || [],
      },
    },
    actionPayload: {
      patientId,
      status,
    },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescription':
        intradialyticMedicationSchema,
    },
  },
});

export const UPDATE_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'UPDATE_INTRADIALYTIC_MEDICATION'
);

export type UpdateIntradialyticMedicationArgsT = {
  id: string;
  patientId: string;
  variableDose: boolean;
  quantity: number;
  prescribableUnitId?: string;
  unit?: string;
  frequency: string;
  dates?: Date[];
  days?: string[];
  whenNecessary: boolean;
  moment: string;
  beforeMomentMinutes?: number;
  startDate: Date;
  endDate?: Date | null;
  comment?: string;
  routeId?: string;
  routeName?: string;
};

export const updateIntradialyticMedication = ({
  id,
  patientId,
  ...values
}: UpdateIntradialyticMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${id}`,
    method: 'PUT',
    payload: {
      prescription: {
        ...values,
        dates: values.frequency === 'specific_dates' ? values.dates : null,
        days: values.days || [],
        comment: values.comment || null,
      },
    },
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/intradialyticMedicationPrescription':
        intradialyticMedicationSchema,
    },
  },
});

export const RESTART_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'RESTART_INTRADIALYTIC_MEDICATION'
);

export type RestartIntradialyticMedicationArgsT = {
  id: string;
  patientId: string;
  startDate: Date;
  endDate?: Date;
  dates?: Date[];
  frequency: string;
};

export const restartIntradialyticMedication = ({
  id,
  patientId,
  startDate,
  endDate,
  dates,
  frequency,
}: RestartIntradialyticMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: RESTART_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${id}/restart`,
    method: 'POST',
    payload: {
      prescription: {
        id,
        startDate,
        endDate,
        dates: frequency === 'specific_dates' ? dates : null,
      },
    },
    actionPayload: {
      id,
      patientId,
    },
  },
});

export const STOP_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'STOP_INTRADIALYTIC_MEDICATION'
);

export type StopIntradialyticMedicationArgsT = {
  id: string;
  patientId: string;
  stopReason: string | null;
};

export const stopIntradialyticMedication = ({
  id,
  patientId,
  stopReason,
}: StopIntradialyticMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: STOP_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${id}/stop`,
    method: 'POST',
    payload: { stopReason },
    actionPayload: { id, patientId },
  },
});

export type ValidateIntradialyticMedicationArgsT = {
  patientId: string;
  status: string;
  rejectionReason?: string;
  medicationId: string;
};

export const VALIDATE_INTRADIALYTIC_MEDICATION = networkActionTypes(
  'VALIDATE_INTRADIALYTIC_MEDICATION'
);

export const validateIntradialyticMedication = ({
  patientId,
  status,
  rejectionReason,
  medicationId,
}: ValidateIntradialyticMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: VALIDATE_INTRADIALYTIC_MEDICATION,
    url: `patients/${patientId}/intradialytic_medication_prescriptions/${medicationId}/validate`,
    method: 'PATCH',
    payload: {
      validation: {
        status,
        rejectionReason,
      },
    },
    actionPayload: {
      patientId,
      medicationId,
      status,
    },
    normalizeSchema: {
      medicationValidation: medicationValidationSchema,
    },
  },
});

// Ambulatory medication
// =================

const ambulatoryMedicationSchema = new schema.Entity(
  'ambulatoryMedication',
  {},
  {
    processStrategy: (medication) => ({
      ...medication,
      ...schemaDeserializers({
        quantity: parseFloat,
        startDate: parseDateTime,
        endDate: parseDateTime,
        updatedAt: parseDateTime,
        createdAt: parseDateTime,
        dates: serializeDateArray,
      })(medication),
      prescriber: medication.prescriber
        ? {
            ...medication.prescriber,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              deletedAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.prescriber),
          }
        : null,
    }),
  }
);

export const GET_AMBULATORY_MEDICATION = networkActionTypes(
  'GET_AMBULATORY_MEDICATION'
);

export type GetAmbulatoryMedicationArgsT = {
  patientId: string;
  status: string;
};

export const getAmbulatoryMedication = ({
  patientId,
  status,
}: GetAmbulatoryMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_MEDICATION,
    url: `patients/${patientId}/home_medication_prescriptions`,
    method: 'GET',
    actionPayload: {
      patientId,
    },
    params: { status },
    normalizeSchema: {
      'medications/homeMedicationPrescriptions': [ambulatoryMedicationSchema],
    },
  },
});

export const GET_AMBULATORY_MEDICATION_HISTORY = networkActionTypes(
  'GET_AMBULATORY_MEDICATION_HISTORY'
);

export type GetAmbulatoryMedicationHistoryArgsT = {
  patientId: string;
  id: string;
};

export const getAmbulatoryMedicationHistory = ({
  patientId,
  id,
}: GetAmbulatoryMedicationHistoryArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_MEDICATION_HISTORY,
    url: `patients/${patientId}/home_medication_prescriptions/${id}/history`,
    method: 'GET',
    params: { patientId, medicationId: id },
    actionPayload: {
      patientId,
    },
    normalizeSchema: {
      'medications/homeMedicationPrescriptions': [ambulatoryMedicationSchema],
    },
  },
});

export const CREATE_AMBULATORY_MEDICATION = networkActionTypes(
  'CREATE_AMBULATORY_MEDICATION'
);

export type CreateAmbulatoryMedicationArgsT = {
  patientId: string;
  medicationType: string;
  productId: string;
  formula: string;
  form: string;
  posology: AmbulatoryMedicationPosologyT;
  patientInstructions?: string | null;
  startDate: Date;
  endDate?: Date | null;
  comment?: string | null;
};

export const createAmbulatoryMedication = (
  {
    patientId,
    medicationType,
    productId,
    formula,
    form,
    posology,
    patientInstructions,
    startDate,
    endDate,
    comment,
  }: CreateAmbulatoryMedicationArgsT,
  status: MedicationFilterT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_AMBULATORY_MEDICATION,
    url: `patients/${patientId}/home_medication_prescriptions`,
    method: 'POST',
    payload: {
      prescription: {
        medicationType,
        productId,
        formula,
        form,
        posology,
        patientInstructions,
        startDate,
        endDate,
        comment,
      },
    },
    actionPayload: { patientId, status },
    normalizeSchema: {
      'medications/homeMedicationPrescription': ambulatoryMedicationSchema,
    },
  },
});

export const UPDATE_AMBULATORY_MEDICATION = networkActionTypes(
  'UPDATE_AMBULATORY_MEDICATION'
);

export type UpdateAmbulatoryMedicationArgsT = {
  id: string;
  patientId: string;
  posology: AmbulatoryMedicationPosologyT;
  patientInstructions?: string | null;
  startDate: Date;
  endDate?: Date | null;
  comment?: string | null;
};

export const updateAmbulatoryMedication = ({
  id,
  patientId,
  posology,
  patientInstructions,
  startDate,
  endDate,
  comment,
}: UpdateAmbulatoryMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_AMBULATORY_MEDICATION,
    url: `patients/${patientId}/home_medication_prescriptions/${id}`,
    method: 'PUT',
    payload: {
      prescription: {
        posology,
        patientInstructions,
        startDate,
        endDate,
        comment,
      },
    },
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/homeMedicationPrescription': ambulatoryMedicationSchema,
    },
  },
});

export const RESTART_AMBULATORY_MEDICATION = networkActionTypes(
  'RESTART_AMBULATORY_MEDICATION'
);

interface RestartAmbulatoryMedicationArgsT {
  id: string;
  patientId: string;
  startDate: Date;
  endDate?: Date | null;
}

export const restartAmbulatoryMedication = ({
  id,
  patientId,
  startDate,
  endDate,
}: RestartAmbulatoryMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: RESTART_AMBULATORY_MEDICATION,
    url: `patients/${patientId}/home_medication_prescriptions/${id}/restart`,
    method: 'POST',
    payload: {
      prescription: {
        id,
        startDate,
        endDate,
      },
    },
    actionPayload: {
      id,
      patientId,
    },
  },
});

export const STOP_AMBULATORY_MEDICATION = networkActionTypes(
  'STOP_AMBULATORY_MEDICATION'
);

export type StopAmbulatoryMedicationArgsT = {
  id: string;
  patientId: string;
  stopReason: string | null;
};

export const stopAmbulatoryMedication = ({
  id,
  patientId,
  stopReason,
}: StopAmbulatoryMedicationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: STOP_AMBULATORY_MEDICATION,
    url: `patients/${patientId}/home_medication_prescriptions/${id}/stop`,
    method: 'POST',
    payload: { stopReason },
    actionPayload: { id, patientId },
  },
});

// Ambulatory medication schedule
// ==========================

const serializeIntakeMoment = (intakeMoment: any) => ({
  ...intakeMoment,
  quantity: parseFloat(intakeMoment.quantity),
  updatedAt: parseDateTime(intakeMoment.updateAt),
  createdAt: parseDateTime(intakeMoment.createdAt),
});

const ambulatoryMedicationScheduleSchema = new schema.Entity(
  'ambulatoryMedicationSchedule',
  {},
  {
    processStrategy: schemaDeserializers({
      intakeMoments: (val: any[]): any[] => val.map(serializeIntakeMoment),
      dates: serializeDateArray,
      quantity: parseFloat,
      updatedAt: parseDateTime,
      createdAt: parseDateTime,
      startDate: parseDate,
      endDate: parseDate,
    }),
  }
);

export const GET_AMBULATORY_MEDICATION_SCHEDULE = networkActionTypes(
  'GET_AMBULATORY_MEDICATION_SCHEDULE'
);

export const getAmbulatoryMedicationSchedule = (
  patientId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_MEDICATION_SCHEDULE,
    url: `patients/${patientId}/home_medication_schedule`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/homeMedicationSchedule': [
        ambulatoryMedicationScheduleSchema,
      ],
    },
  },
});

export const UPDATE_AMBULATORY_MEDICATION_SCHEDULE = networkActionTypes(
  'UPDATE_AMBULATORY_MEDICATION_SCHEDULE'
);

export const updateAmbulatoryMedicationSchedule = (
  patientId: string,
  schedule: any
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_AMBULATORY_MEDICATION_SCHEDULE,
    url: `patients/${patientId}/home_medication_schedule`,
    method: 'PUT',
    payload: {
      home_medication_schedule: schedule,
    },
    actionPayload: {
      patientId,
    },
    normalizeSchema: {
      'medications/homeMedicationSchedule': [
        ambulatoryMedicationScheduleSchema,
      ],
    },
  },
});

export const CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY =
  'CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY';

export const changeIntakeMomentQuantity = (
  patientId: string,
  prescriptionId: string,
  moment: string,
  quantity: number
) => ({
  type: CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY,
  payload: { patientId, prescriptionId, moment, quantity },
});

export const DISCARD_PENDING_INTAKE_MOMENTS = 'DISCARD_PENDING_INTAKE_MOMENTS';

export const discardPendingIntakeMoments = (patientId: string) => ({
  type: DISCARD_PENDING_INTAKE_MOMENTS,
  payload: { patientId },
});

// Invalid prescriptions
// =======================

export const GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS = networkActionTypes(
  'GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS'
);

export const getInvalidIntradialyticPrescriptions = (
  page = 0
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS,
    url: 'medications/mismatching_intradialytic_prescriptions',
    method: 'GET',
    params: { page, pageSize: 30 },
    normalizeSchema: { data: [intradialyticMedicationSchema] },
  },
});

export const GET_INVALID_AMBULATORY_PRESCRIPTIONS = networkActionTypes(
  'GET_INVALID_AMBULATORY_PRESCRIPTIONS'
);

export const getInvalidAmbulatoryPrescriptions = (
  page = 0
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_INVALID_AMBULATORY_PRESCRIPTIONS,
    url: 'medications/mismatching_ambulatory_prescriptions',
    method: 'GET',
    params: { page, pageSize: 30 },
    normalizeSchema: { data: [ambulatoryMedicationSchema] },
  },
});
