import { Action } from 'redux';

import {
  CANCEL_ACTION_BAR_ACTIVITY,
  START_ACTIVITY,
} from 'store/modules/activity';
import {
  CREATE_CODE_FOR_CODE_SYSTEM,
  CREATE_CODE_SYSTEM,
  UPDATE_CODE,
  UPDATE_CODE_SYSTEM,
} from 'store/modules/entities/actions/codeSystems';
import { READY_EXPORTABLE } from 'store/modules/entities/actions/exportables';
import { DELETE_JOURNAL_ENTRY } from 'store/modules/entities/actions/journals';
import {
  CREATE_AMBULATORY_MEDICATION,
  CREATE_INTRADIALYTIC_MEDICATION,
  RESTART_AMBULATORY_MEDICATION,
  RESTART_INTRADIALYTIC_MEDICATION,
  STOP_AMBULATORY_MEDICATION,
  STOP_INTRADIALYTIC_MEDICATION,
  UPDATE_AMBULATORY_MEDICATION,
  UPDATE_INTRADIALYTIC_MEDICATION,
} from 'store/modules/entities/actions/medication';
import {
  CANCEL_AMBULATORY_PRESCRIPTION,
  COPY_AMBULATORY_PRESCRIPTION,
  CREATE_AMBULATORY_PRESCRIPTION,
  REGISTER_AMBULATORY_PRESCRIPTION,
} from 'store/modules/entities/actions/medication/ambulatoryPrescriptions';
import {
  CREATE_MEDICATION_INTERRUPTION,
  UPDATE_MEDICATION_INTERRUPTION,
} from 'store/modules/entities/actions/medication/interruptions';
import { CREATE_MEDICINAL_THERAPY_PLAN } from 'store/modules/entities/actions/medication/therapyPlans';
import {
  CREATE_ADMINISTRATION_ROUTE,
  UPDATE_ADMINISTRATION_ROUTE,
} from 'store/modules/entities/actions/medicationManagement/administrationRoutes';
import {
  CREATE_PRODUCT_CONSUMPTION_SET,
  UPDATE_PRODUCT_CONSUMPTION_SET,
} from 'store/modules/entities/actions/medicationManagement/consumptionSets';
import {
  IMPORT_AMBULATORY_MEDICINAL_PRODUCT_FROM_PROVIDER,
  SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS,
} from 'store/modules/entities/actions/medicationManagement/import';
import {
  CREATE_MEDICINAL_INGREDIENT,
  UPDATE_MEDICINAL_INGREDIENT,
} from 'store/modules/entities/actions/medicationManagement/ingredients';
import {
  CREATE_MEDICINAL_NOT_GIVEN_REASON,
  UPDATE_MEDICINAL_NOT_GIVEN_REASON,
} from 'store/modules/entities/actions/medicationManagement/notGivenReasons';
import {
  CREATE_MEDICINAL_PRODUCT,
  UPDATE_MEDICINAL_PRODUCT,
} from 'store/modules/entities/actions/medicationManagement/products';
import {
  CREATE_MEDICINAL_PRODUCT_PROVIDER,
  UPDATE_MEDICINAL_PRODUCT_PROVIDER,
} from 'store/modules/entities/actions/medicationManagement/providers';
import {
  CREATE_PATIENT,
  DEACTIVATE_PATIENT,
  MERGE_PATIENTS,
  UPDATE_PATIENT,
  UPDATE_PATIENT_AVATAR,
  UPDATE_PATIENT_CONTACT_INFO,
  UPDATE_PATIENT_DISORDER,
  UPDATE_PATIENT_MEDICAL_INFO,
} from 'store/modules/entities/actions/patients';
import { CREATE_TAG } from 'store/modules/entities/actions/tags';
import {
  REQUEST_ACCOUNT_UNLOCK,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  UNLOCK_ACCOUNT,
} from 'store/modules/entities/actions/users';
import { SET_CLIENT_FORM_ERRORS } from 'store/modules/form';
import {
  FORBIDDEN,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
  PAYLOAD_TOO_LARGE,
} from 'store/modules/network';
import { LOCATION_CHANGE } from 'store/modules/router';

import getType from 'utils/getType';

// React-Query
//==============================================================================

import {
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE,
} from 'sharedComponents/Users/Profile/actions';

import {
  CREATE_DEVICE_FAILURE,
  UPDATE_DEVICE_FAILURE,
} from 'views/admin/devices/devices/actions';
import {
  CREATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE,
  CREATE_IDENTIFIER_TYPE_FAILURE,
  UPDATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE,
  UPDATE_IDENTIFIER_TYPE_FAILURE,
} from 'views/admin/iam/identifiers/actions';
import {
  CREATE_PATIENT_CARD_SETTING_FAILURE,
  UPDATE_PATIENT_CARD_SETTING_FAILURE,
} from 'views/admin/iam/identifiers/patientCardSettings/activity';
import {
  CREATE_ROLE_FAILURE,
  UPDATE_ROLE_FAILURE,
} from 'views/admin/iam/permissions/actions';
import {
  CREATE_USER_FAILURE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_FAILURE,
} from 'views/admin/iam/users/actions';
import {
  CREATE_CENTER_FAILURE,
  CREATE_POSITION_FAILURE,
  CREATE_ROOM_FAILURE,
  UPDATE_CENTER_FAILURE,
  UPDATE_POSITION_FAILURE,
  UPDATE_POSITION_ORDER_FAILURE,
  UPDATE_ROOM_FAILURE,
} from 'views/admin/infrastructure/actions';
import { CREATE_REPORT_TEMPLATE_FAILURE } from 'views/admin/reports/actions';
import { UPDATE_CONFIGURATION_FAILURE } from 'views/admin/system/actions';
import {
  CREATE_BRIEFING_FAILURE,
  CREATE_BRIEFING_SELECTION_FAILURE,
  CREATE_DISCUSSION_FAILURE,
  DELETE_BRIEFING_SELECTION_FAILURE,
  DELETE_DISCUSSION_FAILURE,
  UPDATE_BRIEFING_FAILURE,
} from 'views/record/briefings/actions';
import { CREATE_BULK_ORDER_FAILURE } from 'views/record/patients/listing/actions';
import {
  CREATE_PATIENT_ATTACHMENT_FAILURE,
  UPDATE_PATIENT_ATTACHMENT_FAILURE,
} from 'views/record/patients/patient/attachments/actions';
import {
  CREATE_PATIENT_COMPLICATION_FAILURE,
  UPDATE_PATIENT_COMPLICATION_FAILURE,
} from 'views/record/patients/patient/complications/actions';
import { UPDATE_PATIENT_DIET_FAILURE } from 'views/record/patients/patient/diet/actions';
import {
  CREATE_ENCOUNTER_DOCUMENT_FAILURE,
  CREATE_ENCOUNTER_FAILURE,
  UPDATE_ENCOUNTER_FAILURE,
} from 'views/record/patients/patient/encounters/actions';
import {
  CREATE_PATIENT_ADDRESS_FAILURE,
  UPDATE_PATIENT_ADDRESS_FAILURE,
} from 'views/record/patients/patient/generalInformation/addresses/actions';
import { UPDATE_CARE_TEAM_FAILURE } from 'views/record/patients/patient/generalInformation/careTeam/actions';
import {
  CREATE_PATIENT_CONTACT_FAILURE,
  UPDATE_PATIENT_CONTACT_FAILURE,
} from 'views/record/patients/patient/generalInformation/contacts/actions';
import { UPDATE_PATIENT_IDENTIFIER_FAILURE } from 'views/record/patients/patient/generalInformation/identifiers/actions';
import {
  CREATE_PATIENT_INSURANCE_FAILURE,
  UPDATE_PATIENT_INSURANCE_FAILURE,
} from 'views/record/patients/patient/generalInformation/insurances/actions';
import {
  CREATE_ALLERGY_FAILURE,
  UPDATE_ALLERGY_FAILURE,
} from 'views/record/patients/patient/medicalInformation/Conditions/Allergies/actions';
import {
  CREATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE,
  UPDATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE,
} from 'views/record/patients/patient/medicalInformation/FluidBalance/Ultrafiltration/actions';
import {
  CREATE_WEIGHT_FAILURE,
  UPDATE_WEIGHT_FAILURE,
} from 'views/record/patients/patient/medicalInformation/FluidBalance/Weight/actions';
import {
  CREATE_REMARK_FAILURE,
  UPDATE_REMARK_FAILURE,
} from 'views/record/patients/patient/medicalInformation/General/Remarks/actions';
import {
  CREATE_HISTORIC_VACCINATION_FAILURE,
  CREATE_PLANNED_VACCINATION_FAILURE,
  UPDATE_HISTORIC_VACCINATION_FAILURE,
  UPDATE_PATIENT_IMMUNIZATION_FAILURE,
} from 'views/record/patients/patient/medication/immunization/actions';
import { CREATE_PATIENT_OBSERVATION_FAILURE } from 'views/record/patients/patient/observations/actions';
import {
  CREATE_ORDER_FAILURE,
  UPDATE_ORDER_FAILURE,
} from 'views/record/patients/patient/orders/actions';
import {
  CREATE_ABSENCE_FAILURE,
  CREATE_PLANNING_SCHEMA_FAILURE,
  UPDATE_DEVIATION_FAILURE,
  UPDATE_PLANNING_SCHEMA_FAILURE,
} from 'views/record/patients/patient/planningSchemas/actions';
import {
  CLONE_PATIENT_TREATMENT_FAILURE,
  CREATE_PATIENT_TREATMENT_FAILURE,
  UPDATE_PATIENT_TREATMENT_FAILURE,
  UPDATE_PATIENT_TREATMENT_PRESCRIPTION_FAILURE,
} from 'views/record/patients/patient/prescriptions/actions';
import {
  CREATE_ACCESS_SITE_COMPLICATION_FAILURE,
  CREATE_TREATMENT_ACCESS_SITE_FAILURE,
  REPLACE_CATHETER_FAILURE,
  UPDATE_ACCESS_SITE_COMPLICATION_FAILURE,
  UPDATE_CATHETER_CARE_PRESCRIPTION_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_FAILURE,
} from 'views/record/patients/patient/treatmentAccess/actions';
import {
  CREATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE,
} from 'views/record/patients/patient/treatmentAccess/site/attachments/actions';
import {
  CREATE_CATHETER_EXTENSION_FAILURE,
  UPDATE_CATHETER_EXTENSION_FAILURE,
} from 'views/record/patients/patient/treatmentAccess/site/catheterExtensions/actions';
import {
  CREATE_TREATMENT_ACCESS_CATHETER_EXAMINATION_FAILURE,
  CREATE_TREATMENT_ACCESS_EXAMINATION_FAILURE,
} from 'views/record/patients/patient/treatmentAccess/site/examinations/actions';
import { UPDATE_HEMOSTASIS_DEFAULT_FAILURE } from 'views/record/patients/patient/treatmentAccess/site/hemostasis/actions';
import {
  CREATE_INTERVENTION_FAILURE,
  UPDATE_INTERVENTION_FAILURE,
} from 'views/record/patients/patient/treatmentAccess/site/interventions/actions';
import {
  CREATE_WOUND_FAILURE,
  UPDATE_WOUND_FAILURE,
  UPDATE_WOUND_PRESCRIPTION_FAILURE,
} from 'views/record/patients/patient/woundCare/actions';
import { CREATE_WOUND_EVALUATION_FAILURE } from 'views/record/patients/patient/woundCare/evaluation/actions';
import { CREATE_PLANNING_ENTRY_WITH_PATIENT_FAILURE } from 'views/record/planning/actions';
import { CREATE_REPORT_FAILURE } from 'views/record/reports/actions';
import {
  UPDATE_BILLING_CODE_FAILURE,
  UPDATE_BILLING_TREATMENT_SETTINGS_FAILURE,
} from 'views/record/settings/billing/actions';
import {
  UPDATE_CLINICAL_CATEGORY_LAB_PARAMETERS_FAILURE,
  UPDATE_CLINICAL_CATEGORY_PRODUCTS_FAILURE,
} from 'views/record/settings/clinicalSettings/categories/[categoryId]/actions';
import {
  CREATE_CLINICAL_CATEGORY_FAILURE,
  UPDATE_CLINICAL_CATEGORY_FAILURE,
} from 'views/record/settings/clinicalSettings/categories/actions';
import {
  CREATE_LAB_PARAMETER_FAILURE,
  LINK_OBSERVATION_REGISTRY_FAILURE,
  UPDATE_LAB_PARAMETER_FAILURE,
} from 'views/record/settings/clinicalSettings/labParameters/actions';
import { CREATE_LAB_SYSTEM_FAILURE } from 'views/record/settings/clinicalSettings/labSystems/actions';
import { CREATE_ULTRAFILTRATION_PROFILE_FAILURE } from 'views/record/settings/clinicalSettings/ultrafiltrationProfiles/actions';
import {
  CREATE_UNIT_ALIAS_FAILURE,
  UPDATE_UNIT_ALIAS_FAILURE,
} from 'views/record/settings/clinicalSettings/unitAliases/actions';
import {
  CREATE_VACCINATION_GROUP_FAILURE,
  UPDATE_VACCINATION_GROUP_FAILURE,
} from 'views/record/settings/clinicalSettings/vaccinationGroups/actions';
import {
  CREATE_CONTEXT_LINK_FAILURE,
  UPDATE_CONTEXT_LINK_FAILURE,
} from 'views/record/settings/contextLinks/actions';
import {
  CREATE_ENCOUNTER_TYPE_FAILURE,
  UPDATE_ENCOUNTER_TYPE_FAILURE,
} from 'views/record/settings/encounters/types/actions';
import {
  CREATE_DISPOSABLE_ITEM_FAILURE,
  UPDATE_DISPOSABLE_ITEM_FAILURE,
} from 'views/record/settings/logistics/disposables/actions';
import {
  CREATE_DIET_FAILURE,
  UPDATE_DIET_FAILURE,
} from 'views/record/settings/nutrition/diets/actions';
import {
  CREATE_ORDER_TYPE_FAILURE,
  UPDATE_ORDER_TYPE_FAILURE,
} from 'views/record/settings/orderTypes/actions';
import {
  CREATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE,
  UPDATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE,
} from 'views/record/settings/transplantEligibilityStatuses/actions';
import {
  CREATE_TRANSPORT_TYPE_FAILURE,
  UPDATE_TRANSPORT_TYPE_FAILURE,
} from 'views/record/settings/transportTypes/actions';
import {
  CREATE_INTERVENTION_TYPE_FAILURE,
  CREATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE,
  UPDATE_INTERVENTION_TYPE_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE,
} from 'views/record/settings/treatmentAccess/actions';
import {
  CREATE_PATIENT_CARD_FAILURE,
  UPDATE_PATIENT_CARD_FAILURE,
} from 'views/track/cards/actions';
import {
  CREATE_DEVICE_ATTACHMENT_FAILURE,
  UPDATE_DEVICE_ATTACHMENT_FAILURE,
} from 'views/workbench/actions';

// Actions
//==============================================================================

export const RESET_ERROR = 'RESET_ERROR';

// Action Creators
//==============================================================================

export function resetError() {
  return { type: RESET_ERROR };
}

// Reducer
//==============================================================================

type ErrorsState = ErrorT[];

interface ErrorsAction extends Action<string> {
  payload?: any;
  error?: any;
}

export const initialState = [];

function setErrors({
  handlerType = 'unhandled',
  errorMapFunction,
}: {
  handlerType?: string;
  errorMapFunction?: (rawError: ErrorT) => ErrorT;
}) {
  return (_state: ErrorsState, action: ErrorsAction): ErrorsState => {
    const { payload, error: showError } = action;

    if (!showError) return [];

    const rawErrors = Array.isArray(payload) ? payload : [payload];

    const errors = rawErrors.map(
      errorMapFunction
        ? errorMapFunction
        : (rawError) => ({
            ...rawError,
            handlerType,
          })
    );

    return errors;
  };
}

const FORM_HANDLER_ACTIONS = [
  CANCEL_AMBULATORY_PRESCRIPTION.FAILURE,
  CLONE_PATIENT_TREATMENT_FAILURE,
  COPY_AMBULATORY_PRESCRIPTION.FAILURE,
  CREATE_ABSENCE_FAILURE,
  CREATE_ACCESS_SITE_COMPLICATION_FAILURE,
  CREATE_ADMINISTRATION_ROUTE.FAILURE,
  CREATE_ALLERGY_FAILURE,
  CREATE_AMBULATORY_MEDICATION.FAILURE,
  CREATE_AMBULATORY_PRESCRIPTION.FAILURE,
  CREATE_BRIEFING_FAILURE,
  CREATE_BRIEFING_SELECTION_FAILURE,
  CREATE_BULK_ORDER_FAILURE,
  CREATE_CATHETER_EXTENSION_FAILURE,
  CREATE_CENTER_FAILURE,
  CREATE_CLINICAL_CATEGORY_FAILURE,
  CREATE_CODE_FOR_CODE_SYSTEM.FAILURE,
  CREATE_CODE_SYSTEM.FAILURE,
  CREATE_DISPOSABLE_ITEM_FAILURE,
  CREATE_CONTEXT_LINK_FAILURE,
  CREATE_DEVICE_ATTACHMENT_FAILURE,
  CREATE_DEVICE_FAILURE,
  CREATE_DIET_FAILURE,
  CREATE_DISCUSSION_FAILURE,
  CREATE_ENCOUNTER_DOCUMENT_FAILURE,
  CREATE_ENCOUNTER_FAILURE,
  CREATE_ENCOUNTER_TYPE_FAILURE,
  CREATE_HISTORIC_VACCINATION_FAILURE,
  CREATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE,
  CREATE_IDENTIFIER_TYPE_FAILURE,
  CREATE_INTERVENTION_FAILURE,
  CREATE_INTERVENTION_TYPE_FAILURE,
  CREATE_INTRADIALYTIC_MEDICATION.FAILURE,
  CREATE_LAB_PARAMETER_FAILURE,
  CREATE_LAB_SYSTEM_FAILURE,
  CREATE_MEDICATION_INTERRUPTION.FAILURE,
  CREATE_MEDICINAL_INGREDIENT.FAILURE,
  CREATE_MEDICINAL_NOT_GIVEN_REASON.FAILURE,
  CREATE_MEDICINAL_PRODUCT.FAILURE,
  CREATE_MEDICINAL_PRODUCT_PROVIDER.FAILURE,
  CREATE_MEDICINAL_THERAPY_PLAN.FAILURE,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_TYPE_FAILURE,
  CREATE_PATIENT.FAILURE,
  CREATE_PATIENT_ADDRESS_FAILURE,
  CREATE_PATIENT_ATTACHMENT_FAILURE,
  CREATE_PATIENT_CARD_FAILURE,
  CREATE_PATIENT_CARD_SETTING_FAILURE,
  CREATE_PATIENT_COMPLICATION_FAILURE,
  CREATE_PATIENT_CONTACT_FAILURE,
  CREATE_PATIENT_INSURANCE_FAILURE,
  CREATE_PATIENT_OBSERVATION_FAILURE,
  CREATE_PATIENT_TREATMENT_FAILURE,
  CREATE_PLANNED_VACCINATION_FAILURE,
  CREATE_PLANNING_ENTRY_WITH_PATIENT_FAILURE,
  CREATE_PLANNING_SCHEMA_FAILURE,
  CREATE_POSITION_FAILURE,
  CREATE_PRODUCT_CONSUMPTION_SET.FAILURE,
  CREATE_REMARK_FAILURE,
  CREATE_REPORT_FAILURE,
  CREATE_REPORT_TEMPLATE_FAILURE,
  CREATE_ROLE_FAILURE,
  CREATE_ROOM_FAILURE,
  CREATE_TAG.FAILURE,
  CREATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE,
  CREATE_TRANSPORT_TYPE_FAILURE,
  CREATE_TREATMENT_ACCESS_CATHETER_EXAMINATION_FAILURE,
  CREATE_TREATMENT_ACCESS_EXAMINATION_FAILURE,
  CREATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE,
  CREATE_TREATMENT_ACCESS_SITE_FAILURE,
  CREATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE,
  CREATE_ULTRAFILTRATION_PROFILE_FAILURE,
  CREATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE,
  CREATE_UNIT_ALIAS_FAILURE,
  CREATE_VACCINATION_GROUP_FAILURE,
  CREATE_WEIGHT_FAILURE,
  CREATE_WOUND_EVALUATION_FAILURE,
  CREATE_WOUND_FAILURE,
  DEACTIVATE_PATIENT.FAILURE,
  DELETE_BRIEFING_SELECTION_FAILURE,
  DELETE_DISCUSSION_FAILURE,
  DELETE_JOURNAL_ENTRY.FAILURE,
  LINK_OBSERVATION_REGISTRY_FAILURE,
  MERGE_PATIENTS.FAILURE,
  READY_EXPORTABLE.FAILURE,
  REGISTER_AMBULATORY_PRESCRIPTION.FAILURE,
  REPLACE_CATHETER_FAILURE,
  REQUEST_ACCOUNT_UNLOCK.FAILURE,
  REQUEST_PASSWORD_RESET.FAILURE,
  RESET_PASSWORD.FAILURE,
  RESTART_AMBULATORY_MEDICATION.FAILURE,
  RESTART_INTRADIALYTIC_MEDICATION.FAILURE,
  SET_CLIENT_FORM_ERRORS,
  STOP_AMBULATORY_MEDICATION.FAILURE,
  STOP_INTRADIALYTIC_MEDICATION.FAILURE,
  UNLOCK_ACCOUNT.FAILURE,
  UPDATE_ACCESS_SITE_COMPLICATION_FAILURE,
  UPDATE_ADMINISTRATION_ROUTE.FAILURE,
  UPDATE_ALLERGY_FAILURE,
  UPDATE_AMBULATORY_MEDICATION.FAILURE,
  UPDATE_BILLING_CODE_FAILURE,
  UPDATE_BILLING_TREATMENT_SETTINGS_FAILURE,
  UPDATE_BRIEFING_FAILURE,
  UPDATE_CARE_TEAM_FAILURE,
  UPDATE_CATHETER_CARE_PRESCRIPTION_FAILURE,
  UPDATE_CATHETER_EXTENSION_FAILURE,
  UPDATE_CENTER_FAILURE,
  UPDATE_CLINICAL_CATEGORY_FAILURE,
  UPDATE_CLINICAL_CATEGORY_LAB_PARAMETERS_FAILURE,
  UPDATE_CLINICAL_CATEGORY_PRODUCTS_FAILURE,
  UPDATE_CODE.FAILURE,
  UPDATE_CODE_SYSTEM.FAILURE,
  UPDATE_CONFIGURATION_FAILURE,
  UPDATE_DISPOSABLE_ITEM_FAILURE,
  UPDATE_CONTEXT_LINK_FAILURE,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE,
  UPDATE_DEVIATION_FAILURE,
  UPDATE_DEVICE_ATTACHMENT_FAILURE,
  UPDATE_DEVICE_FAILURE,
  UPDATE_DIET_FAILURE,
  UPDATE_ENCOUNTER_FAILURE,
  UPDATE_ENCOUNTER_TYPE_FAILURE,
  UPDATE_HEMOSTASIS_DEFAULT_FAILURE,
  UPDATE_HISTORIC_VACCINATION_FAILURE,
  UPDATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE,
  UPDATE_IDENTIFIER_TYPE_FAILURE,
  UPDATE_INTERVENTION_FAILURE,
  UPDATE_INTERVENTION_TYPE_FAILURE,
  UPDATE_INTRADIALYTIC_MEDICATION.FAILURE,
  UPDATE_LAB_PARAMETER_FAILURE,
  UPDATE_MEDICATION_INTERRUPTION.FAILURE,
  UPDATE_MEDICINAL_INGREDIENT.FAILURE,
  UPDATE_MEDICINAL_NOT_GIVEN_REASON.FAILURE,
  UPDATE_MEDICINAL_PRODUCT.FAILURE,
  UPDATE_MEDICINAL_PRODUCT_PROVIDER.FAILURE,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_TYPE_FAILURE,
  UPDATE_PATIENT.FAILURE,
  UPDATE_PATIENT_ADDRESS_FAILURE,
  UPDATE_PATIENT_ATTACHMENT_FAILURE,
  UPDATE_PATIENT_AVATAR.FAILURE,
  UPDATE_PATIENT_CARD_FAILURE,
  UPDATE_PATIENT_CARD_SETTING_FAILURE,
  UPDATE_PATIENT_COMPLICATION_FAILURE,
  UPDATE_PATIENT_CONTACT_FAILURE,
  UPDATE_PATIENT_CONTACT_INFO.FAILURE,
  UPDATE_PATIENT_DIET_FAILURE,
  UPDATE_PATIENT_DISORDER.FAILURE,
  UPDATE_PATIENT_IDENTIFIER_FAILURE,
  UPDATE_PATIENT_IMMUNIZATION_FAILURE,
  UPDATE_PATIENT_INSURANCE_FAILURE,
  UPDATE_PATIENT_MEDICAL_INFO.FAILURE,
  UPDATE_PATIENT_TREATMENT_FAILURE,
  UPDATE_PATIENT_TREATMENT_PRESCRIPTION_FAILURE,
  UPDATE_PLANNING_SCHEMA_FAILURE,
  UPDATE_POSITION_FAILURE,
  UPDATE_POSITION_ORDER_FAILURE,
  UPDATE_PRODUCT_CONSUMPTION_SET.FAILURE,
  UPDATE_REMARK_FAILURE,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROOM_FAILURE,
  UPDATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE,
  UPDATE_TRANSPORT_TYPE_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_FAILURE,
  UPDATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE,
  UPDATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE,
  UPDATE_UNIT_ALIAS_FAILURE,
  UPDATE_USER_PASSWORD_FAILURE,
  UPDATE_VACCINATION_GROUP_FAILURE,
  UPDATE_WEIGHT_FAILURE,
  UPDATE_WOUND_FAILURE,
  UPDATE_WOUND_PRESCRIPTION_FAILURE,
];

export default function errorReducer(
  state: ErrorsState = initialState,
  action: ErrorsAction
): ErrorsState {
  const { type } = action;

  switch (true) {
    case FORM_HANDLER_ACTIONS.includes(type): {
      const reducer = setErrors({ handlerType: 'form' });
      return reducer(state, action);
    }

    case type === CREATE_USER_FAILURE:
    case type === UPDATE_USER_FAILURE:
    case type === UPDATE_CURRENT_USER_FAILURE: {
      const reducer = setErrors({
        errorMapFunction: (rawError) => {
          if (!rawError) {
            return { handlerType: 'form' };
          }

          // check if it's a uuid
          const uuidCheck = new RegExp(
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
          );

          return {
            ...rawError,
            source:
              rawError.source && uuidCheck.test(rawError.source)
                ? `identifier_${rawError.source}`
                : rawError.source,
            handlerType: 'form',
          };
        },
      });
      return reducer(state, action);
    }

    case type === IMPORT_AMBULATORY_MEDICINAL_PRODUCT_FROM_PROVIDER.FAILURE:
    case type === SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS.FAILURE: {
      return state;
    }

    case type === FORBIDDEN: {
      const reducer = setErrors({ handlerType: 'forbidden' });
      return reducer(state, action);
    }

    case type === NOT_FOUND: {
      const reducer = setErrors({ handlerType: 'not_found' });
      return reducer(state, action);
    }

    case type === METHOD_NOT_ALLOWED: {
      const reducer = setErrors({ handlerType: 'method_not_allowed' });
      return reducer(state, action);
    }

    case type === PAYLOAD_TOO_LARGE: {
      const reducer = setErrors({ handlerType: 'payload_too_large' });
      return reducer(state, action);
    }

    case type === LOCATION_CHANGE:
    case type === RESET_ERROR: {
      return initialState;
    }

    case type.endsWith('SUCCESS'): {
      if (state.length === 0) return initialState;

      return state.filter(
        ({ failedAction }) =>
          failedAction && getType(failedAction) !== getType(type)
      );
    }

    case type.endsWith('FAILURE') || type.endsWith('ERROR'): {
      const reducer = setErrors({});
      return reducer(state, action);
    }

    case type === CANCEL_ACTION_BAR_ACTIVITY:
    case type === START_ACTIVITY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
