import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
}

export default function Portal({ children }: Props) {
  const target = document.querySelector('#portal-root')!;

  return createPortal(children, target);
}
