import { AllergyConditionTypeT, AllergyT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_ALLERGY = 'ADD_ALLERGY';
export const EDIT_ALLERGY = 'EDIT_ALLERGY';
export const REMOVE_ALLERGY = 'REMOVE_ALLERGY';

export const CREATE_ALLERGY_FAILURE = 'CREATE_ALLERGY_FAILURE';
export const UPDATE_ALLERGY_FAILURE = 'UPDATE_ALLERGY_FAILURE';
export const DELETE_ALLERGY_FAILURE = 'DELETE_ALLERGY_FAILURE';

export const startAddAllergyActivity = (conditionType: AllergyConditionTypeT) =>
  startActivity(ADD_ALLERGY, { conditionType });

export const startEditAllergyActivity = (
  allergy: AllergyT,
  conditionType: AllergyConditionTypeT
) => startActivity(EDIT_ALLERGY, { allergy, conditionType });

export const startRemoveAllergyActivity = (allergy: AllergyT) =>
  startActivity(REMOVE_ALLERGY, { allergy });
