import { MouseEvent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { usePopper } from 'react-popper';
import { NavLink } from 'react-router-dom';

import {
  useRecordEnabled,
  useTrackEnabled,
  useWorkbenchEnabled,
} from 'hooks/useConfig';

import FeatureFlag from 'components/FeatureFlag';
import Icon from 'components/Icon';

import { adminRights, useAdminEnabled } from 'views/admin';

export default function PerspectiveNavigation() {
  const { formatMessage } = useIntl();

  const { isOpen, setIsOpen, setActuatorEl, setPopperEl, styles, attributes } =
    useSideBarMenu();

  const closePerspectivePicker = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const togglePerspectivePicker = () => {
    setIsOpen((current) => !current);
  };

  const onPerspectiveClick = () => {
    togglePerspectivePicker();
  };

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  const recordEnabled = useRecordEnabled();
  const workbenchEnabled = useWorkbenchEnabled();
  const trackEnabled = useTrackEnabled();
  const adminEnabled = useAdminEnabled(adminRights);

  return (
    <li
      role="presentation"
      data-testid="perspective-navigation"
      onClick={togglePerspectivePicker}
      ref={setActuatorEl}
    >
      <button
        title={formatMessage(t.nephroflowModules)}
        className="sidebar__item"
      >
        <Icon>apps</Icon>
      </button>

      {isOpen ? (
        <ul
          {...attributes.popper}
          ref={setPopperEl}
          style={styles.popper}
          className="sidebar__perspective-nav"
          onMouseLeave={closePerspectivePicker}
        >
          <li role="presentation">
            <h6>
              {formatMessage(t.switchTo)}
              <Icon
                role="presentation"
                style={{ fontSize: 16, cursor: 'pointer' }}
                className="float-right"
                onClick={closePerspectivePicker}
              >
                close
              </Icon>
            </h6>
          </li>

          {recordEnabled ? (
            <li role="presentation">
              <NavLink
                data-testid="record"
                to="/record"
                title={formatMessage(t.record)}
                onClick={onPerspectiveClick}
                className={cx}
              >
                <Icon>app:record</Icon>
                <span>{formatMessage(t.record)}</span>
              </NavLink>
            </li>
          ) : null}

          {trackEnabled ? (
            <li role="presentation">
              <NavLink
                data-testid="track"
                to="/track"
                title={formatMessage(t.track)}
                onClick={onPerspectiveClick}
                className={cx}
              >
                <Icon>app:track</Icon>
                <span>{formatMessage(t.track)}</span>
              </NavLink>
            </li>
          ) : null}

          {workbenchEnabled ? (
            <li role="presentation">
              <NavLink
                data-testid="workbench"
                to="/workbench"
                title={formatMessage(t.workbench)}
                onClick={onPerspectiveClick}
                className={cx}
              >
                <Icon>app:workbench</Icon>
                <span>{formatMessage(t.workbench)}</span>
              </NavLink>
            </li>
          ) : null}

          {adminEnabled ? (
            <FeatureFlag rights={adminRights}>
              <li role="presentation">
                <NavLink
                  data-testid="admin"
                  to="/admin"
                  title={formatMessage(t.admin)}
                  onClick={onPerspectiveClick}
                  className={cx}
                >
                  <Icon>settings_applications</Icon>
                  <span>{formatMessage(t.admin)}</span>
                </NavLink>
              </li>
            </FeatureFlag>
          ) : null}
        </ul>
      ) : null}
    </li>
  );
}

function useSideBarMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [actuatorEl, setActuatorEl] = useState<HTMLLIElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(actuatorEl, popperEl, {
    placement: 'right-end',
  });

  return { isOpen, setIsOpen, setActuatorEl, setPopperEl, styles, attributes };
}

const t = defineMessages({
  switchTo: {
    id: 'perspective_navigation_switch_to',
    defaultMessage: 'Switch to',
  },
  nephroflowModules: {
    id: 'perspective_navigation_modules',
    defaultMessage: 'NephroFlow modules',
  },
  record: {
    id: 'navigation_perspective_record_title',
    defaultMessage: 'Record',
  },
  workbench: {
    id: 'navigation_perspective_workbench_title',
    defaultMessage: 'Workbench',
  },
  track: {
    id: 'navigation_perspective_track_title',
    defaultMessage: 'Track',
  },
  admin: {
    id: 'navigation_perspective_admin_title',
    defaultMessage: 'System Settings',
  },
});
