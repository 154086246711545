import { startActivity } from 'store/modules/activity';

import { ContactT } from './fetchers';

export const ADD_PATIENT_CONTACT = 'ADD_PATIENT_CONTACT';
export const EDIT_PATIENT_CONTACT = 'EDIT_PATIENT_CONTACT';
export const REMOVE_PATIENT_CONTACT = 'REMOVE_PATIENT_CONTACT';

export const CREATE_PATIENT_CONTACT_FAILURE = 'CREATE_PATIENT_CONTACT_FAILURE';
export const UPDATE_PATIENT_CONTACT_FAILURE = 'UPDATE_PATIENT_CONTACT_FAILURE';
export const DELETE_PATIENT_CONTACT_FAILURE = 'DELETE_PATIENT_CONTACT_FAILURE';

export const addContactActivity = () => startActivity(ADD_PATIENT_CONTACT);

export const editContactActivity = (contact: ContactT) =>
  startActivity(EDIT_PATIENT_CONTACT, { contact });

export const removePatientContactActivity = (contact: ContactT) =>
  startActivity(REMOVE_PATIENT_CONTACT, { contact });
