import { KeyboardEvent } from 'react';
import { BaseEditor, Editor, Range, Text, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import isCtrlPressed from './utils/isCtrlPressed';

export function withDomain(editor: BaseEditor & ReactEditor & HistoryEditor) {
  const e = editor as RTEEditor;
  e.hasSelection = () => {
    return !!e.selection && !Range.isCollapsed(e.selection);
  };

  e.isMarkActive = (format: RTEMark) => {
    const marks = Editor.marks(e) || {};
    if (marks[format] === true) return true;

    const [match] = Editor.nodes(e, {
      match: (node) => node[format] === true,
      universal: true,
    });

    return !!match;
  };

  e.isBlockActive = (block: RTEBlock) => {
    const [match] = Editor.nodes(e, {
      match: (node) => ('type' in node ? node.type === block : false),
    });

    return !!match;
  };

  e.isHeadingActive = () => {
    const [match] = Editor.nodes(e, {
      match: (node) => {
        const n = node as RTEElement;
        const level = n.type ? parseInt(n.type?.slice(-1), 10) : -1;
        return level >= 1 && level <= 6;
      },
    });

    return !!match;
  };

  e.isColorActive = (color?: string) => {
    const [match] = Editor.nodes(e, {
      match: (node) => {
        const n = node as RTEText;
        return color ? n.style?.color === color : !!n.style?.color;
      },
    });

    return !!match;
  };

  e.removeColor = () => {
    Transforms.setNodes(
      e,
      { style: null },
      {
        match: (node) => Text.isText(node),
        split: true,
      }
    );
  };

  e.removeHeading = () => {
    const isActive = e.isHeadingActive();
    if (!isActive) return;

    Transforms.setNodes(e, {
      type: RTE_ELEMENT.paragraph,
    });
  };

  e.toggleColor = (color: string) => {
    const isCollapsed = !e.hasSelection();

    if (!isCollapsed) {
      const isActive = e.isColorActive(color);

      Transforms.setNodes(
        e,
        {
          style: {
            color: isActive ? null : color,
          },
        },
        {
          match: (node) => Text.isText(node),
          split: true,
        }
      );
    }
  };

  e.toggleMark = (format: RTEMark) => {
    const marks = Editor.marks(e) || {};
    const isActive = marks[format] === true;

    if (isActive) {
      e.removeMark(format);
    } else {
      e.addMark(format, true);
    }
  };

  e.toggleBlock = (block: RTEBlock) => {
    const isActive = e.isBlockActive(block);
    const isList = Object.values(RTE_LIST).includes(block as RTEList);

    Transforms.unwrapNodes(e, {
      match: (node) => {
        const n = node as RTEElement;
        return !!n.type && Object.values(RTE_LIST).includes(n.type as RTEList);
      },
      split: true,
    });

    Transforms.setNodes(e, {
      type: isActive
        ? RTE_ELEMENT.paragraph
        : isList
        ? RTE_ELEMENT.listItem
        : block,
    });

    if (!isActive) {
      Transforms.setNodes(
        e,
        {
          style: null,
          bold: null,
          italic: null,
          underline: null,
        },
        {
          match: (node) => Text.isText(node),
          split: true,
        }
      );
    }

    if (!isActive && isList) {
      Transforms.wrapNodes(e, {
        type: block,
        children: [],
      });
    }
  };

  e.insertParagraph = () => {
    Transforms.insertNodes(e, PARAGRAPH_ELEMENT);
  };

  e.focus = () => {
    ReactEditor.focus(e);
    Transforms.collapse(e, {
      edge: 'end',
    });
  };

  e.clearFormatting = () => {
    Transforms.setNodes(
      e,
      {
        style: null,
        bold: null,
        italic: null,
        underline: null,
      },
      {
        match: (node) => Text.isText(node),
        split: true,
      }
    );

    Transforms.unwrapNodes(e, {
      match: (node) => {
        const n = node as RTEElement;
        return !!n.type && Object.values(RTE_LIST).includes(n.type as RTEList);
      },
      split: true,
    });

    Transforms.setNodes(e, {
      type: RTE_ELEMENT.paragraph,
    });
  };

  e.keyDown = (event: KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'Enter': {
        if (e.isHeadingActive()) {
          event.preventDefault();
          e.insertParagraph();
        }

        break;
      }

      default:
        break;
    }

    if (isCtrlPressed(event)) {
      switch (event.key) {
        case 'b':
          event.preventDefault();
          e.toggleMark(RTE_MARK.bold);
          break;

        case 'i':
          event.preventDefault();
          e.toggleMark(RTE_MARK.italic);
          break;

        case 'u':
          event.preventDefault();
          e.toggleMark(RTE_MARK.underline);
          break;

        default:
          break;
      }
    }
  };

  e.canUndo = () => {
    const { history } = e;
    const { undos } = history;

    return undos.length > 0;
  };

  e.canRedo = () => {
    const { history } = e;
    const { redos } = history;

    return redos.length > 0;
  };

  return e;
}

export const RTE_MARK = {
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
} as const;

export const RTE_HEADING = {
  h1: 'heading-1',
  h2: 'heading-2',
  h3: 'heading-3',
  h4: 'heading-4',
  h5: 'heading-5',
  h6: 'heading-6',
} as const;

export const RTE_LIST = {
  ol: 'numbered-list',
  ul: 'bulleted-list',
} as const;

export const RTE_ELEMENT = {
  ...RTE_MARK,
  ...RTE_HEADING,
  ...RTE_LIST,
  paragraph: 'paragraph',
  span: 'span',
  code: 'code',
  listItem: 'list-item',
} as const;

export const PARAGRAPH_ELEMENT = {
  type: RTE_ELEMENT.paragraph,
  children: [{ text: '' }],
};

export const BUTTON_WIDTH = 32;

type ValueOf<T> = T[keyof T];

export type RTEMark = ValueOf<typeof RTE_MARK>;
export type RTEHeading = ValueOf<typeof RTE_HEADING>;
export type RTEList = ValueOf<typeof RTE_LIST>;
export type RTEElementType = ValueOf<typeof RTE_ELEMENT>;
export type RTEBlock = RTEHeading | RTEList;

export type RTENode = RTEElement | RTEText;

export interface RTEElement {
  type?: RTEElementType;
  children: RTENode[];
  [key: string]: unknown;
}

export interface RTEText extends RTEFormattedText {
  text: string;
  style?: RTEStyle | null;
}

export interface RTEFormattedText {
  bold?: boolean | null;
  italic?: boolean | null;
  underline?: boolean | null;
  code?: boolean | null;
}

export interface RTEStyle {
  color?: string | null;
}

export interface DomainEditor {
  hasSelection: () => boolean;
  isMarkActive: (format: RTEMark) => boolean;
  isBlockActive: (format: RTEBlock) => boolean;
  isHeadingActive: () => boolean;
  isColorActive: (color?: string) => boolean;
  removeColor: () => void;
  removeHeading: () => void;
  toggleColor: (color: string) => void;
  toggleMark: (format: RTEMark) => void;
  toggleBlock: (format: RTEBlock) => void;
  insertParagraph: () => void;
  focus: () => void;
  clearFormatting: () => void;
  keyDown: (event: KeyboardEvent<HTMLElement>) => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
}

export type RTEEditor = BaseEditor & ReactEditor & HistoryEditor & DomainEditor;
